import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import IndexLayout from '../IndexLayout';
import {useRequest} from 'ahooks';
import request from '../../util/request';
import './ck-style.css';

const Article = props => {
    const loadData = useRequest(() => {
        return request({url: `/article/detail/${props.match.params.id}`})
            .then(({data}) => data);
    }, {
        refreshDeps: [props.match.params.id]
    });
    return <IndexLayout>
        <Container>
            <Row>
                <Col dangerouslySetInnerHTML={{__html: loadData.data && loadData.data.content}}
                     className={'ck-content'}/>
            </Row>
        </Container>
    </IndexLayout>
}

export default Article;
