import React, {forwardRef} from 'react';
import IndexLayout from '../../../IndexLayout';
import {Button, Form, Input, Modal, Table} from 'antd';
import {PageHeader} from '@ant-design/pro-components';
import {connect} from 'react-redux';

import * as actions from './actions';
import {PlusOutlined} from '@ant-design/icons';
import LiteratureView from '../../../../component/Literature';
import FormInsectSelector from '../../../../component/FormInsectSelector';

const AppendForm = forwardRef((props, ref) => {
    return <Form ref={ref} {...props}>
        <Form.Item label={'物种名称'} name={'insectId'} rules={[{required: true}]}>
            <FormInsectSelector/>
        </Form.Item>
        <Form.Item label={'本种页码'} name={'page'} rules={[{required: true}]}>
            <Input/>
        </Form.Item>
    </Form>
});

const AppendModal = forwardRef((props, ref) => {
    const [modal, contextHolder] = Modal.useModal();
    const [form] = Form.useForm();
    ref({
        show: () => {
            modal.confirm({
                content: <AppendForm form={form}/>,
                icon: null,
                onOk: () => {
                    return form.validateFields()
                        .then(data => {
                            return props.onOk && props.onOk(data);
                        });
                }
            });
        }
    });
    return contextHolder;
});

class Detail extends React.Component {
    componentDidMount() {
        const {match: {params}, data: {id}} = this.props;
        +params.id !== id && this.props.loadData(params);
        this.props.loadDetail(params);
    }

    columns = [{
        dataIndex: 'specificNameChinese',
        title: '中文名'
    }, {
        dataIndex: 'latinName',
        title: '拉丁名'
    }, {
        dataIndex: 'page',
        title: '本种页码'
    }, {
        dataIndex: 'operate',
        title: '操作',
        render: (text, record) => (
            <span>
            <Button type="link" onClick={() => this.props.removeInsect(record)}>删除</Button>
            </span>
        )
    }]

    showAppendItem = () => {
        this.modal.show();
    }

    render() {
        const {props} = this;
        return <IndexLayout>
            <PageHeader title={'文献详情'} extra={<LiteratureView {...props.data}/>}/>
            <div>
                <Table
                    rowKey={'insectId'}
                    columns={this.columns}
                    dataSource={props.listData}
                    summary={() => {
                        return <Table.Summary.Row>
                            <Table.Summary.Cell colSpan={4}>
                                <Button
                                    type={'primary'}
                                    size={'small'}
                                    icon={<PlusOutlined/>}
                                    onClick={this.showAppendItem}
                                />
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    }}
                />
            </div>
            <AppendModal ref={modal => this.modal = modal} onOk={props.appendItem}/>
        </IndexLayout>;
    }
}

const ManageLiteratureDetail = connect(state => state.manage.literature.detail, actions)(Detail);

export default ManageLiteratureDetail;
