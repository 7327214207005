import React from 'react';
import {Form, message, Modal, Switch} from 'antd';
import {push} from 'connected-react-router';

export const update = payload => ({type: 'state.manage.user', payload});
export const updateQuery = payload => ({type: 'state.manage.user.query', payload});
export const updateDetail = payload => ({type: 'state.manage.user.detail', payload});

export const loadData = () => (dispatch, getState, {request}) => {
    const {query} = getState().manage.user.index;
    request('/users/user/list', {params: query})
        .then(({data}) => {
            dispatch(update(data));
        })
};

export const toDetail = data => dispatch => {
    dispatch(updateDetail({info: data}));
    dispatch(push(`${window.location.pathname}/${data.id}`));
};

export const tableChange = (pagination, filters, sorter, extra) => (dispatch, getState) => {
    if (pagination && pagination.current) {
        const {query} = getState().manage.user.index;
        if (query.limit !== pagination.pageSize) {
            dispatch(update({rows: []}));
        }
        dispatch(updateQuery({offset: (pagination.current - 1) * pagination.pageSize, limit: pagination.pageSize}));
        dispatch(loadData());
    }
};

export const handleSearch = search => dispatch => {
    dispatch(updateQuery({search}));
    dispatch(loadData());
}

export const deleteRow = record => (dispatch, getState, {request}) => {
    console.log(record);
    const formData = {sms: true};
    let form = null;
    Modal.confirm({
        title: '确认删除用户',
        content: <Form ref={e => form = e} initialValues={formData}>
            <Form.Item name={'sms'} valuePropName="checked" label={'发送短信通知用户'}>
                <Switch/>
            </Form.Item>
        </Form>,
        onOk: () => {
            return form.validateFields()
                .then(data => {
                    return request.delete(`/users/user/${record.id}`, {params: {sms: data.sms ? '1' : ''}})
                        .then(({data}) => {
                            if (data.message === 'success') {
                                dispatch(loadData());
                                return;
                            }
                            console.log(data);
                            message.error(JSON.stringify(data));
                        })
                });
        }
    })
}
