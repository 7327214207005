import {push} from "connected-react-router";
import {taxonomyField} from '../../util';

const update = payload => ({type: 'state.insect.taxonomy', payload});

export const pathIndex = {
    phylum: 0,
    class: 1,
    order: 2,
    family: 3
}

export const getList = (params) => (dispatch, getState, {request}) => {
    const {query} = getState().router.location;
    const {type, subType, id} = params;
    const url = ['/insect/taxonomy', type || taxonomyField[0].name];
    if (id) {
        url.push(id);
        if (subType) url.push(subType);
    }
    request.get(url.join('/'))
        .then(({data}) => {
            let list = data;
            if (query.exclude) {
                const exclude = query.exclude.split(',').map(id => +id);
                list = list.filter(({id}) => !exclude.includes(id));
            }
            list.forEach(item => {
                item.images = item.images ? item.images.split(',') : [];
            });
            dispatch(update({list}));
        })
};

export const toDetail = (data, depth) => (dispatch, getState) => {
    const state = getState().insect.taxonomy;
    const parentPath = [...state.parentPath];
    parentPath[depth] = data;
    const newData = {parentData: data, parentPath};
    dispatch(update(newData));
    if (taxonomyField.length === depth + 1) {
        return dispatch(push(`./${taxonomyField[depth].name}/${data.id}`));
    }
    dispatch(push(`/insect/taxonomy/${taxonomyField[depth].name}/${data.id}/${taxonomyField[depth + 1].name}`));
};

export const getParentData = ({id, type}) => (dispatch, getState, {request}) => {
    const state = getState().insect.taxonomy;
    return request.get(`/insect/taxonomy/${type}/${id}`)
        .then(({data}) => {
            if (data.message === 'success') {
                const parentPath = [...state.parentPath];
                parentPath[pathIndex[type]] = data.data;
                dispatch(update({parentData: data.data, parentPath}));
            }
        })
};

