import dateUtil from '@/util/date';

import {showMessage} from '../../../component/MessageAlert';
import {message, Modal} from 'antd';
import {push} from 'connected-react-router';
import {pick} from 'ramda';

const update = payload => ({type: 'action.insect.upload', payload});
const updateData = payload => ({type: 'action.insect.upload.data', payload});
export const updateAuxiliaryData = payload => ({type: 'action.insect.upload.auxiliaryData', payload});

export const save = (collectData) => (dispatch, getState, {request}) => {
    const {data, auxiliaryData} = collectData.state;
    if (auxiliaryData.matchMode === 1 && !data.insectId) {
        return message.info('请设置昆虫名称项');
    }
    if (auxiliaryData.matchMode !== 1 && !auxiliaryData.orderChinese) {
        return message.info('请填写目名');
    }
    if (!data.images || data.images.length === 0) {
        return message.info('请上传物种图片');
    }
    console.log(data);
        const insectData = data;
        request.post('/users/insect/collect', {data, auxiliaryData})
            .then(({data}) => {
                if (data.message === 'success') {
                    Modal.confirm({
                        title: `保存成功`,
                        okText: '开始新的采集',
                        cancelText: '采集结束',
                        onOk: () => {
                            const positionData = pick(['address', 'lat', 'lng', 'province', 'city', 'district', 'township'], insectData);
                            dispatch(reset());
                            dispatch(initCollect());
                            dispatch(updateData(positionData));
                        },
                        onCancel: () => dispatch(push('/'))
                    });
                    return;
                }
                dispatch(showMessage({message: JSON.stringify(data)}));
            });
};

export const picker = (result) => dispatch => {
    console.log(result);
    const {info, position, regeocode, address} = result;
    if (info === 'OK') {
        const {province, city, district, township} = regeocode.addressComponent;
        dispatch({
            type: 'action.insect.upload.data',
            payload: {
                address, lat: position.lat, lng: position.lng,
                province, city, district, township
            }
        });
    }
};

export const addImage = (e) => (dispatch, getState) => {
    let {files} = e.target;
    files = Array.from(files);
    const {imageData} = getState().insect.upload;
    dispatch(update({imageData: imageData.concat(files.map(file => ({file, url: window.URL.createObjectURL(file)})))}));
};

export const removeImage = index => (dispatch, getState) => {
    const {imageData} = getState().insect.upload;
    const data = imageData[index];
    window.URL.revokeObjectURL(data.url);
    dispatch(update({imageData: [...imageData.slice(0, index), ...imageData.slice(index + 1)]}));
};

export const reset = () => (dispatch, getState) => {
    const {imageData} = getState().insect.upload;
    if (imageData.length) {
        imageData.forEach(({url}) => {
            window.URL.revokeObjectURL(url);
        });
    }
    dispatch({type: 'reset.insect.upload'});
};

export const unknownName = () => updateAuxiliaryData({matchMode: 2});

export const notFoundName = () => updateAuxiliaryData({matchMode: 3});

export const selectInsect = insect => (dispatch, getState, {request}) => {
    const {id} = insect;
    dispatch(updateData({insectId: id}));
    dispatch(update({insect}));
    request.get(`/insect/detail/${id}`)
        .then(({data}) => {
            dispatch(update({insect: data}));
        });
};

export const onDataChange = ({target}) => updateData({[target.name]: target.value});

export const onTimeSelect = (date) => {
    return updateData({collectTime: date.format('L LT')});
};

export const initCollect = () => (dispatch, getState) => {
    const {name: collectUserName} = getState().frameworks.user;
    dispatch(updateData({collectTime: dateUtil().format('YYYY-MM-DD HH:mm'), collectUserName}));
};
