import {message, Modal} from 'antd';

export const update = payload => ({type: 'state.manage.literature.detail', payload});

export const loadData = ({id}) => (dispatch, getState, {request}) => {
    request.get(`/users/literature/${id}`)
        .then(({data}) => {
            dispatch(update({data: data[0] || {}}));
        });
};

export const loadDetail = ({id}) => (dispatch, getState, {request}) => {
    request.get(`/users/literature/${id}/insect`)
        .then(({data}) => {
            dispatch(update({listData: data}));
        });
};

export const appendItem = (appendData) => (dispatch, getState, {request}) => {
    const {data} = getState().manage.literature.detail;
    const {insectId, page} = appendData;
    return request.post(`/users/literature/${data.id}/insect/${insectId}`, {page})
        .then(() => {
            message.success('添加成功');
            dispatch(loadDetail({id: data.id}));
        });
};

export const selectedData = (appendData) => {
    return update({appendData});
};

export const removeInsect = (insectData) => (dispatch, getState, {request}) => {
    const {data: baseData} = getState().manage.literature.detail;
    Modal.confirm({
        title: `确认删除${insectData.specificNameChinese}(${insectData.latinName})吗?`,
        onOk: () => request.delete(`/users/literature/${baseData.id}/insect/${insectData.insectId}`)
            .then(({data}) => {
                if (data) {
                    if (data.message === 'success') {
                        message.success('删除成功');
                        dispatch(loadDetail({id: baseData.id}));
                        return;
                    }
                }
                console.log(data);
                message.error(JSON.stringify(data));
            })
    });
};
