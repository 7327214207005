import React from 'react';
import Layout from '../../IndexLayout';
import {connect} from 'react-redux';
import {Button, Col, Container, Row} from 'react-bootstrap';
import * as actions from './actions';
import 'react-datetime/css/react-datetime.css';
import CollectEditor from '../../../component/CollectEditor';
import {Typography} from 'antd';

class Upload extends React.Component {
    componentDidMount() {
        this.props.initCollect();
    }

    componentWillUnmount() {
        this.props.reset();
    }

    render() {
        const {data, auxiliaryData} = this.props;
        return <Layout>
            <Container>
                <Row>
                    <Col>
                        <Typography.Title level={4}>物种图像采集</Typography.Title>
                        <CollectEditor ref={node => this.collectEditor = node} data={data} auxiliaryData={auxiliaryData}
                                       positionPicker geolocation/>
                        <div className={'text-center'}>
                            <Button onClick={() => this.props.save(this.collectEditor)}>保存</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Layout>;
    }
}

const InsectUpload = connect(state => state.insect.upload, actions)(Upload);

export default InsectUpload;
