const update = payload => ({type: 'state.manage.user.detail', payload});

export const loadUserData = ({id}) => (dispatch, getState, {request}) => {
    request.get(`/users/user/${id}/info`)
        .then(({data}) => {
            dispatch(update({info: data}));
        })
};

export const loadAnalyseData = ({id}) => (dispatch, getState, {request}) => {
    request.get(`/users/user/${id}/analyse`)
        .then(({data}) => {
            if (data.auditedRows) {
                const phylum = {};
                const classObj = {};
                const order = {};
                const family = {};
                const taxonomyData = {};
                let collectSum = 0;
                data.taxonomyRows.forEach(({phylumChinese, classChinese, orderChinese, familyChinese, sum}) => {
                    phylum[phylumChinese] = 1;
                    classObj[`${phylumChinese}-${classChinese}`] = 1;
                    order[`${phylumChinese}-${classChinese}-${orderChinese}`] = 1;
                    family[`${phylumChinese}-${classChinese}-${orderChinese}-${familyChinese}`] = 1;
                    collectSum += sum;
                    if (!taxonomyData[phylumChinese]) {
                        taxonomyData[phylumChinese] = {};
                    }
                    if (!taxonomyData[phylumChinese][classChinese]) {
                        taxonomyData[phylumChinese][classChinese] = {};
                    }
                    if (!taxonomyData[phylumChinese][classChinese][orderChinese]) {
                        taxonomyData[phylumChinese][classChinese][orderChinese] = {};
                    }
                    taxonomyData[phylumChinese][classChinese][orderChinese][familyChinese] = 1;
                });
                data.taxonomyData = taxonomyData;
                data.sumData = {
                    order: Object.keys(order).length,
                    family: Object.keys(family).length,
                    specificName: data.auditedRows.length,
                    collectSum
                };
            }
            dispatch(update({insectAnalyse: data}));
        })
};

export const loadPermissionData = () => (dispatch, getState, {request}) => {
    const {grant} = getState().manage.user.detail;
    if (grant) return;
    request.get('/users/permission/grant')
        .then(({data}) => {
            dispatch(update({grant: data.list}))
        })
}

export const loadUserPermissionData = ({id}) => (dispatch, getState, {request}) => {
    return request.get(`/users/user/${id}/permission`)
        .then(({data}) => {
            dispatch(update({permission: data}));
        })
}

export const changePermission = ({target}) => (dispatch, getState, {request}) => {
    const {info} = getState().manage.user.detail;
    (target.checked
        ? request.put(`/users/user/${info.id}/permission/${target.name}`)
        : request.delete(`/users/user/${info.id}/permission/${target.name}`))
        .then(() => dispatch(loadUserPermissionData(info)))
}
