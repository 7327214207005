import React from 'react';
import {connect} from 'react-redux';
import IndexLayout from '../../IndexLayout';
import {Modal, Typography} from 'antd';
import useImageStyle from 'antd/lib/image/style';
import * as actions from './actions';
import UserReportList from '../../../component/UserReportList';
import CollectEditor from '../../../component/CollectEditor';
import UserAcceptedList from '../../../component/UserAcceptedList';

const AntImageWrapper = props => {
    const [wrapSSR, hashId] = useImageStyle('ant-image');
    return wrapSSR(<div className={`ant-image ${hashId}`} style={{height: props.height, width: props.width}}>
        {props.children}
    </div>);
}

class Index extends React.Component {
    componentDidMount() {
        this.props.loadAnalyseData();
        this.props.loadCollectData();
        this.props.loadAcceptedData();
    }

    render() {
        const {props} = this;
        const {insectAnalyse} = props.user;
        return <IndexLayout>
            <div className={'p-3'}>
                <Typography.Title level={3}>个人中心</Typography.Title>
                <div className={'d-flex flex-row'}>
                    <AntImageWrapper width={120} height={120}>
                        <input style={{display: 'none'}} type={'file'} ref={e => this.imgUpload = e}
                               onChange={props.changeAvatar}/>
                        <img className={'ant-image-img'} style={{width: 120, height: 120, objectFit: 'contain'}}
                             src={`/uploadImg/tiny-avatar/${props.frameworks.user.avatar}`}
                             alt={props.frameworks.user.name}/>
                        <div className={'ant-image-mask'} onClick={() => {
                            this.imgUpload.click()
                        }}>
                            <div className={'ant-image-mask-info'}>编辑头像</div>
                        </div>
                    </AntImageWrapper>
                    <div className={'pl-3 d-flex flex-column justify-content-around'}>
                        <Typography.Text>
                            {props.frameworks.user.name}
                        </Typography.Text>
                        <Typography.Text>
                            {props.frameworks.user.workplace}
                        </Typography.Text>
                        <Typography.Text>
                            {props.frameworks.user.address}
                        </Typography.Text>
                    </div>
                </div>
                <Typography.Title level={4}>已上传数据</Typography.Title>
                <UserReportList canOperate data={props.user.collectData} param={props.user.collectParam}
                                edit={props.editCollect} pageChange={props.loadCollectData}/>
                <Typography.Title level={4}>
                    已入库数据{insectAnalyse.auditedRows && insectAnalyse.auditedRows.length !== 0 && insectAnalyse.sumData && `:${insectAnalyse.sumData.order}目,${insectAnalyse.sumData.family}科,${insectAnalyse.sumData.specificName}种,${insectAnalyse.sumData.collectSum}条记录`}
                </Typography.Title>
                <UserAcceptedList
                    data={props.user.acceptedData}
                    param={props.user.acceptedParam}
                    pageChange={props.loadAcceptedData}
                />
                {!!insectAnalyse.waitAuditSum &&
                    <Typography.Title level={4}>待鉴定数据{insectAnalyse.waitAuditSum}条</Typography.Title>
                }
            </div>
            <Modal
                title={"编辑采集数据"}
                open={props.user.editCollectData !== null}
                destroyOnClose width={750}
                onOk={() => props.saveCollect(this.collectEditor)}
                onCancel={props.cancelEditCollect}
            >
                <CollectEditor ref={node => this.collectEditor = node} {...props.user.editCollectData} positionPicker/>
            </Modal>
        </IndexLayout>
    }
}

const UserIndex = connect(({frameworks, user: {index}}) => ({frameworks, user: index}), actions)(Index);

export default UserIndex;
