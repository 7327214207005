import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import IndexLayout from '../../IndexLayout';
import {Button, Divider, Form, Input, message, Space} from 'antd';
import {PageHeader} from '@ant-design/pro-components';
import {MinusCircleOutlined} from '@ant-design/icons';
import {insectInputTextField} from '../../util';
import LiteratureSelector from '../../../component/LiteratureSelector';
import {literatureEdit} from '../../manage/literature/edit';
import {useRequest} from 'ahooks-v2';
import request from '../../../util/request';
import FormTaxonomySelector from '../../../component/TaxonomySelector/FormTaxonomySelector';

const formLayout = {
    labelCol: {span: 6}
}

const Add = props => {
    const [form] = Form.useForm();
    const [{create: showCreate}] = literatureEdit({
        onSuccess: data => {
            const literature = form.getFieldValue('literature') || [];
            form.setFieldsValue({literature: literature.concat({literature: {label: data.title, value: data.id}})});
        }
    });
    const submit = useRequest((data) => {
        const {literature, ...insectData} = form.getFieldsValue(true);
        return request({url: '/users/insect', data: insectData, method: 'POST'})
            .then(({data: result}) => {
                if (result.message === 'success' && result.id) {
                    console.log(literature, 'literature');
                    Promise.all(
                        literature.map(item => request.post(`/users/literature/${item.literature.value}/insect/${result.id}`, {page: item.page}))
                    )
                        .then((data) => {
                            console.log(data);
                            const error = data.filter(item => item.data.message !== 'success');
                            if (error.length) {
                                message.error(JSON.stringify(error.map(item => item.data)));
                            } else {
                                message.success('添加成功');
                            }
                        })
                    return
                }
                message.error(JSON.stringify(result));
            })
    }, {
        requestMethod: (param) => request(param),
        manual: true,
        onSuccess: () => {
            props.history.push('index');
        }
    });
    return <IndexLayout>
        <Container>
            <Row>
                <Col>
                    <PageHeader title={'添加物种'}/>
                    <Form
                        {...formLayout} form={form} onFinish={submit.run}
                        initialValues={props.history.location.state && props.history.location.state.initialValues}
                    >
                        <FormTaxonomySelector/>
                        {insectInputTextField.map(({key, label}) =>
                            <Form.Item key={key} label={label} name={key}>
                                <Input/>
                            </Form.Item>
                        )}
                        <Divider>文献</Divider>
                        <Form.List name={'literature'} initialValue={[{}]} rules={[
                            {
                                validator: async (_, names) => {
                                    if (!names || names.length < 1) {
                                        return Promise.reject(new Error('至少添加一条文献'));
                                    }
                                },
                            },
                        ]}>
                            {(fields, {add, remove}, {errors}) => (
                                <>
                                    {
                                        fields.map(({key, name, fieldKey, ...restField}) =>
                                            <div key={key}
                                                 className={'d-flex align-items-baseline justify-content-center'}>
                                                <Form.Item {...restField} labelCol={{span: 6}} wrapperCol={{span: 18}}
                                                           name={[name, 'literature']}
                                                           label={'文献标题'} className={'flex-grow-1 w-50'}
                                                           rules={[{required: true}]}>
                                                    <LiteratureSelector/>
                                                </Form.Item>
                                                <Form.Item {...restField} labelCol={{span: 6}} wrapperCol={{span: 18}}
                                                           name={[name, 'page']}
                                                           label={'本种页码'} className={'mx-3 flex-grow-1'}
                                                           rules={[{required: true}]}>
                                                    <Input/>
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)}/>
                                            </div>)
                                    }
                                    <Form.Item className={'text-center'}>
                                        <Form.ErrorList errors={errors}/>
                                        <Space>
                                            <Button type={'primary'} onClick={() => add()}>添加现有文献</Button>
                                            <Button onClick={showCreate}>创建文献</Button>
                                        </Space>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        <Form.Item className={'text-right'}>
                            <Button htmlType={'submit'} loading={submit.loading}>保存</Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Container>
    </IndexLayout>
}

const ManageInsectAdd = Add;

export default ManageInsectAdd;
