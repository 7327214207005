const edit = {
    name: '',
    chineseName: '',
    images: []
};
const initialData = {
    orderList: [],
    showOrderEdit: false,
    showEdit: false,
    edit,
    currentOrder: {},
    familyList: [],
    sortType: ''
};

const taxonomy = (state = initialData, {type, payload}) => {
    if (type === 'state.manage.insect.taxonomy') {
        return {...state, ...payload};
    }
    if (type === 'state.manage.insect.taxonomy.edit') {
        return {...state, edit: {...state.edit, ...payload}};
    }
    if (type === 'state.manage.insect.taxonomy.newRecord') {
        return {...state, edit, showEdit: true};
    }
    return state;
};

export default taxonomy;
