import React from 'react';
import {connect} from 'react-redux';
import IndexLayout from '../../IndexLayout';
import {Col, Container, Row} from 'react-bootstrap';
import queryString from 'query-string'
import InfiniteScroll from 'react-infinite-scroller';
import {Card, Carousel, Input, Spin} from 'antd';
import * as actions from './actions';

class Search extends React.Component {
    constructor(props) {
        super(props);
        const params = queryString.parse(props.location.search);
        this.state = {...params};
    }

    componentDidMount() {
        const {search} = this.state;
        if (search) {
            this.doSearch(search);
        }
    }

    doSearch = (text) => {
        this.props.doSearch(text, this.props.match.params.type);
    };

    render() {
        const {props, state} = this;
        return <IndexLayout>
            <Container>
                <Row>
                    <Col>
                        <div className={'text-center py-3'}>
                            <Input.Search
                                className={'insect-search'}
                                placeholder={this.props.match.params.type === 'host' ? '输入寄主名' : '输入物种中文名或拉丁学名'}
                                onSearch={this.doSearch}
                                enterButton
                                defaultValue={state.search}
                            />
                        </div>
                    </Col>
                </Row>
                <InfiniteScroll
                    className={'row-cols-3 row'}
                    loadMore={props.loadMore}
                    initialLoad={false}
                    hasMore={props.listData.length < props.total}
                    loader={<div className="col-12 text-center py-5" key={0}><Spin/></div>}
                >
                    {props.listData.map((data) => {
                        const {id, specificNameChinese, latinName, images} = data;
                        return <Col key={id}>
                            <Card
                                onClick={() => props.toInsectDetail(data)}
                                cover={
                                    <Carousel>
                                        {images.length === 0 && <div className={'img-4-3-wrapper'}/>}
                                        {images.map((name, index) => (
                                            <div key={index} className={'img-4-3-wrapper'}>
                                                <img
                                                    alt={name}
                                                    src={`/uploadImg/tiny/${name}`}
                                                />
                                            </div>
                                        ))}
                                    </Carousel>
                                }
                            >
                                <p className={'h5'}>
                                    {specificNameChinese} {latinName}
                                </p>
                            </Card>
                        </Col>;
                    })}
                </InfiniteScroll>
            </Container>
        </IndexLayout>;
    }
}

const InsectSearch = connect(state => state.insect.search, actions)(Search);

export default InsectSearch;
