import axios from 'axios';
import {push} from 'connected-react-router';

const update = payload => ({type: 'action.main', payload});
export const selectKey = (key) => ({type: 'action.main', payload: {selected: key}});
export const changeSearch = ({target: {value: search}}) => ({type: 'action.main', payload: {search}});
export const doSearch = () => (dispatch, getState) => {
    const state = getState().main;
    const params = {[state.selected]: state.search};
    return axios.get('/api/insect', {params})
        .then(({data}) => dispatch({type: 'action.main', payload: {listData: data.rows}}))
};
export const toInsectDetail = (data) => dispatch => {
    if (Object.keys(data).length > 1) {
        dispatch({type: 'action.insect.detail', payload: data});
    }
    dispatch(push(`/insect/${data.id}`))
};

export const selectMenu = (selected) => {
    if (selected === 'taxonomy') {
        return {type: 'action.main', payload: {selected: '', showSelector: true}};
    }
    return {type: 'action.main', payload: {selected, showSelector: false}};
};

export const toTaxonomy = () => push('/insect/taxonomy');

export const toSearch = search => dispatch => search && dispatch(push(encodeURI(`/insect/search/search?search=${search}`)));

export const toHost = () => push('/insect/search/host');

export const toMorphology = () => push('/insect/morphology');

export const loadSumData = () => (dispatch, getState, {request}) => {
    if (getState().main.sumData) return;
    request.get('/insect/analyse/sum')
        .then(({data}) => {
            dispatch(update({sumData: data}));
        })
}

export const loadAllCollectPosition = () => (dispatch, getState, {request}) => {
    if (getState().main.allCollectPosition) return;
    request.get('/insect/collect/all')
        .then(({data}) => {
            if (!data || !data.length) return;
            let minLat = data[0].lat;
            let minLng = data[0].lng;
            let maxLat = data[0].lat;
            let maxLng = data[0].lng;
            const markers = data.map(({lat, lng}, index) => {
                if (lat < minLat) minLat = lat;
                if (lng < minLng) minLng = lng;
                if (lat > maxLat) maxLat = lat;
                if (lng > maxLng) maxLng = lng;
                return {
                    lnglat: [lng, lat],
                    id: index
                }
            })
            const opacityPath = [{
                longitude: minLng,
                latitude: minLat
            }, {
                longitude: minLng,
                latitude: maxLat
            }, {
                longitude: maxLng,
                latitude: maxLat
            }, {
                longitude: maxLng,
                latitude: minLat
            }]
            console.log(opacityPath);
            dispatch(update({allCollectPosition: {markers, opacityPath}}));
        })
}

export const loadTopData = () => (dispatch, getState, {request}) => {
    if (getState().main.topData) return;
    request.get('/insect/analyse/top')
        .then(({data}) => {
            dispatch(update({topData: data}));
        })
}

export const queryTypeChange = queryType => push(`/insect/class/${queryType}`);
