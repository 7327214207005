import React from 'react';
import InsectDetail from '../page/insect/detail';
import {Route, withRouter} from 'react-router-dom';
import {SpecialFilterProvider} from '../page/insect/special/detail/provider';

const RouterList = [
    {path: '/detail/:id', component: InsectDetail}
]
    .map(item => ({path: `/insect/special/:specialId/insect${item.path}`, component: withRouter(item.component)}));
const SpecialRouter = props => {
    return <div>
        {RouterList.map((item, index) => {
            return <Route path={item.path} key={index}>
                <SpecialFilterProvider {...props} component={item.component}/>
            </Route>
        })}
    </div>
}

export default SpecialRouter;
