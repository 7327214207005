const initialData = {
    data: {
        address: '',
        lat: '',
        lng: '',
        province: '',
        city: '',
        district: '',
        township: '',
        collectUserName: '',
        collectTime: '',
        host: '',
        hostLatinName: '',
        insectState: '',
        harmWay: '',
        habitatType: '',
        elevation: '',
        images: []
    },
    auxiliaryData: {
        matchMode: 1
    },
    imageData: [],
    insect: {}
};
const reducer = (state = initialData, {type, payload}) => {
    if (type === 'action.insect.upload') {
        return {...state, ...payload};
    }
    if (type === 'action.insect.upload.data') {
        return {...state, data: {...state.data, ...payload}};
    }
    if (type === 'action.insect.upload.auxiliaryData') {
        return {...state, auxiliaryData: {...state.auxiliaryData, ...payload}};
    }
    if (type === 'reset.insect.upload') {
        return initialData;
    }
    return state;
};

export default reducer;
