import React, {useState} from 'react';
import {taxonomyField} from '../../page/util';
import TaxonomyFieldSelector from './TaxonomyFieldSelector';
import {Form, Input} from 'antd';

const FormTaxonomySelector = props => {
    const [selectedTaxonomy, setSelectedTaxonomy] = useState({});
    return <Form.Item noStyle>
        {taxonomyField.map((item, index) => {
            return [
                <Form.Item name={item.name} label={item.name} key={item.name}>
                    <Input/>
                </Form.Item>,
                <Form.Item
                    key={item.key}
                    label={item.label}
                    dependencies={['phylumChinese', 'phylum', 'classChinese', 'class', 'orderChinese', 'order', 'familyChinese', 'family']}
                >
                    {({setFieldsValue}) => {
                        const onTaxonomyChange = (type, data, option) => {
                            setSelectedTaxonomy({...selectedTaxonomy, [type]: data});
                            setFieldsValue({[item.key]: data.label, [item.name]: option.name});
                        }
                        return <TaxonomyFieldSelector
                            type={item.key}
                            depth={index}
                            selected={selectedTaxonomy}
                            onChange={onTaxonomyChange}
                        />
                    }}
                </Form.Item>
            ]
        })}
    </Form.Item>
}
export default FormTaxonomySelector;
