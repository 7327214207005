import React from 'react';
import IndexLayout from '../../../IndexLayout';
import {Button, Carousel, Spin} from 'antd';
import {PageHeader} from '@ant-design/pro-components';
import {connect} from 'react-redux';
import * as actions from './actions';
import InfiniteScroll from 'react-infinite-scroller';
import {Col, Container} from 'react-bootstrap';

class MorphologySearch extends React.Component {
    componentDidMount() {
        this.props.initLoadData();
    }

    render() {
        const {props} = this;
        return <IndexLayout>
            <PageHeader title="形态检索管理" extra={
                <Button type="primary" onClick={props.newData}>
                    新增模式图
                </Button>
            }/>
            <Container fluid>
                <InfiniteScroll
                    className={'row-cols-3 row'}
                    loadMore={props.loadMore}
                    initialLoad={false}
                    hasMore={props.rows.length < props.total}
                    loader={<div className="col-12 text-center py-5" key={0}><Spin/></div>}
                >
                    {props.rows.map((data) => {
                        const {id, images} = data;
                        return <Col key={id} onClick={() => props.editSearch(data)}>
                            <Carousel>
                                {images.length === 0 && <div className={'img-4-3-wrapper'}/>}
                                {images.map((name, index) => (
                                    <div key={index} className={'img-4-3-wrapper'}>
                                        <img
                                            alt={name}
                                            src={`/uploadImg/${name}`}
                                        />
                                    </div>
                                ))}
                            </Carousel>
                        </Col>;
                    })}
                </InfiniteScroll>
            </Container>
        </IndexLayout>;
    }
}

const ManageInsectMorphologySearch = connect(state => state.manage.insect.morphology.search, actions)(MorphologySearch);

export default ManageInsectMorphologySearch;
