import {equals} from 'ramda';

const update = payload => ({type: 'state.insect.analyse', payload});
const updateFilter = payload => ({type: 'state.insect.analyse.filter', payload});

export const optionFields = {
    taxonomy: ['classChinese', 'orderChinese', 'familyChinese', 'genusChinese', 'specificNameChinese'],
    address: ['province', 'city', 'district'],
    host: ['hostFamilyChinese', 'host', 'specificNameChinese'],
    // harmWay: ['harmWay']
};
export const labelFields = {
    taxonomy: ['纲名', '目名', '科名', '属名', '种名'],
    address: ['省', '市', '区'],
    host: ['寄主科名', '寄主植物', '种名'],
    // harmWay: ['危害方式']
};

export const init = () => dispatch => {
    dispatch(update({filter: {}, option1: 'host'}));
    dispatch(doAnalyse());
};

export const option1Change = (e) => dispatch => {
    const {value} = e.target;
    dispatch(update({option1: value}));
    dispatch(doAnalyse());
};

export const selectInsect = insect => (dispatch, getState, {request}) => {
    const {id} = insect;
    dispatch(update({insectId: id}));
    request('/insect/analyse', {params: {insectId: id, analyseType: 'host'}})
        .then(({data}) => {
            dispatch(update({hostAnalyseData: data}));
        });
    request('/insect/analyse', {params: {insectId: id, analyseType: 'address'}})
        .then(({data}) => {
            dispatch(update({addressInsectAnalyseData: data}));
        });
};

export const analyseAddress = () => (dispatch, getState, {request}) => {
    const {addressFilter} = getState().insect.analyse;
    request('/insect/analyse', {params: {...addressFilter, analyseType: 'address'}})
        .then(({data}) => {
            dispatch(update({addressAnalyseData: data}));
        });
};

export const addressFilterChange = (e, filterKey, record) => (dispatch, getState) => {
    e.preventDefault();
    let addressFilter = filterKey ? {[filterKey]: record[filterKey]} : {};
    const {addressFilter: filter} = getState().insect.analyse;
    if (filterKey === 'city') {
        addressFilter.province = filter.province;
    }
    if (equals(addressFilter, filter)) return;
    dispatch(update({addressFilter}));
    dispatch(analyseAddress());
};

export const filterChange = (type, value, selectedOptions) => dispatch => {
    const optionField = optionFields[type];
    let params;
    if (selectedOptions && selectedOptions.length) {
        params = {};
        selectedOptions.forEach((item, index) => {
            params[optionField[index]] = item.valueData;
        });
    }
    dispatch(updateFilter({[type]: params}));
    dispatch(doAnalyse());
};

export const doAnalyse = () => (dispatch, getState, {request}) => {
    const {option1, filter} = getState().insect.analyse;
    request('/insect/analyse', {params: {...filter, type: option1}})
        .then(({data}) => {
            const dispatchData = {analyseData: data, valueKey: ['sum'], typeValueKey: ['insectSum']};
            const group = filter[option1] || {};
            const optionField = optionFields[option1];
            const labelField = labelFields[option1];
            let depth = Object.keys(group).length;
            if (depth >= optionField.length) {
                depth = optionField.length - 1;
            }
            if (group) {
                dispatchData.labelKey = [optionField[depth]];
                dispatchData.keyName = labelField[depth];
                dispatchData.titleKey = labelField.slice(0, depth + 1);
            }
            dispatchData.analyseData.sort((a, b) => b.insectSum - a.insectSum);
            dispatch(update(dispatchData));
        });
};
