import React from 'react';
import IndexLayout from '../../../../IndexLayout';
import {Button, Col, Form, Input, Radio, Row, Select, Upload} from 'antd';
import {PageHeader} from '@ant-design/pro-components';
import {connect} from 'react-redux';
import * as actions from './actions';
import {Container} from 'react-bootstrap';
import {CloseOutlined} from '@ant-design/icons';

const Area = props => {
    const {info, mode} = props
    let {style} = info;
    if (mode === 'preview') {
        style = {
            height: `${info.height}%`,
            width: `${info.width}%`,
            left: `${info.left}%`,
            top: `${info.top}%`
        };
        if (info.borderRadius) {
            style.borderRadius = `${info.borderRadius}%`
        }
        if (info.rotate) {
            style.transform = `rotate(${info.rotate}deg)`
        }
    } else {
        style = info.style
    }
    return <div className={`morphology-area ${mode}`} style={style}/>
}

class SearchEdit extends React.Component {
    componentDidMount() {
        if (!this.props.areaList) {
            this.props.loadAreaList()
        }
    }

    componentWillUnmount() {
        this.props.reset();
    }

    render() {
        const {props} = this;
        const {currentArea} = props;
        const areaType = (currentArea && currentArea.borderRadius) === '50' ? 'round' : 'box'
        return <IndexLayout>
            <Container fluid>
                <PageHeader title="编辑模式图" extra={
                    <Button type="primary" onClick={props.saveData}>
                        保存
                    </Button>
                }/>
                <Row gutter={16} justify="space-around">
                    <Col span={10}>
                        <Upload
                            action={'/api/users/insect/img'}
                            fileList={props.fileList}
                            onChange={props.fileChange}
                        >
                            <Button type={'primary'}>上传图片</Button>
                        </Upload>
                        {props.images.length > 0 && <div className={'morphology-wrapper'}>
                            {props.activeIndex > -1 && props.area.list[props.activeIndex] &&
                            props.area.list[props.activeIndex].image &&
                            <img src={`/uploadImg/${props.area.list[props.activeIndex].image}`}
                                 className={'w-100 position-absolute'} alt=""/>
                            }
                            {props.currentArea && props.currentArea.image &&
                            <img src={`/uploadImg/${props.currentArea.image}`} className={'w-100 position-absolute'}
                                 alt=""/>
                            }
                            {currentArea && <Area mode={'preview'} info={currentArea}/>}
                            {props.area.list.map((info, index) =>
                                <Area info={info} key={index} mode={props.activeIndex === index ? 'active' : 'normal'}/>
                            )}
                            <img src={`/uploadImg/${props.images}`} className={'w-100'} alt=""/>
                        </div>}
                    </Col>
                    {props.images.length > 0 && <Col span={10}>
                        {props.areaList && <Form>
                            <Form.Item label={'固定展示区域'}>
                                <Select name={'area'} onChange={props.areaChange} mode="multiple"
                                        value={props.area.static || []}>
                                    {props.areaList.map(value =>
                                        <Select.Option value={value} key={value}>{value}</Select.Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Form>}
                        {currentArea ? <>
                                <Button type={'primary'} onClick={props.saveArea}>完成</Button>
                                <Upload
                                    action={'/api/users/insect/img'}
                                    fileList={props.areaFileList}
                                    onChange={props.areaFileChange}
                                >
                                    <Button type={'primary'}>上传高亮图</Button>
                                </Upload>
                            </> :
                            <Button type={'primary'} onClick={props.addArea}>添加区域</Button>}
                        {currentArea && <Form>
                            <Form.Item label={'左间距'}>
                                <Input suffix="%" type={'number'} min={0} max={100} name={'left'}
                                       onChange={props.editChange} value={currentArea.left}/>
                            </Form.Item>
                            <Form.Item label={'上间距'}>
                                <Input suffix="%" type={'number'} min={0} max={100} name={'top'}
                                       onChange={props.editChange}
                                       value={currentArea.top}/>
                            </Form.Item>
                            <Form.Item label={'形状'}>
                                <Radio.Group value={areaType}
                                             onChange={props.changeType}>
                                    <Radio value="box">矩形</Radio>
                                    <Radio value="round">圆形</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label={'宽'}>
                                <Input suffix="%" type={'number'} min={0} max={100} name={'width'}
                                       onChange={props.editChange} value={currentArea.width}/>
                            </Form.Item>
                            <Form.Item label={'高'}>
                                <Input suffix="%" type={'number'} min={0} max={100} name={'height'}
                                       onChange={props.editChange} value={currentArea.height}/>
                            </Form.Item>
                            <Form.Item label={'角度'}>
                                <Input suffix="%" type={'number'} min={-90} max={90} name={'rotate'}
                                       onChange={props.editChange} value={currentArea.rotate}/>
                            </Form.Item>
                            <Form.Item label={'区域'}>
                                <Select name={'area'} onChange={props.currentAreaChange} mode="multiple"
                                        value={currentArea.area}>
                                    {props.areaList.map(value =>
                                        <Select.Option value={value} key={value}>{value}</Select.Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Form>}
                        {props.area.list.map((item, index) => <div key={index}>
                            宽:{item.style.width}, 高:{item.style.height},
                            左间距:{item.style.left}, 上间距: {item.style.top},
                            区域:{item.area.join(',')}
                            {props.activeIndex === index ?
                                <Button size={'small'} onClick={() => props.showArea(-1)}>取消标识</Button> :
                                <Button size={'small'} onClick={() => props.showArea(index)}>标识</Button>
                            }
                            <Button icon={<CloseOutlined/>} size={'small'} onClick={() => props.removeArea(index)}/>
                        </div>)}
                    </Col>
                    }
                </Row>
            </Container>
        </IndexLayout>;
    }
}

const ManageInsectMorphologySearchEdit = connect(state => state.manage.insect.morphology.edit, actions)(SearchEdit);

export default ManageInsectMorphologySearchEdit;
