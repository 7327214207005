import {message, Modal} from "antd";
import {update as updateDetail} from './detail/actions';
import {push} from 'connected-react-router';

export const update = payload => ({type: 'state.manage.literature', payload});
export const updateQuery = payload => ({type: 'state.manage.literature.query', payload});

export const loadData = () => (dispatch, getState, {request}) => {
    const {query} = getState().manage.literature;
    request('/users/literature', {params: query})
        .then(({data}) => {
            dispatch(update(data));
        });
};

export const pageChange = (pagination) => (dispatch) => {
    if (pagination && pagination.current) {
        dispatch(updateQuery({offset: (pagination.current - 1) * pagination.pageSize}));
        dispatch(loadData());
    }
};

export const handleSearch = text => dispatch => {
    dispatch(updateQuery({search: text ? text : undefined, offset: 0}));
    dispatch(loadData());
}

export const deleteRow = (data) => (dispatch, getState, {request}) => {
    Modal.confirm({
        title: `确认删除该文献吗`,
        content: '删除后无法恢复',
        onOk: () => {
            return request.delete(`/users/literature/${data.id}`)
                .then(({data}) => {
                    if (data) {
                        if (data.message === 'success') {
                            message.success('删除成功');
                            dispatch(loadData());
                            return;
                        }
                    }
                    console.log(data);
                    message.error(JSON.stringify(data));
                });
        }
    });
};

export const preserveKey = {
    type: true, 'title': true, 'periodical': true, 'volume': true, 'pageSum': true
}

export const submitRow = (data, record) => (dispatch, getState, {request}) => {
    let promise = null;
    if (record) {
        const diff = {};
        Object.keys(data).forEach(key => {
            if (preserveKey[key] || data[key] !== record[key]) {
                diff[key] = data[key];
            }
        })
        if (Object.keys(diff).length) {
            promise = request.patch(`/users/literature/${record.id}`, diff)
        }
    } else {
        promise = request.put('/users/literature', data)
    }
    return promise && promise.then(({data}) => {
        if (data) {
            if (data.message === 'success') {
                message.success('保存成功');
                dispatch(loadData());
                return;
            }
            if (data.message) {
                message.info(data.message);
                return Promise.reject(data.message);
            }
        }
        message.error(JSON.stringify(data));
        return Promise.reject(JSON.stringify(data));
    })
}

export const toDetail = record => dispatch => {
    dispatch(updateDetail({data: record}));
    dispatch(push(`literature/${record.id}/detail`));
}
