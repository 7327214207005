const initialData = {
    data: {id: 0, name: ''},
    listData: [],
    showAppend: false,
    appendData: {}
}

const detail = (state = initialData, {type, payload}) => {
    if (type === 'state.manage.insect.morphology.detail') {
        return {...state, ...payload};
    }
    return state;
}

export default detail;
