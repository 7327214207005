const currentArea = {
    width: 20,
    height: 20,
    left: 20,
    top: 20,
    rotate: 0,
    area: []
}
const initialData = {
    area: {list: [], static: []},
    images: [],
    areaFileList: [],
    currentArea: null,
    areaList: null,
    activeIndex: -1
}

const edit = (state = initialData, {type, payload}) => {
    if (type === 'state.manage.insect.morphology.edit') {
        return {...state, ...payload};
    }
    if (type === 'reset.manage.insect.morphology.edit') {
        return initialData;
    }
    if (type === 'state.manage.insect.morphology.edit.currentArea') {
        return {...state, currentArea: {...state.currentArea, ...payload}};
    }
    if (type === 'state.manage.insect.morphology.edit.area') {
        return {...state, area: {...state.area, ...payload}};
    }
    if (type === 'state.manage.insect.morphology.edit.new') {
        return {...state, currentArea}
    }
    return state
}

export default edit;
