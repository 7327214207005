import React from 'react';
import {Button, ButtonGroup, Col, Container, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import Layout from '../../IndexLayout';
import * as actions from './actions';
import {Progress} from 'antd';

const Batch = props => {
    return <Layout>
        <Container>
            <Row>
                <Col xs={12}>
                    <div className="h2 page-header">批量导入物种名称&nbsp;
                        <ButtonGroup>
                            <Button onClick={() => window.open('/template/specie name.xlsx')}>下载模板</Button>
                            <Button variant="success" className="file-input-button">
                                <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                       type="file"
                                       onChange={props.uploadInsect}/>
                                上传
                            </Button>
                        </ButtonGroup>
                    </div>
                    <p>{props.insectMessage}</p>
                </Col>
                <Col xs={12}>
                    <div className="h2 page-header">批量导入标本信息&nbsp;
                        <ButtonGroup>
                            <Button onClick={() => window.open('/template/specimen data.xlsx')}>下载模板</Button>
                            <Button variant="success" className="file-input-button">
                                <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                       type="file"
                                       onChange={props.uploadCollect}/>
                                上传
                            </Button>
                        </ButtonGroup>
                    </div>
                    <p>{props.collectMessage}</p>
                </Col>
                <Col xs={12}>
                    <div className="h2 page-header">批量导入物种图片&nbsp;
                        <ButtonGroup>
                            <Button className="file-input-button">
                                <input accept="application/zip"
                                       type="file"
                                       onChange={props.uploadImgPackage}/>
                                打包上传
                            </Button>
                            {/*<Button variant="success">多图上传</Button>*/}
                        </ButtonGroup>
                    </div>
                    {props.imgMessage && <Progress percent={props.imgProgress}/>}
                    <p>{props.imgMessage}</p>
                </Col>
                <Col xs={12}>
                    <div className="h2 page-header">批量关联专题信息&nbsp;
                        <ButtonGroup>
                            <Button onClick={() => window.open('/template/specialData.xlsx')}>下载模板</Button>
                            <Button variant="success" className="file-input-button">
                                <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                       type="file"
                                       onChange={props.uploadSpecialData}/>
                                上传
                            </Button>
                        </ButtonGroup>
                    </div>
                    <p>{props.specialMessage}</p>
                </Col>
                <Col xs={12}>
                    <div className="h2 page-header">批量关联形态信息&nbsp;
                        <ButtonGroup>
                            <Button onClick={() => window.open('/template/morphologyData.xlsx')}>下载模板</Button>
                            <Button variant="success" className="file-input-button">
                                <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                       type="file"
                                       onChange={props.uploadMorphologyData}/>
                                上传
                            </Button>
                        </ButtonGroup>
                    </div>
                    <p>{props.morphologyMessage}</p>
                </Col>
                <Col xs={12}>
                    <div className="h2 page-header">批量上传文献&nbsp;
                        <ButtonGroup>
                            <Button onClick={() => window.open('/template/literatureData.xlsx')}>下载期刊模板</Button>
                            <Button variant="success" className="file-input-button">
                                <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                       type="file"
                                       name="periodical"
                                       onChange={props.uploadLiteratureData}/>
                                上传期刊
                            </Button>
                            <Button onClick={() => window.open('/template/monographData.xlsx')}>下载专著模板</Button>
                            <Button variant="success" className="file-input-button">
                                <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                       type="file"
                                       name="monograph"
                                       onChange={props.uploadLiteratureData}/>
                                上传专著
                            </Button>
                        </ButtonGroup>
                    </div>
                    <p>{props.literatureMessage}</p>
                </Col>
            </Row>
        </Container>
    </Layout>;
};

const InsectBatch = connect(state => state.insect.batch, actions)(Batch);

export default InsectBatch;
