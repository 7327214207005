import {combineReducers} from 'redux';
import edit from './edit';
import audit from './audit';
import batch from './batch';
import special from './special';
import analyse from './analyse';
import detail from './detail';
import taxonomy from './taxonomy';
import upload from './upload';
import search from './search';

const insect = combineReducers({
    edit,
    audit,
    batch,
    special,
    analyse,
    detail,
    taxonomy,
    upload,
    search
});

export default insect;
