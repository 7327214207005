import React from 'react';
import IndexLayout from '../../IndexLayout';
import {Breadcrumb, Col, Form, Radio, Row, Table, Typography} from 'antd';
import {PageHeader} from '@ant-design/pro-components';
import {connect} from 'react-redux';

import * as actions from './actions';
import InsectSelector from '../../../component/InsectSelector';
import ReactEcharts from 'echarts-for-react';
import FilterCascader from '../../../component/FilterCascader';

const options = [
    {label: '分类学', value: 'taxonomy'},
    {label: '地域', value: 'address'},
    {label: '寄主', value: 'host'}
];

const LineChart = ({labelKey = [], valueKey = [], keyName = '', data = []}) => {
    const option = {
        tooltip: {},
        xAxis: {
            data: data.map(item => labelKey.map(key => item[key]).join('-')),
            silent: false,
            splitLine: {
                show: false
            }
        },
        yAxis: {},
        series: [{
            name: keyName,
            type: 'bar',
            color: '#a9d18e',
            barMaxWidth: '50',
            data: data.map(item => valueKey.map(key => item[key]).join('-'))
        }]
    };
    return <ReactEcharts option={option}/>;
};

const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 14}
};

class Analyse extends React.Component {
    componentDidMount() {
        this.props.init();
    }

    render() {
        const {props} = this;
        return <IndexLayout>
            <PageHeader title={'数据分析'}/>
            <Form {...formItemLayout}>
                <Form.Item label={'请选择分析维度'}>
                    <Radio.Group size={'small'} value={props.option1} onChange={props.option1Change}>
                        {options.map(({label, value}) =>
                            <Radio.Button key={value} value={value}>{label}</Radio.Button>
                        )}
                    </Radio.Group>
                </Form.Item>
                <Form.Item label={'分类学过滤'}>
                    <FilterCascader
                        type={'taxonomy'}
                        onChange={(value, selectedOptions) => props.filterChange('taxonomy', value, selectedOptions)}
                    />
                </Form.Item>
                <Form.Item label={'地区过滤'}>
                    <FilterCascader
                        type={'address'}
                        onChange={(value, selectedOptions) => props.filterChange('address', value, selectedOptions)}
                    />
                </Form.Item>
                <Form.Item label={'寄主过滤'}>
                    <FilterCascader
                        type={'host'}
                        onChange={(value, selectedOptions) => props.filterChange('host', value, selectedOptions)}
                    />
                </Form.Item>
                {props.option1 === 'insect' &&
                <InsectSelector insectId={props.insectId} onSelect={props.selectInsect}/>
                }
            </Form>
            <div className={'p-2'}>
                {props.analyseData && props.labelKey && props.valueKey && <>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Typography.Title level={4} className={'text-center'}>物种数量分析</Typography.Title>
                            <LineChart data={props.analyseData} labelKey={props.labelKey} valueKey={props.typeValueKey}
                                       keyName={props.keyName}/>
                        </Col>
                        <Col span={12}>
                            <Typography.Title level={4} className={'text-center'}>标本数量分析</Typography.Title>
                            <LineChart data={props.analyseData} labelKey={props.labelKey} valueKey={props.valueKey}
                                       keyName={props.keyName}/>
                        </Col>
                    </Row>
                </>}
                {props.option1 === 'insect' && <Row gutter={16}>
                    <Col span={12}>
                        <PageHeader title={'寄主植物分析'}/>
                        <Table rowKey={(row, index) => index} dataSource={props.hostAnalyseData} pagination={false}>
                            <Table.Column title="寄主植物" dataIndex="host"/>
                            <Table.Column title="物种数量" dataIndex="insectSum"/>
                            <Table.Column title="记录数量" dataIndex="sum"/>
                        </Table>
                    </Col>
                    <Col span={12}>
                        <PageHeader title={'地区分析'}/>
                        <Table rowKey={(row, index) => index} dataSource={props.addressInsectAnalyseData}
                               pagination={false}>
                            <Table.Column title="省" dataIndex="province"/>
                            <Table.Column title="物种数量" dataIndex="insectSum"/>
                            <Table.Column title="记录数量" dataIndex="sum"/>
                        </Table>
                    </Col>
                    <Col span={12}>
                        <PageHeader title={'test'}/>
                        <LineChart labelKey={['host']} valueKey={['sum']} data={props.hostAnalyseData}/>
                    </Col>
                    <Col span={12}>
                        <PageHeader title={'test'}/>
                        <LineChart labelKey={['province']} valueKey={['sum']} data={props.addressInsectAnalyseData}/>
                    </Col>
                </Row>
                }
                {props.option1 === 'address-' &&
                <Row gutter={16}>
                    <Col span={24}>
                        <PageHeader title={'区域昆虫分析'} pagination={false}/>
                        <Breadcrumb>
                            <Breadcrumb.Item href={''} onClick={props.addressFilterChange}>全国</Breadcrumb.Item>
                            {props.addressFilter.province &&
                            <Breadcrumb.Item
                                href={props.addressFilter.city && ''}
                                onClick={e => props.addressFilterChange(e, 'province', props.addressFilter)}
                            >
                                {props.addressFilter.province}
                            </Breadcrumb.Item>}
                            {props.addressFilter.city &&
                            <Breadcrumb.Item>
                                {props.addressFilter.city}
                            </Breadcrumb.Item>}
                        </Breadcrumb>
                        <Table rowKey={(row, index) => index} dataSource={props.addressAnalyseData} pagination={false}>
                            <Table.Column
                                title="省" dataIndex="province"
                                onCell={record => {
                                    return {
                                        onClick: event => props.addressFilterChange(event, 'province', record)
                                    };
                                }}
                            />
                            {props.addressFilter.province &&
                            <Table.Column
                                title="市" dataIndex="city"
                                onCell={record => {
                                    return {
                                        onClick: event => props.addressFilterChange(event, 'city', record)
                                    };
                                }}
                            />}
                            {props.addressFilter.city &&
                            <Table.Column
                                title="区" dataIndex="district"
                            />}
                            <Table.Column title="物种数量" dataIndex="insectSum"/>
                            <Table.Column title="记录数量" dataIndex="sum"/>
                        </Table>
                        <PageHeader title={'test'}/>
                        <LineChart
                            labelKey={
                                ['province', props.addressFilter.province && 'city', props.addressFilter.city && 'district'].filter(key => key)
                            }
                            valueKey={['sum']}
                            data={props.addressAnalyseData}/>
                    </Col>
                </Row>
                }
            </div>
        </IndexLayout>;
    }
}

const InsectAnalyse = connect(state => state.insect.analyse, actions)(Analyse);

export default InsectAnalyse;

