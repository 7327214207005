import React from 'react';
import {Table} from 'antd';

const phylumListFilter = (value, record) => record.phylumChinese.indexOf(value) === 0;
const classListFilter = (value, record) => record.classChinese.indexOf(value) === 0;
const orderListFilter = (value, record) => record.orderChinese.indexOf(value) === 0;
const familyListFilter = (value, record) => record.familyChinese.indexOf(value) === 0;

class UserReportInfo extends React.Component {
    state = {filters: {}};
    handleChange = (pagination, filters, sorter) => {
        console.log(pagination, filters, sorter);
        const {phylumChinese, classChinese, orderChinese, familyChinese} = filters;
        const {filters: stateFilters} = this.state;
        const res = {};
        if (phylumChinese) {
            res.phylumChinese = phylumChinese;
            if (phylumChinese === stateFilters.phylumChinese && classChinese) {
                res.classChinese = classChinese;
                if (classChinese === stateFilters.classChinese && orderChinese) {
                    res.orderChinese = orderChinese;
                    if (orderChinese === stateFilters.orderChinese && familyChinese) {
                        res.familyChinese = familyChinese;
                    }
                }
            }
        }
        this.setState({filters: res});
    };

    render() {
        const {props, state} = this;
        const {taxonomyData} = props;
        const phylumFilters = taxonomyData && Object.keys(taxonomyData).map(text => ({text, value: text}));
        const classFilter = [];
        const orderFilters = [];
        const familyFilters = [];
        if (taxonomyData && state.filters.phylumChinese) {
            state.filters.phylumChinese.forEach(text => {
                classFilter.push(...Object.keys(taxonomyData[text]).map(text => ({text, value: text})));
            })
            if (taxonomyData[state.filters.phylumChinese] && state.filters.classChinese) {
                state.filters.classChinese.forEach(text => {
                    orderFilters.push(...Object.keys(taxonomyData[state.filters.phylumChinese][text]).map(text => ({
                        text,
                        value: text
                    })));
                })
                if (taxonomyData[state.filters.phylumChinese][state.filters.classChinese] && state.filters.orderChinese) {
                    state.filters.orderChinese.forEach(text => {
                        familyFilters.push(...Object.keys(taxonomyData[state.filters.phylumChinese][state.filters.classChinese][text]).map(text => ({
                            text,
                            value: text
                        })));
                    })
                }
            }
        }
        return <Table dataSource={props.data} rowKey={'insectId'} onChange={this.handleChange}>
            <Table.Column
                title={'门名'}
                dataIndex={'phylumChinese'}
                filters={phylumFilters}
                onFilter={phylumListFilter}
                filterMultiple={false}
                filteredValue={state.filters.phylumChinese || null}
            />
            <Table.Column
                title={'纲名'}
                dataIndex={'classChinese'}
                filters={classFilter}
                onFilter={classListFilter}
                filterMultiple={false}
                filteredValue={state.filters.classChinese || null}
            />
            <Table.Column
                title={'目名'}
                dataIndex={'orderChinese'}
                filters={orderFilters}
                onFilter={orderListFilter}
                filterMultiple={false}
                filteredValue={state.filters.orderChinese || null}
            />
            <Table.Column
                title={'科名'}
                dataIndex={'familyChinese'}
                filters={familyFilters}
                onFilter={familyListFilter}
                filterMultiple={false}
                filteredValue={state.filters.familyChinese || null}
            />
            <Table.Column title={'中文种名'} dataIndex={'specificNameChinese'}/>
            <Table.Column title={'拉丁学名'} dataIndex={'latinName'}/>
            <Table.Column title={'采集数量'} dataIndex={'sum'}/>
        </Table>
    }
}

export default UserReportInfo;
