import React from 'react';
import {taxonomyField} from '../../page/util';
import request from '../../util/request';
import {Select} from 'antd';

class TaxonomyFieldSelector extends React.Component {
    componentDidMount() {
        this.loadList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.depth && prevProps !== this.props) {
            const key = taxonomyField[this.props.depth - 1].key
            if (this.props.selected[key] !== prevProps.selected[key]) {
                this.setState({list: [], filterList: null});
                this.loadList();
            }
        }
    }

    state = {list: [], filterList: null};

    loadList = () => {
        let url = ['/insect/taxonomy'];
        if (this.props.depth) {
            const key = taxonomyField[this.props.depth - 1].key
            if (!this.props.selected[key]) return;
            url.push(taxonomyField[this.props.depth - 1].name, this.props.selected[key].key);
        }
        url.push(taxonomyField[this.props.depth].name);
        request.get(url.join('/'))
            .then(({data}) => {
                this.setState({list: data});
            })
    };

    onSearch = (value) => {
        if (value) {
            const result = this.state.list.filter(item => item.chineseName.indexOf(value) >= 0);
            if (result.length === 0) {
                result.push({id: value, chineseName: value});
            }
            this.setState({filterList: result});
            return;
        }
        this.setState({filterList: null});
    };


    render() {
        const list = this.state.filterList || this.state.list;
        const {props} = this;
        return <Select
            showSearch
            filterOption={false}
            onSearch={this.onSearch}
            labelInValue
            value={props.selected[props.type]}
            onChange={(value, option) => props.onChange(props.type, value, option)}
        >
            {list.map(item =>
                <Select.Option value={item.id} key={item.id} name={item.name}>{item.chineseName}</Select.Option>)}
        </Select>;
    }
}

export default TaxonomyFieldSelector;
