import {showMessage} from '../../../component/MessageAlert';

const update = payload => ({type: 'state.insect.edit', payload});
const updateData = payload => ({type: 'state.insect.edit.data', payload});

export const showAdd = (data = {}) => update({show: true, data});

export const hideAdd = () => update({show: false});

export const onDataChange = ({target}) => updateData({[target.name]: target.value});

export const save = () => (dispatch, getState, {request}) => {
    const {data: {id, callback, ...data}} = getState().insect.edit;
    (id ? request.put(`/users/insect/${id}`, data) : request.post('/users/insect', data))
        .then(({data}) => {
            if (data.message === 'success') {
                dispatch(hideAdd());
                callback && callback();
                return dispatch(showMessage({message: '保存成功'}));
            }
            dispatch(showMessage({message: JSON.stringify(data)}));
        });
};
