import React from 'react';
import IndexLayout from '../../IndexLayout';
import {Col, Container, Row} from 'react-bootstrap';
import {Card, Carousel} from 'antd';
import {connect} from 'react-redux';
import * as actions from './actions';
import {taxonomyField} from '../../util';
import TaxonomyTitle from './TaxonomyTitle';

class Taxonomy extends React.Component {
    componentDidMount() {
        const {match: {params}, parentData, getParentData, getList} = this.props;
        if (params.subType && params.type && parentData.id !== +params.id) {
            getParentData(params);
        }
        getList(params);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {match: {params}, parentData, getParentData, getList} = this.props;
        if (params.type !== prevProps.match.params.type || params.subType !== prevProps.match.params.subType) {
            if (params.subType && params.type && parentData.id !== +params.id) {
                getParentData(params);
            }
            getList(this.props.match.params);
        }
    }

    render() {
        const {props} = this;
        const type = props.match.params.type || taxonomyField[0].name;
        const currentType = props.match.params.subType || type;
        let depth = taxonomyField.findIndex((item) => item.name === currentType);
        if (depth < 0) depth = 0;
        return <IndexLayout>
            <Container>
                <Row>
                    <Col>
                        <TaxonomyTitle
                            history={props.history}
                            type={props.match.params.type}
                            subType={props.match.params.subType}
                            parentData={props.parentData}
                            parentPath={props.parentPath}
                            depth={depth}
                        />
                    </Col>
                </Row>
                <Row className={'row-cols-3'}>
                    {props.list.map((data) => {
                        const {id, name, chineseName, images} = data;
                        return <Col key={id}>
                            <Card
                                onClick={() => props.toDetail(data, depth)}
                                cover={
                                    <Carousel>
                                        {images.map((name, index) => (
                                            <img
                                                key={index}
                                                alt={name}
                                                src={`/uploadImg/${name}`}
                                            />
                                        ))}
                                    </Carousel>
                                }
                            >
                                <p className={'h5'}>{chineseName} {name}</p>
                            </Card>
                        </Col>;
                    })}
                </Row>
            </Container>
        </IndexLayout>;
    }
}

const InsectTaxonomy = connect(state => state.insect.taxonomy, actions)(Taxonomy);

export default InsectTaxonomy;
