const edit = {
    name: ''
};

const initialData = {
    showEdit: false,
    edit,
    rows: [],
    total: 0,
    query: {
        order: 'desc',
        offset: 0,
        limit: 10
    },
    insectData: {},
    batchOption: false,
    checkedRows: []
};

const collect = (state = initialData, {type, payload}) => {
    if (type === 'state.manage.insect.collect') {
        return {...state, ...payload};
    }
    if (type === 'state.manage.insect.collect.edit') {
        return {...state, edit: {...state.edit, ...payload}};
    }
    if (type === 'state.manage.insect.collect.query') {
        return {...state, query: {...state.query, ...payload}};
    }
    return state;
};

export default collect;
