import React from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {Button, Divider, Form, Input, Modal, Table} from 'antd';
import {PageHeader} from '@ant-design/pro-components';
import IndexLayout from '../../../IndexLayout';
import {connect} from 'react-redux';
import ImgListEditor from '../../../../component/ImgListEditor';
import * as actions from './actions';
import {DndProvider} from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import DragableBodyRow from '../../../../component/DragableBodyRow';
import {taxonomyField} from '../../../util';

class Taxonomy extends React.Component {
    componentDidMount() {
        this.props.getList(this.props.match.params);
        this.mount = true
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.type !== prevProps.match.params.type) {
            this.props.getList(this.props.match.params);
        }
    }

    componentWillUnmount() {
        this.mount = false;
        if (this.props.showEdit) {
            this.props.hiddenEdit();
        }
    }

    components = {
        body: {
            row: DragableBodyRow,
        },
    };

    render() {
        const {props} = this;
        const type = props.match.params.type || taxonomyField[0].name;
        const currentType = props.match.params.subType || props.match.params.type || type;
        let depth = taxonomyField.findIndex((item) => item.name === currentType);
        if (depth < 0) depth = 0;
        const config = taxonomyField[depth];
        const columns = [{
            dataIndex: 'chineseName',
            title: config.label
        }, {
            dataIndex: 'name',
            title: config.name
        }, {
            dataIndex: 'Action',
            title: 'action',
            render: (text, record) => {
                return (
                    <span>
                        <Button type="link" onClick={() => this.props.editRecord(record)}>编辑</Button>
                        <Divider type="vertical"/>
                        {depth + 1 < taxonomyField.length && <>
                            <Button type="link" onClick={() => this.props.toDetail(record, depth)}>查看详情</Button>
                            <Divider type="vertical"/>
                        </>}
                        <Button type="link"
                                onClick={() => this.props.deleteTaxonomy(record, currentType, props.match.params)}>删除</Button>
                </span>
                )
            }
        }];
        return <IndexLayout>
            <PageHeader title="分类学管理" extra={
                props.sortType === config.name ?
                    <Button type={'primary'}
                            onClick={() => props.saveSort(type, props.match.params.id)}>保存排序结果</Button> :
                    <>
                        <Button type="primary" onClick={props.newRecord}>
                            新增{config.label}
                        </Button>
                        <Button onClick={() => props.doSort(type)}>
                            排序
                        </Button>
                    </>
            }/>
            <DndProvider backend={HTML5Backend} context={window}>
                <Table
                    columns={columns}
                    components={this.components}
                    dataSource={props.list}
                    rowKey={'id'}
                    onRow={(record, index) => ({
                        index,
                        moveRow: (...params) => props.moveRecord(config.name, ...params),
                    })}
                    pagination={false}
                    loading={props.loading}
                />
            </DndProvider>
            <Modal
                open={this.mount && props.showEdit} destroyOnClose
                onCancel={props.hiddenEdit} onOk={() => props.saveRecord(config.name, props.match.params)}
            >
                <Form>
                    <Form.Item label={config.label}>
                        <Input name={'chineseName'} value={props.edit.chineseName}
                               onChange={props.onEditChange}/>
                    </Form.Item>
                    <Form.Item label={config.name}>
                        <Input name={'name'} value={props.edit.name} onChange={props.onEditChange}/>
                    </Form.Item>
                    <Form.Item label={'图片'}>
                        <ImgListEditor images={props.edit.images} onChange={props.onEditImagesChange}>
                            <div>
                                <PlusOutlined/>
                                <div className="ant-upload-text">图片上传</div>
                            </div>
                        </ImgListEditor>
                    </Form.Item>
                </Form>
            </Modal>
        </IndexLayout>;
    }
}

const ManageTaxonomy = connect(state => state.manage.insect.taxonomy, actions)(Taxonomy);

export default ManageTaxonomy;
