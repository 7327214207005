import React, {lazy, Suspense} from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import loadable from '@loadable/component';
import InsectDetail from '../page/insect/detail';
import {connect} from 'react-redux';
import Passport from '../page/Passport';
import InsectUpload from '../page/insect/upload';
import InsectAudit from '../page/insect/audit';
import InsectBatch from '../page/insect/batch';
import InsectSpecial from '../page/insect/special';
import ManageInsectSpecial from '../page/manage/special';
import ManageInsectSpecialDetail from '../page/manage/special/detail';
import InsectSpecialDetail from '../page/insect/special/detail';
import ManageInsectMorphologyDetail from '../page/manage/morphology/detail';
import ManageInsectMorphology from '../page/manage/morphology';
import InsectAnalyse from '../page/insect/analyse';
import ManageInsect from '../page/manage/insect/index';
import ManageInsectCollect from '../page/manage/insect/collect';
import ManageLiterature from "../page/manage/literature";
import UserIndex from '../page/users/index';
import ManageUser from '../page/manage/user';
import ManageUserDetail from '../page/manage/user/detail';
import ManageTaxonomy from '../page/manage/insect/taxonomy';
import InsectTaxonomy from '../page/insect/taxonomy';
import InsectTaxonomyFamily from '../page/insect/taxonomy/family';
import InsectSearch from '../page/insect/search';
import ManageInsectMorphologySearch from '../page/manage/morphology/search';
import ManageInsectMorphologySearchEdit from '../page/manage/morphology/search/edit';
import InsectClass from '../page/insect/class';
import SpecialRouter from './special';
import ManageLiteratureDetail from '../page/manage/literature/detail';
import ManageArticleList from '../page/manage/article/list';
import Article from '../page/article';
import ManageArticleReference from '../page/manage/article/reference';
import ManageInsectAdd from '../page/insect/add';
import InsectSpecialTaxonomy from '../page/insect/special/taxonomy';
import InsectDistribution from '../page/insect/distribution';

const Main = loadable(() => import(/* webpackChunkName: "Main" */'../page/index/Main'));
const Province = loadable(() => import(/* webpackChunkName: "Province" */'../page/index/Province'));
const Csldias = loadable(() => import(/* webpackChunkName: "Csldias" */'../page/index/Csldias'));
const ManageArticleEdit = lazy(() => import(/* webpackChunkName: "article-edit" */'../page/manage/article/edit'));

let MainPage = Main;

switch (process.env.REACT_APP_ENV) {
    case 'province':
        MainPage = Province;
        break;
    case 'csldias':
        MainPage = Csldias;
        break;
    default:
}

const IndexRouter = (props) => (
    <Router history={props.history}>
        <Suspense fallback={<div>loading...</div>}>
            <Switch location={window.location}>
                <Route
                    exact path="/" key="MainPage"
                    component={MainPage}
                />
                <Route path="/insect/upload" component={InsectUpload} key="InsectUpload"/>
                <Route path="/insect/batch" component={InsectBatch} key="InsectUpload"/>
                <Route path="/insect/audit" component={InsectAudit}/>
                <Route path="/insect/analyse" component={InsectAnalyse}/>
                <Route path="/insect/special/:id/insect" component={SpecialRouter}/>
                <Route path="/insect/special/:id/taxonomy" component={InsectSpecialTaxonomy}/>
                <Route path="/insect/special/:id" component={InsectSpecialDetail}/>
                <Route path="/insect/special" component={InsectSpecial}/>
                <Route path="/insect/taxonomy/order/:orderId/family/:familyId" component={InsectTaxonomyFamily}/>
                <Route path="/insect/taxonomy/:type/:id/:subType" component={InsectTaxonomy}/>
                <Route path="/insect/taxonomy/:type/:id" component={InsectTaxonomy}/>
                <Route path="/insect/taxonomy/:type" component={InsectTaxonomy}/>
                <Route path="/insect/taxonomy" component={InsectTaxonomy}/>
                <Route path="/insect/class/:type" component={InsectClass}/>
                <Route path="/insect/search/:type" component={InsectSearch}/>
                <Route path="/insect/distribution" component={InsectDistribution}/>
                <Route path="/insect/:id" component={InsectDetail} key="InsectDetailPage"/>
                <Route path="/article/:id" component={Article}/>
                <Route path="/manage/insect/morphology/detail/:id" component={ManageInsectMorphologyDetail}/>
                <Route path="/manage/insect/morphology/search/edit/:id?" component={ManageInsectMorphologySearchEdit}/>
                <Route path="/manage/insect/morphology/search" component={ManageInsectMorphologySearch}/>
                <Route path="/manage/insect/morphology" component={ManageInsectMorphology}/>
                <Route path="/manage/insect/special/:id" component={ManageInsectSpecialDetail}/>
                <Route path="/manage/insect/special" component={ManageInsectSpecial}/>
                <Route path="/manage/insect/taxonomy/:type/:id/:subType" component={ManageTaxonomy}/>
                <Route path="/manage/insect/taxonomy/:type/:id" component={ManageTaxonomy}/>
                <Route path="/manage/insect/taxonomy/:type" component={ManageTaxonomy}/>
                <Route path="/manage/insect/taxonomy" component={ManageTaxonomy}/>
                <Route path="/manage/insect/add" component={ManageInsectAdd}/>
                <Route path="/manage/insect/index" component={ManageInsect}/>
                <Route path="/manage/insect/:id/collect" component={ManageInsectCollect}/>
                <Route path="/manage/literature/:id/detail" component={ManageLiteratureDetail}/>
                <Route path="/manage/literature" component={ManageLiterature}/>
                <Route path="/manage/user/:id" component={ManageUserDetail}/>
                <Route path="/manage/user" component={ManageUser}/>
                <Route path="/manage/article/list" component={ManageArticleList}/>
                <Route path="/manage/article/reference" component={ManageArticleReference}/>
                <Route path="/manage/article/edit/:id?" component={ManageArticleEdit}/>
                <Route path="/user/index" component={UserIndex}/>
                <Route path="/passport" component={Passport} key="Passport"/>
            </Switch>
        </Suspense>
    </Router>
);

export default connect(state => ({state}))(IndexRouter);
