import React from 'react';
import {connect} from 'react-redux';
import {Button, Divider, Form, Input, Modal, Table} from 'antd';
import {PageHeader} from '@ant-design/pro-components';
import IndexLayout from '../../IndexLayout';
import * as actions from './actions';

class Morphology extends React.Component {
    componentDidMount() {
        this.props.getList();
    }

    render() {
        const {props} = this;
        return <IndexLayout>
            <PageHeader title="形态管理" extra={
                <Button type="primary" onClick={props.newData}>
                    新建形态
                </Button>
            }/>
            <Table dataSource={props.listData} rowKey={'id'}>
                <Table.Column title="区域" dataIndex="area" key="area"/>
                <Table.Column title="形态类别" dataIndex="type" key="type"/>
                <Table.Column title="形态名称" dataIndex="name" key="name"/>
                <Table.Column
                    title="Action"
                    key="action"
                    render={(text, record) => (
                        <span>
                        <Button type="link" onClick={() => props.editRow(record)}>编辑</Button>
                        <Divider type="vertical"/>
                        <Button type="link" onClick={() => props.toDetail(record)}>查看详情</Button>
                        <Divider type="vertical"/>
                        <Button type="link" onClick={() => props.deleteRow(record)}>删除</Button>
                        </span>
                    )}
                />
            </Table>
            <Modal open={props.showEdit} onCancel={props.hiddenEdit} onOk={props.save}>
                <Form>
                    <Form.Item label={'区域'}>
                        <Input placeholder={'请输入区域'} name={'area'} value={props.edit.area}
                               onChange={props.inputChange}/>
                    </Form.Item>
                    <Form.Item label={'形态类别'}>
                        <Input placeholder={'请输入形态类别'} name={'type'} value={props.edit.type}
                               onChange={props.inputChange}/>
                    </Form.Item>
                    <Form.Item label={'形态名称'}>
                        <Input placeholder={'请输入形态名称'} name={'name'} value={props.edit.name}
                               onChange={props.inputChange}/>
                    </Form.Item>
                </Form>
            </Modal>
        </IndexLayout>;
    }
}


const ManageInsectMorphology = connect(state => state.manage.insect.morphology.index, actions)(Morphology);

export default ManageInsectMorphology;
