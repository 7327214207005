export const loadConfig = () => (dispatch, getState, {request}) => {
    return request.get('/config')
        .then(({data}) => {
            dispatch({type: 'state.frameworks', payload: {config: data}});
            return data;
        })
};

export const loadAccessCount = () => (dispatch, getState, {request}) => {
    return request.get('/count')
        .then(({data}) => {
            dispatch({type: 'state.frameworks', payload: {count: data}});
        })
}

export const loadReferenceData = () => (dispatch, getState, {request}) => {
    return request.get('/article/reference')
        .then(({data}) => {
            dispatch({type: 'state.frameworks', payload: {reference: data}});
        })
}

export {loadUserData} from '../util';
