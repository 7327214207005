import React from 'react';
import {Button, Card, Pagination, Popover} from 'antd';
import {Col, Row} from 'react-bootstrap';
import Carousel from 'react-slick';

const convertStatus = text => {
    if (text & 4) {
        return '审核通过';
    }
    if (text & 2) {
        return '驳回';
    }
    if (text & 1) {
        return '待审核';
    }
    return '待提交';
};

const columns = [{
    dataIndex: ['insectData', 'phylumChinese'],
    title: '门名'
}, {
    dataIndex: ['insectData', 'classChinese'],
    title: '纲名'
}, {
    dataIndex: ['insectData', 'orderChinese'],
    title: '目名'
}, {
    dataIndex: ['insectData', 'familyChinese'],
    title: '科名'
}, {
    dataIndex: ['insectData', 'specificNameChinese'],
    title: '种名'
}, {
    dataIndex: ['data', 'address'],
    title: '地址'
}, {
    dataIndex: ['data', 'lat'],
    title: '纬度'
}, {
    dataIndex: ['data', 'lng'],
    title: '经度'
}, {
    dataIndex: ['data', 'collectTime'],
    title: '采集时间'
}, {
    dataIndex: ['data', 'collectUserName'],
    title: '采集人'
}, {
    dataIndex: 'status',
    title: '状态',
    render: (text, record) => {
        const res = convertStatus(text);
        if (record.remark) {
            return <Popover content={record.remark} title="审核意见" trigger="hover">
                {res}
            </Popover>
        }
        return res;
    }
}];


class UserReportList extends React.Component {
    constructor(props) {
        super(props);
        this.operateColumns = [
            ...columns, {
                dataIndex: 'operate',
                title: '操作',
                render: (text, record) => {
                    if (record.status & 4) {
                        return null;
                    }
                    return <Button type={'link'} onClick={() => props.edit && props.edit(record)}>编辑</Button>
                }
            }
        ];
    }

    render() {
        const {props} = this;
        return <>
            <Row className={'row-cols-1 row-cols-md-3 row-cols-xl-5'}>
                {props.data.list && props.data.list.map(item => {
                    const {data: {images, collectTime}, insectData: {specificNameChinese}, id} = item
                    return <Col key={id} className={'py-3'}><Card
                        cover={
                            <Carousel arrows className={'inner-arrow'}>
                                {images && images.map((name, index) => (
                                    <div className={'img-4-3-wrapper'} key={index}>
                                        <img
                                            alt={name}
                                            src={`/uploadImg/tiny/${name}`}
                                        />
                                    </div>
                                ))}
                            </Carousel>
                        }
                    >
                        <p className={'h5'}>{specificNameChinese || '-'}</p>
                        <p className={'text-right'}>{collectTime}</p>
                        {
                            item.remark ? <Popover content={item.remark} title="审核意见" trigger="hover">
                                {convertStatus(item.status)}
                            </Popover> : convertStatus(item.status)
                        }
                        {
                            !(item.status & 4) &&
                            <Button type={'link'} onClick={() => props.edit && props.edit(item)}>编辑</Button>
                        }
                    </Card></Col>;
                })}
            </Row>
            <Pagination
                showTotal={(total, range) => `展示第${range[0]}-${range[1]}条, 共${total}条`}
                total={props.data.total}
                pageSize={props.param.limit}
                current={(props.param.offset / props.param.limit) + 1}
                onChange={props.pageChange}
            />
        </>
        // return <Table
        //     dataSource={props.data.list}
        //     rowKey={'id'}
        //     pagination={{
        //         showTotal: (total, range) => `展示第${range[0]}-${range[1]}条, 共${total}条`,
        //         total: props.data.total,
        //         pageSize: props.param.limit,
        //         current: (props.param.offset / props.param.limit) + 1,
        //         onChange: props.pageChange
        //     }}
        //     columns={props.canOperate ? this.operateColumns : columns}
        // >
        // </Table>;
    }
}

export default UserReportList;
