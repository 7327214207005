import React from 'react';
import {Upload} from 'antd';
import {equals} from 'ramda';
import request from '../../util/request';
import {connect} from 'react-redux';

class Editor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {fileList: this.computeFileList(props.images, props.urlPrefix), images: props.images};
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.images !== this.props.images && this.state.images !== this.props.images) {
            this.setState({fileList: this.computeFileList(this.props.images, this.props.urlPrefix)});
        }
    }

    computeFileList = (images = [], urlPrefix = '/uploadImg/tiny/') => {
        return images.map((fileName, index) => ({
            uid: index,
            fileName,
            url: `${urlPrefix}${fileName}`
        }))
    };

    fileChange = (data) => {
        const {fileList} = data;
        this.setState({fileList});
        const images = [];
        for (let i = 0; i < fileList.length; i++) {
            if (fileList[i].fileName) {
                images.push(fileList[i].fileName);
                continue;
            }
            if (fileList[i].response && fileList[i].response.length) {
                images.push(fileList[i].response[0]);
            } else if (fileList[i].status === 'done' && fileList[i].finalName) {
                images.push(fileList[i].finalName);
            }
        }
        if (!equals(images, this.state.images)) {
            this.setState({images});
            this.props.onChange && this.props.onChange(images);
        }
    };

    getSign = file => {
        return request.get(this.props.signUrl || '/users/insect/img/sign', {params: {name: file.name, type: file.type}})
            .then(({data}) => {
                file.finalName = data.key;
                return data;
            })
    }

    render() {
        const {props, state} = this;
        const {mode = 'local'} = props;
        let data;
        if (mode === 'oss') {
            data = {
                action: props.config.imgEndpoint,
                data: this.getSign
            }
        } else {
            data = {action: '/api/users/insect/img'}
        }
        return <Upload
            listType={'picture-card'}
            showUploadList={{showPreviewIcon: false}}
            {...data}
            {...props}
            fileList={state.fileList}
            onChange={this.fileChange}
        />;
    }
}

const ImgListEditor = connect(state => ({config: state.frameworks.config}))(Editor);

export default ImgListEditor;
