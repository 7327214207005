const initialData = {
    selected: null,
    search: '',
    listData: [],
    showSelector: false,
    sumData: null,
    allCollectPosition: null,
    topData: null
};

const reducer = (state = initialData, {type, payload}) => {
    if (type === 'action.main') {
        return {...state, ...payload};
    }
    return state;
};

export default reducer;
