import React from 'react';
import {Select} from 'antd';
import {useRequest} from 'ahooks-v2';
import request from '../util/request';

const LiteratureSelector = props => {
    const search = useRequest((title) => ({url: '/literature/search', params: {title}}), {
        requestMethod: (param) => request(param),
        formatResult: ({data}) => data,
        manual: true,
        debounceInterval: 500
    });
    return <Select placeholder={'请输入文献标题搜索'} showSearch filterOption={false} onSearch={search.run} loading={search.loading} labelInValue {...props}>
        {search.data && search.data.map(item =>
            <Select.Option value={item.id} key={item.id}>{item.title}</Select.Option>
        )}
    </Select>
}

export default LiteratureSelector;
