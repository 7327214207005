const initialData = {
    show: false,
    data: {}
};

const edit = (state = initialData, {type, payload}) => {
    if (type === 'state.insect.edit') {
        return {...state, ...payload};
    }
    if (type === 'state.insect.edit.data') {
        return {...state, data: {...state.data, ...payload}};
    }
    return state;
};

export default edit;
