import React from 'react';
import {Cascader} from 'antd';
import request from '../util/request';

export const optionFields = {
    taxonomy: ['classChinese', 'orderChinese', 'familyChinese', 'genusChinese', 'specificNameChinese'],
    address: ['province', 'city', 'district'],
    region: ['province', 'city', 'district'],
    host: ['hostFamilyChinese', 'host', 'specificNameChinese'],
};

class FilterCascader extends React.Component {
    state = {
        options: []
    }
    loadData = selectedOptions => {
        const {type, initialFilter = {}} = this.props;
        const startDepth = Object.keys(initialFilter).length;
        const optionField = optionFields[type];
        const depth = selectedOptions.length;
        const isLeaf = this.props.multiple || (depth >= optionField.length - 2);
        const params = {type, data: {data: 1}, ...initialFilter};
        let targetOption;
        if (depth) {
            targetOption = selectedOptions[depth - 1];
            targetOption.loading = true;
        }
        selectedOptions.forEach((item, index) => {
            params[optionField[index + startDepth]] = item.valueData;
        });
        request('/insect/analyse/filter', {params})
            .then(({data}) => {
                const options = data.map((item, index) => {
                    const valueData = item[optionField[depth + startDepth]];
                    return ({
                        valueData,
                        value: valueData || index,
                        label: valueData || '-',
                        isLeaf
                    });
                });
                if (targetOption) {
                    targetOption.loading = false;
                    targetOption.children = options;
                    this.setState({options: [...this.state.options]});
                } else {
                    this.setState({options});
                }
            });
    }
    onPopupVisibleChange = status => {
        const options = this.state.options;
        if (status && options.length === 0) {
            this.loadData([]);
        }
    }

    clearCache() {
        setTimeout(() => {
            this.loadData([]);
        })
    }

    render() {
        const {props: {initialFilter, ...props}, state} = this;
        return <Cascader
            options={state.options} changeOnSelect
            // onChange={(value, selectedOptions) => props.filterChange('host', value, selectedOptions)}
            loadData={this.loadData}
            onDropdownVisibleChange={this.onPopupVisibleChange}
            {...props}
        />
    }
}

export default FilterCascader;
