const initialData = {
    rows: [],
    total: 0,
    query: {
        order: 'desc',
        offset: 0,
        limit: 10
    },
    detail: {
        data: {id: 0, name: ''},
        listData: [],
        showAppend: false,
        appendData: {}
    }
};

const literature = (state = initialData, {type, payload}) => {
    if (type === 'state.manage.literature') {
        return {...state, ...payload};
    }
    if (type === 'state.manage.literature.query') {
        return {...state, query: {...state.query, ...payload}};
    }
    if (type === 'state.manage.literature.detail') {
        return {...state, detail: {...state.detail, ...payload}};
    }
    return state;
};

export default literature;
