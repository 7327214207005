const initialData = {
    list: [],
    familyList: [],
    insectData: {rows: [], total: 0},
    loading: false,
    currentOrder: {},
    currentFamily: {},
    parentData: {},
    parentPath: [null, null, null, null],
    query: {
        offset: 0,
        limit: 10,
        order: 'asc',
        sort: 'latinName'
    }
};

const taxonomy = (state = initialData, {type, payload}) => {
    if (type === 'state.insect.taxonomy') {
        return {...state, ...payload};
    }
    if (type === 'state.insect.taxonomy.query') {
        return {...state, query: {...state.query, ...payload}};
    }
    return state;
};

export default taxonomy;
