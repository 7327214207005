import {message, Modal} from "antd";

const update = payload => ({type: 'state.manage.insect.collect', payload});
const updateQuery = payload => ({type: 'state.manage.insect.collect.query', payload});

export const loadInsectData = (id) => (dispatch, getState, {request}) => {
    request.get(`/insect/detail/${id}`)
        .then(({data}) => {
            dispatch(update({insectData: data}));
        });
};

export const pageChange = (pagination) => (dispatch, getState) => {
    if (pagination && pagination.current) {
        const {query} = getState().manage.insect.collect;
        if (query.limit !== pagination.pageSize) {
            dispatch(update({rows: []}));
        }
        dispatch(updateQuery({offset: (pagination.current - 1) * pagination.pageSize, limit: pagination.pageSize}));
        dispatch(loadCollectData());
    }
};

export const loadCollectData = (id) => (dispatch, getState, {request}) => {
    const state = getState().manage.insect.collect;
    const {query} = state;
    if (!id) {
        id = state.insectData.id;
    }
    request(`/insect/${id}/collect`, {params: query})
        .then(({data}) => {
            console.log(data);
            data.rows.forEach(item => {
                const images = item.images ? item.images.split(',') : [];
                item.imgList = images.map((fileName, index) => ({
                    uid: index,
                    fileName,
                    url: `/uploadImg/tiny/${fileName}`
                }));
            });
            dispatch(update(data));
        });
};

export const deleteRow = ({id}) => (dispatch, getState, {request}) => {
    Modal.confirm({
        title: `确认删除该采集信息吗`,
        content: '删除后无法恢复',
        onOk: () => {
            return request.delete(`/users/insect/database/collect/${id}`)
                .then(({data}) => {
                    if (data) {
                        if (data.message === 'success') {
                            message.success('删除成功');
                            dispatch(loadCollectData());
                            return;
                        }
                    }
                    console.log(data);
                    message.error(JSON.stringify(data));
                });
        }
    });
};

export const editField = ({id}, {field, value}) => (dispatch, getState, {request}) => {
    if (value) {
        request.patch(`/users/insect/database/collect/${id}`, {[field]: value})
            .then(({data}) => {
                if (data) {
                    if (data.message === 'success') {
                        message.success('修改成功');
                        dispatch(loadCollectData());
                        return;
                    }
                }
                console.log(data);
                message.error(JSON.stringify(data));
            })
    }
};

export const imagesSave = (data, fileList) => dispatch => {
    const images = [];
    if (fileList.length === 0) {
        message.info('至少上传一张图片');
        return;
    }
    for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].fileName) {
            images.push(fileList[i].fileName);
            continue;
        }
        if (fileList[i].response && fileList[i].response.length) {
            images.push(fileList[i].response[0]);
        }
    }
    dispatch(editField(data, {field: 'images', value: images.join(',')}));
};

export const batchDelete = () => (dispatch, getState, {request}) => {
    const state = getState().manage.insect.collect;
    const {batchOption, checkedRows} = state;
    if (batchOption) {
        if (checkedRows.length === 0) {
            message.info('请选择要删除的行');
            return;
        }
        Modal.confirm({
            title: `确认删除${checkedRows.length}条采集信息吗`,
            content: '删除后无法恢复',
            onOk: () => {
                return Promise.all(checkedRows.map(id => {
                    return request.delete(`/users/insect/database/collect/${id}`)
                        .then(({data}) => {
                            if (data) {
                                return data.message;
                            }
                            return data;
                        });
                }))
                    .then(res => {
                        dispatch(loadCollectData());
                        dispatch(update({batchOption: false, checkedRows: []}));
                        const success = res.filter(item => item === 'success');
                        if (success.length === checkedRows.length) {
                            message.info('操作成功');
                            return;
                        }
                        message.error(`${checkedRows.length - success.length}条数据删除失败`, 10);
                    })
            }
        });
        return;
    }
    dispatch(update({batchOption: true}));
};

export const onSelectChange = checkedRows => update({checkedRows});
