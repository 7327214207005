const initialData = {
    option1: 'host',
    insectId: undefined,
    addressFilter: {},
    filter: {}
};

const analyse = (state = initialData, {type, payload}) => {
    if (type === 'state.insect.analyse') {
        return {...state, ...payload};
    }
    if (type === 'state.insect.analyse.filter') {
        return {...state, filter: {...state.filter, ...payload}};
    }
    if (type === 'state.insect.analyse.addressFilter') {
        return {...state, addressFilter: {...state.addressFilter, ...payload}};
    }
    return state;
};

export default analyse;
