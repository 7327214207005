import React from 'react';
import {useRequest} from 'ahooks-v2';
import request from '../../util/request';
// import './ck-style.css';

const ArticleContent = props => {
    const loadData = useRequest(() => {
        return {url: `/article/detail/${props.id}`};
    }, {
        requestMethod: (param) => request(param),
        formatResult: ({data}) => data,
    });
    return <div dangerouslySetInnerHTML={{__html: loadData.data && loadData.data.content}} className={'ck-content'}/>
}

export default ArticleContent;
