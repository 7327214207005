import {useEffect} from 'react';
import {connect} from 'react-redux';
import * as actions from './actions';
// import {ConfigProvider} from 'antd';

const framework = props => {
    useEffect(() => {
        // let screenMode = 'mobile';
        // console.log('addEventListener resize')
        // window.addEventListener('resize', () => {
        //     console.log('resize');
        //     if (window.innerWidth >= 992) {
        //         if (screenMode === 'mobile') {
        //             ConfigProvider.config({
        //                 prefixCls: 'custom',
        //                 theme: {
        //                     fontSizeBase: '16px',
        //                     fontSizeSm: '14px',
        //                     heightBase: '40px',
        //                     heightLg: '48px',
        //                     heightSm: '32px',
        //                     primaryColor: '#25b864'
        //                 }
        //             });
        //             screenMode = 'desktop';
        //             console.log('desktop');
        //         }
        //     } else if (screenMode === 'desktop') {
        //         ConfigProvider.config({
        //             theme: {}
        //         });
        //         screenMode = 'mobile';
        //         console.log('mobile');
        //     }
        // });
        props.loadConfig()
            .then(config => {
                if (config.readOnly === false) {
                    props.loadUserData(false, !config.anonymousAccess);
                }
                props.loadAccessCount();
                props.loadReferenceData();
            });
    }, [])
    if (props.config) {
        return props.children;
    }
    return null;
}

const Framework = connect(state => state.frameworks, actions)(framework);

export default Framework;
