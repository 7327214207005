import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Button, Table} from 'antd';
import {PageHeader} from '@ant-design/pro-components';
import IndexLayout from '../../../IndexLayout';
import request from '../../../../util/request';
import {useRequest} from 'ahooks-v2';

const ManageArticleList = props => {
    const {data} = useRequest('/users/article/count', {
        requestMethod: (param) => request(param),
        formatResult: ({data}) => data,
        onSuccess: ({total}) => mutate((data = {}) => ({...data, total}))
    });
    const {
        tableProps,
        mutate
    } = useRequest(({current, pageSize}) => {
        return {
            url: '/users/article/list',
            params: {offset: (current - 1) * pageSize, limit: pageSize}
        }
    }, {
        requestMethod: (param) => request(param),
        paginated: true,
        formatResult: ({data: list}) => ({list, total: (data && data.total) || list.length})
    });
    return <IndexLayout>
        <Container>
            <Row><Col>
                <PageHeader
                    title={'素材管理'}
                    extra={<Button.Group>
                        <Button type={'primary'} onClick={() => props.history.push('edit')}>新建</Button>
                        <Button onClick={() => props.history.push('reference')}>引用管理</Button>
                    </Button.Group>}
                />
                <Table {...tableProps} rowKey={'id'}>
                    <Table.Column title="编号" dataIndex="id"/>
                    <Table.Column title="标题" dataIndex="title"/>
                    <Table.Column title="操作" dataIndex="operate" render={(text, record) => {
                        return <Button.Group>
                            <Button type={'link'} onClick={() => props.history.push(`/article/${record.id}`)}>查看</Button>
                            <Button type={'link'} onClick={() => props.history.push(`edit/${record.id}`)}>编辑</Button>
                        </Button.Group>;
                    }}/>
                </Table>
            </Col></Row>
        </Container>
    </IndexLayout>;
}

export default ManageArticleList;
