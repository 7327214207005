import React from 'react';

export const didMountEnhancer = (Component, componentDidMount) => (
    class didMountEnhancer extends React.Component {
        componentDidMount() {
            componentDidMount.call(this);
        }

        render() {
            return <Component {...this.props}/>
        }
    }
);

export const insectField = [
    {key: 'order', label: 'Order'},
    {key: 'orderChinese', label: '目名'},
    {key: 'family', label: 'Family'},
    {key: 'familyChinese', label: '科名'},
    {key: 'specificNameChinese', label: '中文种名'},
    {key: 'latinName', label: '拉丁学名', render: (value, {authorYear}) => `${value} ${authorYear}`}
];

export const insectDisplayField = [
    {key: 'phylum', label: 'Phylum', detailOnly: true},
    {key: 'phylumChinese', label: '门名', detailOnly: true},
    {key: 'class', label: 'Class', detailOnly: true},
    {key: 'classChinese', label: '纲名', detailOnly: true},
    {key: 'order', label: 'Order', detailOnly: true},
    {key: 'orderChinese', label: '目名'},
    {key: 'family', label: 'Family', detailOnly: true},
    {key: 'familyChinese', label: '科名'},
    {key: 'genus', label: 'Genus', detailOnly: true},
    {key: 'genusChinese', label: '中文属名'},
    {key: 'specificName', label: 'specific name', detailOnly: true},
    {key: 'subspeciesName', label: 'subspecies name', detailOnly: true},
    {key: 'specificNameChinese', label: '中文种名'},
    {key: 'namer', label: '命名人'},
    {key: 'nameYear', label: '命名年代'},
    {key: 'latinName', label: '拉丁学名'},
    {key: 'authorYear', label: '命名人,命名年代'},
    {key: 'taxonomyRemark', label: '分类学评述'}
];

export const insectInputTextField = [
    {key: 'genus', label: 'Genus'},
    {key: 'genusChinese', label: '中文属名'},
    {key: 'specificName', label: 'specific name'},
    {key: 'subspeciesName', label: 'subspecies name'},
    {key: 'specificNameChinese', label: '中文种名'},
    {key: 'namer', label: '命名人'},
    {key: 'nameYear', label: '命名年代'},
    {key: 'latinName', label: '拉丁学名'},
    {key: 'authorYear', label: '命名人,命名年代'},
    {key: 'taxonomyRemark', label: '分类学评述'}
];

export const insectQueryField = [{
    field: 'taxonomy', label: '分类学', children: [
        {field: 'orderChinese', label: '目名'},
        {field: 'familyChinese', label: '科名'},
        {field: 'genus', label: '拉丁属名'},
        {field: 'specificName', label: '拉丁种名'},
        {field: 'specificNameChinese', label: '中文种名'}
    ]
}, {field: 'host', label: '寄主'}, {field: 'morphology', label: '形态'}];

export const taxonomyField = [
    {key: 'phylumChinese', name: 'phylum', label: '门名'},
    {key: 'classChinese', name: 'class', label: '纲名'},
    {key: 'orderChinese', name: 'order', label: '目名'},
    {key: 'familyChinese', name: 'family', label: '科名'}
];

export const auditFilterField = [
    {field: 'empty', label: '无'},
    {field: 'taxonomy', label: '分类学'},
    {field: 'user', label: '用户名'},
    {field: 'address', label: '采集地点'}
];

export const literatureField = [
    {field: 'author', label: '作者'},
    {field: 'year', label: '年'},
    {field: 'title', label: '题目'},
    {field: 'periodical', label: '期刊'},
    {field: 'volume', label: '卷'},
    {field: 'pageSum', label: '总页数'},
    {field: 'page', label: '页码'},
    {field: 'latinName', label: '拉丁名'},
];
