import React, {forwardRef} from 'react';
import {Button, Form, Input, message, Modal, Radio} from 'antd';
import {useRequest} from 'ahooks-v2';
import request from '../../../util/request';
import {useSelector} from 'react-redux';
import {preserveKey} from './actions';

const rules = [{required: true}];

const literatureField = {
    monograph: [{
        name: 'author', label: '作者', rules
    }, {
        name: 'year', label: '出版年', rules
    }, {
        name: 'title', label: '题目', rules
    }, {
        name: 'periodical', label: '出版社', rules
    }, {
        name: 'volume', label: '出版社所在地', rules
    }, {
        name: 'pageSum', label: '总页数', rules
    }],
    periodical: [{
        name: 'author', label: '作者', rules
    }, {
        name: 'year', label: '出版年', rules
    }, {
        name: 'title', label: '题目', rules
    }, {
        name: 'periodical', label: '期刊', rules
    }, {
        name: 'volume', label: '卷(期)', rules
    }, {
        name: 'pageSum', label: '页码', rules
    }]
}

const LiteratureEdit = forwardRef((props, ref) => {
    return <Form {...props} ref={ref}>
        <Form.Item label={'文献类型'} name={'type'} rules={rules}>
            <Radio.Group disabled={props.data}>
                <Radio value={'periodical'}>期刊</Radio>
                <Radio value={'monograph'}>专著</Radio>
            </Radio.Group>
        </Form.Item>
        <Form.Item dependencies={['type']} noStyle>
            {({getFieldValue}) => {
                const type = getFieldValue('type');
                return literatureField[type] && literatureField[type].map(item => (
                    <Form.Item {...item} key={item.name}><Input/></Form.Item>
                ));
            }}
        </Form.Item>
    </Form>
});

export const literatureEdit = (params) => {
    const submitRow = useRequest((data, record) => {
        if (record) {
            const diff = {};
            Object.keys(data).forEach(key => {
                if (preserveKey[key] || data[key] !== record[key]) {
                    diff[key] = data[key];
                }
            })
            if (Object.keys(diff).length) {
                return {url: `/users/literature/${record.id}`, data: diff, method: 'PATCH'}
            }
            return Promise.resolve({})
        }
        return {url: '/users/literature', data, method: 'PUT'}
    }, {
        requestMethod: (param) => request(param),
        formatResult: ({data}) => data,
        manual: true
    });
    const user = useSelector(state => state.frameworks.user);
    const edit = (data) => {
        let form;
        Modal.confirm({
            title: '文献',
            icon: null,
            content: <LiteratureEdit
                ref={e => form = e}
                initialValues={data}
            />,
            onOk: () => {
                if (form) {
                    return form.validateFields()
                        .then(values => {
                            return submitRow.run(values, data)
                                .then(data => {
                                    params && params.onSuccess && params.onSuccess({...values, id: data.id});
                                });
                        })
                }
            }
        })
    }
    const create = () => {
        if (user.permission.literatureAdd) {
            edit();
        } else {
            message.error('没有权限操作');
        }
    };
    return [{edit, create}];
}

export const AddLiterature = props => {
    const [{create: showCreate}] = literatureEdit();
    return <Button {...props} onClick={showCreate}>{props.children}</Button>
}

export default LiteratureEdit;
