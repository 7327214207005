import {message, Modal} from 'antd';

const update = payload => ({type: 'state.manage.insect.taxonomy', payload});
const updateEdit = payload => ({type: 'state.manage.insect.taxonomy.edit', payload});

export const onEditImagesChange = images => updateEdit({images});

export const onEditChange = ({target}) => updateEdit({[target.name]: target.value});

export const hiddenEdit = () => update({showEdit: false});

export const editRecord = record => update({showEdit: true, edit: record});

export const newRecord = () => ({type: 'state.manage.insect.taxonomy.newRecord'});

export const deleteRecord = (type, record, callback) => (dispatch, getState, {request}) => {
    Modal.confirm({
        title: `确认删除${record.chineseName}吗`,
        onOk: () => {
            return request.delete(`/users/insect/taxonomy/${type}/${record.id}`)
                .then(({data}) => {
                    if (data) {
                        if (data.message === 'success') {
                            message.success('删除成功');
                            callback();
                            return;
                        }
                    }
                    message.error(JSON.stringify(data));
                });
        }
    });
};

export const moveRecord = (type, dragIndex, hoverIndex) => (dispatch, getState, {request}) => {
    const taxonomy = getState().manage.insect.taxonomy;
    const dataKey = 'list';
    const dataList = taxonomy[dataKey];
    if (dataList) {
        const dragRow = dataList[dragIndex];
        const result = [...dataList];
        result.splice(dragIndex, 1);
        result.splice(hoverIndex, 0, dragRow);
        dispatch(update({[dataKey]: result, sortType: type}));
    }
};

export const saveSortResult = (type, id) => (dispatch, getState, {request}) => {
    const taxonomy = getState().manage.insect.taxonomy;
    const dataList = taxonomy.list;
    if (dataList) {
        const sortResult = dataList.map(item => item.id).reverse().join(',');
        const keyList = [type];
        id && keyList.push(id);
        request.put(`/users/insect/taxonomy/sort/${keyList.join('_')}`, {data: sortResult})
            .then(({data}) => {
                dispatch(update({sortType: ''}));
            })
    }
};
