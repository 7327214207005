const initialData = {
    insectValue: '',
    insectMessage: '',
    collectMessage: '',
    imgProgress: 0,
    imgMessage: '',
    specialMessage: '',
    literatureMessage: '',
    morphologyMessage: '',
    data: {}
};

const batch = (state = initialData, {type, payload}) => {
    if (type === 'state.insect.batch') {
        return {...state, ...payload};
    }
    if (type === 'state.insect.batch.data') {
        return {...state, data: {...state.data, ...payload}};
    }
    return state;
};

export default batch;
