const insectSelector = {
    show: false, query: '', queryMessage: '', queryTimer: null, selected: {}, list: [], showNoData: false
};

const initialData = {
    messageList: [],
    user: {},
    insectSelector,
    config: null,
    count: null,
    reference: null
};

const reducer = (state = initialData, {type, payload}) => {
    console.log(type, payload);
    if (type === 'state.frameworks') {
        return {...state, ...payload};
    }
    if (type === 'state.frameworks.insectSelector') {
        return {...state, insectSelector: {...state.insectSelector, ...payload}};
    }
    if (type === 'reset.frameworks.insectSelector') {
        return {...state, insectSelector};
    }
    return state;
};

export default reducer;
