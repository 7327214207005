import {Badge, Dropdown} from 'react-bootstrap';
import React from 'react';

const DropdownField = props => {
    const {deep = 0} = props;
    const paddingLeft = `${1.5 + deep}rem`;
    return (<>
        <Dropdown.Item key={props.field} eventKey={props.field} style={{paddingLeft}} disabled={!!props.children}>
            {props.label}
            {props.sum && <>{' '}<Badge variant="danger">{props.sum}</Badge></>}
        </Dropdown.Item>
        {props.children && props.children.map(item => <DropdownField {...item} deep={deep + 1} key={item.field}/>)}
    </>);
};

export default DropdownField;
