import React from 'react';
import IndexLayout from '../../IndexLayout';
import {Col, Container, Row} from 'react-bootstrap';
import {Card, Typography} from 'antd';
import config from '../../index/config';
import {connect} from 'react-redux';
import * as actions from './actions';

const Class = props => {
    const {type} = props.match.params;
    return <IndexLayout>
        <Container>
            <Row>
                <Col>
                    <Typography.Title level={3}>{config[type].name}检索</Typography.Title>
                </Col>
            </Row>
            <Row className={'align-items-center'}>
                {config[type].search.length === 2 && <Col>
                    <img
                        className={'w-100'}
                        alt=""
                        src={config[type].img}
                    />
                </Col>}
                <Col>
                    <Card
                        onClick={() => props.toTaxonomy(config[type].route)}
                        cover={
                            <img
                                alt=""
                                src={config[type].search[0].img}
                            />
                        }
                    >
                        按照分类系统检索
                    </Card>
                </Col>
                {config[type].search.length !== 2 && <Col>
                    <Card onClick={props.toHost}
                          cover={
                              <img
                                  alt=""
                                  src={config[type].search[1].img}
                              />
                          }
                    >
                        寄主分类
                    </Card>
                </Col>}
                <Col>
                    <Card onClick={props.toMorphology}
                          cover={
                              <img
                                  alt=""
                                  src={(config[type].search[2] || config[type].search[1]).img}
                              />
                          }
                    >
                        按照形态特征检索
                    </Card>
                </Col>
            </Row>
        </Container>
    </IndexLayout>
}

const InsectClass = connect(state => state.insect.class || {}, actions)(Class);

export default InsectClass;
