import {Button, ButtonGroup, Col, Container, Dropdown, Form, Image, Row, Table} from 'react-bootstrap';
import {omit} from 'ramda';
import Layout from '../../IndexLayout';
import React from 'react';
import {connect} from 'react-redux';
import InsectSelector from '../../../component/InsectSelector';
import * as actions from './actions';
import {Map, Marker} from 'react-amap';
import {auditFilterField, taxonomyField} from '../../util';
import DropdownField from '../../../component/DropdownField';
import ImgListEditor from '../../../component/ImgListEditor';
import {Input, Select, Typography} from 'antd';
import debounce from 'lodash/debounce';
import request from '../../../util/request';
import Carousel from 'react-slick';
import HostSelector from '../../../component/HostSelector';
import {AddHost} from '../../manage/host/edit';

const auditFilterObj = {};

auditFilterField.forEach(({field, label}) => {
    auditFilterObj[field] = label;
});

const displaySort = ['insectId', 'province', 'city', 'district', 'township', 'address', 'lat', 'lng', 'host', 'hostLatinName', 'hostFamilyChinese', 'hostFamily', 'remark'];

const AuditResult = connect(() => ({}), {
    changeInsectId: actions.changeInsectId,
    showAddInsect: actions.showAddInsect
})(props => <>
    <InsectSelector
        insectId={props.insectId}
        onSelect={props.changeInsectId}
        noDataTip={<Button variant='link' onClick={props.showAddInsect}>系统中没有该昆虫</Button>}
    />
    <Button variant="link" onClick={props.showAddInsect}>添加新物种</Button>
</>);

const config = {
    province: {label: '省'},
    city: {label: '市'},
    district: {label: '区'},
    township: {label: '街道'},
    address: {label: '详细地址'},
    lat: {label: '纬度'},
    lng: {label: '经度'},
    hostId: {visible: false},
    host: {
        label: '寄主植物',
        render: function (value, data, props) {
            let host = {};
            if (data.hostId) {
                host.id = data.hostId;
                host.name = data.hostLatinName;
                host.nameChinese = data.host;
                host.family = data.hostFamily;
                host.familyChinese = data.hostFamilyChinese;
                console.log(props.changeFields, 'changeFields');
                return value && <tr>
                    <td className="align-middle">寄主植物</td>
                    <td>
                        <HostSelector data={host} style={{minWidth: '50%'}} onChange={data => props.changeFields({
                            hostId: data.id,
                            host: data.nameChinese,
                            hostLatinName: data.name,
                            hostFamilyChinese: data.familyChinese,
                            hostFamily: data.family
                        })}/>
                        <AddHost type={'link'} className={'mx-1'}>新增寄主植物</AddHost>
                    </td>
                </tr>;
            }
            return null;
        }
    },
    hostLatinName: {label: '寄主拉丁名'},
    hostFamilyChinese: {label: '寄主中文科名'},
    hostFamily: {label: '寄主拉丁科名'},
    collectTime: {label: '采集时间'},
    collectUserName: {label: '采集人'},
    harmWay: {label: '生物学'},
    insectState: {label: '发育阶段'},
    habitatType: {label: '生境类型'},
    images: {
        render: function (value, data, props) {
            return value && <tr>
                <td className="align-middle">图片</td>
                <td>
                    <ImgListEditor
                        images={value} showUploadList={{showPreviewIcon: true}}
                        onChange={props.changeImages}
                        mode={'oss'}
                        onPreview={e => window.open(`/uploadImg/origin/${e.fileName}`)}
                    />
                </td>
            </tr>;
        }
    },
    insectId: {
        render: function (value, data, props) {
            return <tr>
                <td className="align-middle">鉴定结果</td>
                <td>
                    <Row>
                        <AuditResult insectId={value}/>
                    </Row>
                </td>
            </tr>;
        }
    },
    remark: {
        render: (value, data, props) => {
            return <tr>
                <td className="align-middle">备注</td>
                <td>
                    <Input placeholder={'请输入备注'} name={'remark'} value={value} onChange={props.changeField}/>
                </td>
            </tr>;
        }
    },
    id: {visible: false}
};

const AuditField = (props) => {
    const {data, field, label, render, formatter, visible} = props;
    const value = data[field];
    if (visible === false) return null;
    if (render) return render(value, data, props);
    if (value) {
        return <tr>
            <td className={'text-nowrap'}>{label || field}</td>
            <td>{formatter ? formatter(value) : value}</td>
        </tr>;
    }
    return null;
};

const FilterSelector = props => {
    switch (props.selected) {
        case 'taxonomy':
            const {filterData, groupData} = props;
            return <Form.Group as={Col}>
                <Form.Label>类别</Form.Label>
                <Dropdown id="filter-taxonomy" onSelect={props.selectTaxonomy}>
                    <Dropdown.Toggle
                        variant="light"
                        style={{width: '100%', border: '1px solid #ced4da'}}
                    >
                        {filterData.taxonomy || '请选择类别'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{width: '100%'}}>
                        {groupData.map(item => <DropdownField {...item} key={item.field}/>)}
                    </Dropdown.Menu>
                </Dropdown>
            </Form.Group>;
        case 'user':
            return <Form.Group as={Col}>
                <Form.Label>用户名</Form.Label>
                <Form.Control type="text" placeholder="请输入用户名" value={props.filterData.user || ''}
                              onChange={props.inputUsername}/>
            </Form.Group>;
        case 'address':
            return <Form.Group as={Col}>
                <Form.Label>地址</Form.Label>
                <Form.Control type="text" placeholder="地址" value={props.filterData.address || ''}
                              onChange={props.inputAddress}/>
            </Form.Group>;
        case 'empty':
        default:
            return <Form.Group as={Col}/>;
    }
};

const AuditView = props => {
    const {data, auxiliaryData, insect} = props;
    return <>
        {data.lat &&
        <div style={{height: '250px'}}>
            <Map amapkey={'88369698bd3d994eb5f636508e005f8d'} version={'1.4.15'} center={[data.lng, data.lat]} zoom={16}>
                <Marker position={[data.lng, data.lat]}/>
            </Map>
        </div>
        }
        <Table striped hover>
            <tbody>
            {taxonomyField.map(({key, label}) =>
                <AuditField key={key} field={key} data={insect} label={label}/>
            )}
            {auxiliaryData.matchMode === 3 &&
            <AuditField data={insect} field={'specificNameChinese'} label={'种名'}/>
            }
            {displaySort.concat(Object.keys(omit(displaySort, data))).map(key =>
                <AuditField {...props} {...config[key]} data={data} key={key} field={key}/>)}
            </tbody>
        </Table>
    </>;
};

class ReferInsect extends React.Component {
    constructor(props) {
        super(props);
        this.handleSearch = debounce(this.doSearch, 500);
    }

    doSearch = query => {
        this.setState({searchText: query, searching: true});
        if (query) {
            request.get('/insect/query', {params: {query}})
                .then(({data}) => {
                    this.setState({searchList: data, searching: false});
                })
        }
    };

    state = {searchList: [], searchText: '', searching: false};

    render() {
        const {searchList, searchText, searching} = this.state;
        const {insect, selectInsect} = this.props;
        return <div>
            <Typography.Title level={4}>
                参考物种
            </Typography.Title>
            <Select className={'d-block mb-1'} onSearch={this.handleSearch} showSearch filterOption={false}
                    placeholder="请输入科名/物种名称搜索" onChange={selectInsect} value={insect.id}>
                {searchList.map(item =>
                    <Select.Option value={item.id} key={item.id}>{item.specificNameChinese}</Select.Option>
                )}
                {searchText && searchList.length === 0 && !searching &&
                <Select.Option value={-1}>{searchText}</Select.Option>}
            </Select>
            {(insect.images && insect.images.length) ? <Carousel
                infinite={false}
                arrows
            >
                {insect.images.map(({url, key}) =>
                    <div className={'img-4-3-wrapper'} key={key}>
                        <img src={url} className="px-1" alt=""/>
                    </div>
                )}
            </Carousel> : '暂无图片'}
        </div>;
    }
}

class Audit extends React.Component {
    // componentDidMount() {
    //     this.props.loadData(this.props.match.params);
    // }
    //
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.activeIndex !== prevProps.activeIndex) {
            this.props.onIndexChange();
        }
    }

    render() {
        const props = this.props;
        const {list, activeIndex, insectData, query: {offset}, total, switchPage, rejectCollect, doAudit} = props;
        const {data, auxiliaryData} = list[activeIndex] || {};
        const insect = data && data.insectId ? insectData : auxiliaryData;
        return <Layout>
            <Container>
                <Row>
                    <Col>
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label>筛选依据:</Form.Label>
                                    <Dropdown style={{width: '100%'}} onSelect={props.selectKey} id="filter-1">
                                        <Dropdown.Toggle
                                            variant="light"
                                            style={{width: '100%', border: '1px solid #ced4da'}}
                                        >
                                            {auditFilterObj[props.selected] || '筛选依据'}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu style={{width: '100%'}}>
                                            {auditFilterField.map(item =>
                                                <Dropdown.Item key={item.field} eventKey={item.field}>
                                                    {item.label}
                                                </Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Form.Group>
                                <FilterSelector {...props}/>
                                <Form.Group as={Col} style={{flexGrow: 0}}>
                                    <Form.Label>&nbsp;</Form.Label>
                                    <div>
                                        <Button variant="primary" onClick={props.startFilter}
                                                style={{whiteSpace: 'nowrap'}}>开始鉴定</Button>
                                    </div>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Col>
                </Row>
                <Row className="justify-content-between" style={{padding: '10px'}}>
                    <Col className="h3">物种鉴定</Col>
                </Row>
                <Row>
                    <Col className="img-list-wrapper" xs={6}>
                        <Row className="img-list row-cols-2">
                            {list.map(({data: {images: [url] = []}, id}, index) => url &&
                                <Col key={id}>
                                    <div className={'img-4-3-wrapper'}>
                                        <Image thumbnail src={`/uploadImg/tiny/${url}`} data-index={index}
                                               className={index === activeIndex && 'active'}
                                               onClick={props.clickImage}/>
                                    </div>
                                </Col>)}
                        </Row>
                        {list.length > 0 && <div className={'text-center'}>
                            <ButtonGroup aria-label="Toolbar">
                                <Button disabled={offset === 0 && activeIndex === 0}
                                        onClick={() => switchPage(-1)}>上一个</Button>
                                <Button variant={'success'} onClick={() => doAudit(1)}>通过</Button>
                                <Button variant={'danger'} onClick={rejectCollect}>驳回</Button>
                                <Button disabled={offset + activeIndex >= total - 1}
                                        onClick={() => switchPage(1)}>下一个</Button>
                            </ButtonGroup>
                            <Input placeholder={'审核意见'} className={'my-1'} onChange={props.changeRemark}/>
                        </div>}
                    </Col>
                    <Col xs={6}>
                        {data && <>
                            <ReferInsect insect={props.referInsect} selectInsect={props.selectReferInsect}/>
                            <AuditView
                                {...props}
                                data={data}
                                auxiliaryData={auxiliaryData}
                                insect={insect}
                            />
                        </>}
                    </Col>
                </Row>
            </Container>
        </Layout>;
    }
}

const InsectAudit = connect(state => state.insect.audit, actions)(Audit);

export default InsectAudit;
