import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';

import IndexLayout from '../../../IndexLayout';
import {Button, Form, Input, message, Modal, Table} from 'antd';
import {PageHeader} from '@ant-design/pro-components';
import {MinusCircleOutlined, PlusCircleOutlined} from '@ant-design/icons';
import {useRequest} from 'ahooks-v2';
import request from '../../../../util/request';

const EditModal = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false);
    const [modalProps, setModalProps] = useState({});
    const [form] = Form.useForm();
    useImperativeHandle(ref, () => ({
        open: (data, onOk) => {
            form.setFieldsValue(data);
            setModalProps({
                onOk: () => {
                    console.log(form.getFieldsValue());
                    onOk && onOk(form.getFieldsValue());
                    setVisible(false);
                }
            });
            setVisible(true);
        }
    }));
    return <Modal forceRender open={visible} {...modalProps} onCancel={() => {
        setVisible(false);
        setModalProps({});
    }}>
        <Form form={form}>
            <Form.Item label={'名称'} name={'name'}><Input/></Form.Item>
            <Form.Item label={'跳转链接'} name={'url'} extra="如果是站内跳转,只需要填写.cn后路径即可,如/article/1"><Input/></Form.Item>
        </Form>
    </Modal>
});

const ManageArticleReference = props => {
    const [data, setData] = useState([{
        key: 0,
        name: '物种地域',
        url: '',
        child: [{name: '半岛物种', url: ''}, {name: '半岛物种', url: ''}, {name: '半岛物种', url: ''}, {name: '半岛物种', url: ''}]
    }]);
    const editModalRef = useRef();
    useRequest(() => ({url: '/article/reference'}), {
        requestMethod: (param) => request(param),
        formatResult: ({data}) => data,
        onSuccess: data => {
            setData(data);
        }
    });
    const save = useRequest(() => ({url: '/users/article/reference', method: 'PUT', data: {data}}), {
        requestMethod: (param) => request(param),
        formatResult: ({data}) => data,
        manual: true,
        onSuccess: data => {
            if (data.message === 'success') {
                message.success('保存成功,刷新页面生效');
                return;
            }
            message.error(JSON.stringify(data));
        }
    });
    return <IndexLayout>
        <PageHeader title={'引用管理'} extra={<Button type={'primary'} onClick={save.run}>保存</Button>}/>
        <Table dataSource={data}>
            <Table.Column title="名称" dataIndex="name"/>
            <Table.Column title="子菜单" dataIndex="child" render={(child, record, index) => {
                if (child) {
                    return <>
                        {child.map((item, childIndex) =>
                            <span key={childIndex}>
                                <PlusCircleOutlined onClick={() => editModalRef.current.open({}, newData => {
                                    data[index].child.splice(childIndex, 0, newData);
                                    setData([...data]);
                                })}/>
                                &nbsp;
                                <span onClick={() => editModalRef.current.open(item, newData => {
                                    data[index].child[childIndex] = newData;
                                    setData([...data]);
                                })}>{item.name}</span>
                                <MinusCircleOutlined onClick={() => {
                                    data[index].child.splice(childIndex, 1);
                                    setData([...data]);
                                }}/>
                                &nbsp;
                            </span>
                        )}
                        <PlusCircleOutlined onClick={() => editModalRef.current.open({}, newData => {
                            data[index].child.push(newData);
                            setData([...data]);
                        })}/>
                    </>;
                }
                return null;
            }}/>
            <Table.Column title="操作" dataIndex="operate" render={(text, record, index) => {
                return <Button.Group>
                    <Button type={'link'} onClick={() => editModalRef.current.open(record, newData => {
                        data[index] = {...data[index], ...newData};
                        setData([...data]);
                    })}>编辑</Button>
                </Button.Group>;
            }}/>
        </Table>
        <EditModal ref={editModalRef}/>
    </IndexLayout>
};

export default ManageArticleReference;
