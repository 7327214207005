import React from 'react';
import {connect} from 'react-redux';
import {PlusOutlined} from '@ant-design/icons';
import {Button, Divider, Form, Input, InputNumber, Modal, Radio, Switch, Table, Upload} from 'antd';
import {PageHeader} from '@ant-design/pro-components';
import IndexLayout from '../../IndexLayout';
import * as actions from './actions';
import {props as pickArray} from 'ramda';
import {optionFields} from '../../insect/analyse/actions';
import FilterCascader from '../../../component/FilterCascader';
import FormInsectSelector from '../../../component/FormInsectSelector';

const normFile = (e) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList;
};

const HostFilter = props => {
    const handleSwitch = value => {
        if (props.onChange) {
            if (value) {
                props.onChange([]);
            } else {
                props.onChange(false);
            }
        }
    }
    const checked = props.value !== false
    return <div>
        <Switch checked={checked} onChange={handleSwitch}/>
        {checked && <FilterCascader type={'host'} {...props}/>}
    </div>
}

const dropdownRender = (menus) => (
    <div>
        <div style={{padding: 8}}>
            <Form.Item noStyle name={['filter', 'taxonomyMulti']} valuePropName="checked"
                       getValueFromEvent={e => e ? 1 : 0}>
                <Switch checkedChildren="多选" unCheckedChildren="级联"/>
            </Form.Item>
        </div>
        <Divider style={{margin: 0}}/>
        {menus}
    </div>
);

class Special extends React.Component {
    componentDidMount() {
        this.props.getList();
    }

    render() {
        const {props} = this;
        return <IndexLayout>
            <PageHeader title="专题管理" extra={
                <Button type="primary" onClick={props.newData}>
                    新建专题
                </Button>
            }/>
            <Table dataSource={props.listData} rowKey={'id'}>
                <Table.Column title="专题名称" dataIndex="name" key="name"/>
                <Table.Column title="城市" dataIndex={['filter', 'region']} key="city" render={text => {
                    if (text) {
                        return pickArray(optionFields.address, text).filter(item => item).join('/');
                    }
                    return '-';
                }}/>
                <Table.Column title="专题图片" dataIndex="images" key="images"/>
                <Table.Column
                    title="Action"
                    key="action"
                    render={(text, record) => (
                        <span>
                            <Button type="link" onClick={() => props.editRow(record)}>编辑</Button>
                            <Divider type="vertical"/>
                            <Button type="link" onClick={() => props.toDetail(record)}>查看详情</Button>
                            <Divider type="vertical"/>
                            <Button type="link" onClick={() => props.deleteRow(record)}>删除</Button>
                        </span>
                    )}
                />
            </Table>
            <Modal open={props.showEdit} onCancel={props.hiddenEdit} onOk={() => {
                return this.form.validateFields().then(data => props.save(data))
            }} destroyOnClose>
                <Form
                    ref={form => this.form = form} preserve={false} initialValues={props.edit}
                    onValuesChange={changedValues => {
                        if (changedValues.showMode === 1) {
                            this.taxonomyFilter = null;
                            this.form.setFieldsValue({filter: {taxonomy: []}});
                        }
                        if (changedValues.filter && typeof changedValues.filter.taxonomyMulti !== 'undefined') {
                            this.form.setFieldsValue({filter: {taxonomy: []}});
                            this.taxonomyFilter.clearCache();
                        }
                    }}
                >
                    <Form.Item label={'专题名称'} name={'name'} rules={[{required: true}]}>
                        <Input placeholder={'请输入专题名称'}/>
                    </Form.Item>
                    <Form.Item label={'展示方式'} name={'showMode'}>
                        <Radio.Group>
                            <Radio value={0}>直接展示物种</Radio>
                            <Radio value={1}>展示纲</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item noStyle dependencies={['showMode', ['filter', 'taxonomyMulti']]}>
                        {({getFieldValue}) => {
                            if (getFieldValue('showMode') !== 1) {
                                return <>
                                    <Form.Item label={'分类学过滤'} name={['filter', 'taxonomy']}>
                                        <FilterCascader ref={filter => this.taxonomyFilter = filter}
                                                        dropdownRender={dropdownRender}
                                                        multiple={getFieldValue(['filter', 'taxonomyMulti']) === 1}
                                                        type={'taxonomy'}/>
                                    </Form.Item>
                                    <Form.Item hidden name={['filter', 'taxonomyMulti']}><InputNumber/></Form.Item>
                                </>;
                            }
                        }}
                    </Form.Item>
                    <Form.Item label={'物种名称'} name={['filter', 'insectId']}>
                        <FormInsectSelector mode="multiple"/>
                    </Form.Item>
                    <Form.Item label={'地域'} name={['filter', 'region']}>
                        <FilterCascader type={'address'}/>
                    </Form.Item>
                    <Form.Item label={'地点'} name={['filter', 'address']}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={'寄主'} name={['filter', 'host']}>
                        <HostFilter/>
                    </Form.Item>
                    <Form.Item label={'tag'} name={['filter', 'tag']}>
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={'专题图片'} name={'images'} valuePropName="fileList"
                        getValueFromEvent={normFile}
                        rules={[{required: true}]}
                    >
                        <Upload
                            action={'/api/users/insect/img'}
                            listType={'picture-card'}
                            showUploadList={{showPreviewIcon: false}}
                        >
                            <div>
                                <PlusOutlined/>
                                <div className="ant-upload-text">专题图片上传</div>
                            </div>
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
        </IndexLayout>;
    }
}

const ManageInsectSpecial = connect(state => state.manage.insect.special, actions)(Special);

export default ManageInsectSpecial;
