import React from 'react';
import IndexLayout from '../../../IndexLayout';
import {CheckOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Tag} from 'antd';
import {PageHeader} from '@ant-design/pro-components';
import {connect} from 'react-redux';

import * as actions from './actions';
import InsectSelector from '../../../../component/InsectSelector';

class Detail extends React.Component {
    componentDidMount() {
        const {match: {params}, data: {id}} = this.props;
        +params.id !== id && this.props.loadData(params);
        this.props.loadDetail(params);
    }

    render() {
        const {props} = this;
        return <IndexLayout>
            <PageHeader title={`${props.data.name}专题详情`}/>
            <div>
                {props.listData.map(({insectId, specificNameChinese, latinName}, index) =>
                    <Tag closable onClose={(e) => props.removeInsect(e, index)}
                         key={insectId}>{specificNameChinese}({latinName})</Tag>)
                }
            </div>
            {props.showAppend && <InsectSelector
                insectId={props.appendData.id}
                onSelect={props.selectedData}
            />}
            <Button
                type={'primary'}
                icon={props.showAppend ? <CheckOutlined/> : <PlusOutlined/>}
                onClick={props.appendItem}
            />
        </IndexLayout>;
    }
}

const ManageInsectSpecialDetail = connect(state => state.manage.insect.special.detail, actions)(Detail);

export default ManageInsectSpecialDetail;
