import React from 'react';
import {connect} from 'react-redux';
import {Button, Form, Overlay, Popover} from 'react-bootstrap';
import * as actions from './actions';
import ListGroup from 'react-bootstrap/ListGroup';

const Item = ({value, label, ...props}) => <ListGroup.Item data-value={value} {...props}>
    {label || 'noname'}
</ListGroup.Item>;

class Selector extends React.Component {
    constructor(props) {
        super(props);
        this.onSelected = (e) => {
            const {handleSelect, onSelect} = this.props;
            const selected = handleSelect(e);
            onSelect && selected && onSelect(selected);
        };
    }

    componentDidMount() {
        const {insectId, selected, updateSelected} = this.props;
        if (insectId !== selected.id) {
            updateSelected(insectId);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {insectId, selected, updateSelected} = this.props;
        if (insectId !== selected.id) {
            updateSelected(insectId);
        }
    }

    componentWillUnmount() {
        this.props.reset();
    }

    render() {
        const {insectId, selected, list, query, queryMessage, showNoData, noDataTip} = this.props;
        return <div className="d-inline-block">
            <Button variant="link" onClick={this.props.toggle}>
                {insectId && selected.id === insectId ?
                    `${selected.specificNameChinese}(${selected.latinName})` :
                    '点击选择物种'
                }
            </Button>
            <Overlay
                show={this.props.show}
                target={this.props.target}
                placement="bottom"
                container={this.current}
                style={{minWidth: 300}}
            >
                <Popover id={'insect-selector-content'}>
                    <Popover.Content>
                        <Form.Control type={'text'} placeholder="请搜索" onChange={this.props.queryChange}/>
                        {query && queryMessage && <div className="text-muted">{queryMessage}</div>}
                        <ListGroup onClick={this.onSelected} style={{margin: '10px auto'}}>
                            {insectId && selected.id &&
                            <Item value={selected.id} label={selected.specificNameChinese} active/>}
                            {list.map((item, index) => item.id !== selected.id &&
                                <Item key={index} value={item.id}
                                      label={`${item.specificNameChinese}(${item.latinName})`}
                                      data-index={index}
                                />)}
                            {showNoData && noDataTip && <ListGroup.Item>{noDataTip}</ListGroup.Item>}
                        </ListGroup>
                    </Popover.Content>
                </Popover>
            </Overlay>
        </div>;
    }
}

const InsectSelector = connect(state => state.frameworks.insectSelector, actions)(Selector);

export default InsectSelector;
