import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import config from '../../../index/config';
import IndexLayout from '../../../IndexLayout';

const InsectSpecialTaxonomy = props => {
    return <IndexLayout>
        <Container>
            <Row className={'row-cols-4 justify-content-around my-2'}>
                {config.map((item, index) => {
                    if (item && index !== 12) {
                        return <Col
                            key={index}
                            className={'d-flex justify-content-center align-items-center taxonomy-name-wrapper'}
                        >
                            <div className={'taxonomy-name'}>{item.name}</div>
                            <img
                                src={item.img} alt={''} className={'w-100'}
                                onClick={() => {
                                    props.history.push('..', {taxonomy: {classChinese: item.name}});
                                }}
                            />
                        </Col>
                    }
                    return null;
                })}
            </Row>
        </Container>
    </IndexLayout>
}

export default InsectSpecialTaxonomy;
