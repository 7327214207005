import {pick} from 'ramda';
import {message, Modal} from 'antd';
import {push} from 'connected-react-router';
import {showAdd} from '../../../insect/edit/actions';

export const update = payload => ({type: 'manage.insect.index', payload});
export const updateQuery = payload => ({type: 'manage.insect.index.query', payload});

export const optionFields = {
    taxonomy: ['classChinese', 'orderChinese', 'familyChinese', 'genusChinese', 'specificNameChinese'],
    address: ['province', 'city', 'district'],
    host: ['host']
};

export const loadData = () => (dispatch, getState, {request}) => {
    const {query: params} = getState().manage.insect.index;
    request('/insect/list', {params})
        .then(({data}) => {
            dispatch(update(data));
        });
};

export const pageChange = (pagination) => (dispatch, getState) => {
    if (pagination && pagination.current) {
        const {query} = getState().manage.insect.index;
        if (query.limit !== pagination.pageSize) {
            dispatch(update({rows: []}));
        }
        dispatch(updateQuery({offset: (pagination.current - 1) * pagination.pageSize, limit: pagination.pageSize}));
        dispatch(loadData());
    }
};

export const filterChange = (type, value, selectedOptions) => (dispatch, getState) => {
    const {query} = getState().manage.insect.index;
    const optionField = optionFields[type];
    let params = pick(['order', 'offset', 'limit'], query);
    selectedOptions && selectedOptions.forEach((item, index) => {
        params[optionField[index]] = item.valueData;
    });
    dispatch(update({query: params}));
    dispatch(loadData());
};

export const editRow = (data) => dispatch => dispatch(showAdd({...data, callback: () => dispatch(loadData())}));

export const deleteRow = (data) => (dispatch, getState, {request}) => {
    Modal.confirm({
        title: `确认删除${data.specificNameChinese}物种吗`,
        content: '物种删除后无法恢复, 且该物种的标本信息也会一并删除',
        onOk: () => {
            return request.delete(`/users/insect/${data.id}`)
                .then(({data}) => {
                    if (data) {
                        if (data.message === 'success') {
                            message.success('删除成功');
                            dispatch(loadData());
                            return;
                        }
                    }
                    message.error(JSON.stringify(data));
                });
        }
    });
};

export const toDetail = insectData => dispatch => {
    dispatch({type: 'state.manage.insect.collect', payload: {insectData}});
    dispatch(push(`/manage/insect/${insectData.id}/collect`));
};

export const batchDelete = () => (dispatch, getState, {request}) => {
    const state = getState().manage.insect.index;
    const {batchOption, checkedRows} = state;
    if (batchOption) {
        if (checkedRows.length === 0) {
            message.info('请选择要删除的行');
            return;
        }
        Modal.confirm({
            title: `确认删除${checkedRows.length}个物种吗`,
            content: '物种删除后无法恢复, 且该物种的标本信息也会一并删除',
            onOk: () => {
                return Promise.all(checkedRows.map(id => {
                    return request.delete(`/users/insect/${id}`)
                        .then(({data}) => {
                            if (data) {
                                return data.message;
                            }
                            return data;
                        });
                }))
                    .then(res => {
                        dispatch(loadData());
                        dispatch(update({batchOption: false, checkedRows: []}));
                        const success = res.filter(item => item === 'success');
                        if (success.length === checkedRows.length) {
                            message.info('操作成功');
                            return;
                        }
                        message.error(`${checkedRows.length - success.length}条数据删除失败`, 10);
                    })
            }
        });
        return;
    }
    dispatch(update({batchOption: true}));
};

export const onSelectChange = checkedRows => update({checkedRows});

export const doSearch = search => dispatch => {
    dispatch(updateQuery({search}));
    dispatch(loadData());
};
