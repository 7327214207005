import React from 'react';
import IndexLayout from '../../../IndexLayout';
import {Button, Divider, Form, Input, Table} from 'antd';
import {PageHeader} from '@ant-design/pro-components';
import {insectDisplayField} from '../../../util';
import {connect} from 'react-redux';
import * as actions from './actions';
import FilterCascader from '../../../../component/FilterCascader';

const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 14}
};

class Insect extends React.Component {
    componentDidMount() {
        this.props.loadData();
    }

    render() {
        const {props} = this;
        const {query} = props;
        const rowSelection = {
            selectedRowKeys: props.checkedRows,
            onChange: props.onSelectChange,
        };
        return <IndexLayout>
            <PageHeader title={'数据管理'}/>
            <Form {...formItemLayout}>
                <Form.Item label={'物种名称过滤'}>
                    <Input.Search
                        className={'insect-search'}
                        placeholder={'输入物种中文名或拉丁学名'}
                        onSearch={props.doSearch}
                        enterButton
                        defaultValue={props.query.search}
                    />
                </Form.Item>
                <Form.Item label={'分类学过滤'}>
                    <FilterCascader
                        type={'taxonomy'}
                        onChange={(value, selectedOptions) => props.filterChange('taxonomy', value, selectedOptions)}
                    />
                </Form.Item>
            </Form>
            <div className={'px-3 pb-2'}>
                <Button type={'danger'} onClick={props.batchDelete}>批量删除</Button>
            </div>
            <Table
                dataSource={props.rows}
                rowKey={'id'}
                onChange={props.pageChange}
                pagination={{
                    showTotal: (total, range) => `展示第${range[0]}-${range[1]}条, 共${total}条`,
                    current: query.offset / query.limit + 1,
                    total: props.total,
                    pageSize: query.limit
                }}
                expandedRowRender={record => insectDisplayField.map(({key, label, detailOnly}) =>
                    detailOnly && <p key={key}>{label}:{record[key]}</p>
                )}
                rowSelection={props.batchOption ? rowSelection : null}
            >
                {insectDisplayField.map(({key, label, detailOnly}) =>
                    !detailOnly && <Table.Column title={label} dataIndex={key} key={key}/>
                )}
                <Table.Column
                    title="Action"
                    key="action"
                    render={(text, record) => (
                        <span>
                        <Button type="link" onClick={() => props.editRow(record)}>编辑</Button>
                        <Divider type="vertical"/>
                        <Button type="link" onClick={() => props.toDetail(record)}>查看标本</Button>
                        <Divider type="vertical"/>
                        <Button type="link" onClick={() => props.deleteRow(record)}>删除</Button>
                        </span>
                    )}
                />
            </Table>
        </IndexLayout>;
    }
}

const ManageInsect = connect(state => state.manage.insect.index, actions)(Insect);

export default ManageInsect;
