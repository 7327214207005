import {push} from 'connected-react-router';
import {optionFields} from '../../analyse/actions';
import {equals} from 'ramda';

const update = payload => ({type: 'state.insect.special.detail', payload});
const updateQuery = payload => ({type: 'state.insect.special.detail.itemQuery', payload});
const updateFilter = payload => ({type: 'state.insect.special.detail.filter', payload});
const updateFilterOptions = payload => ({type: 'state.insect.special.detail.filterOptions', payload});

export const loadSpecialData = ({id}) => (dispatch, getState, {request}) => {
    return request.get('/insect/special', {params: {id}})
        .then(({data}) => {
            const [special] = data;
            dispatch(update({data: special || {}}));
            return special;
        });
};

export const loadMore = page => (dispatch, getState) => {
    const state = getState().insect.special.detail;
    if (state.itemLoading) return;
    dispatch(updateQuery({offset: state.itemQuery.offset + state.itemQuery.limit}));
    return dispatch(loadSpecialDetail());
};

export const resetItem = () => update({
    itemData: {rows: [], more: false},
    itemQuery: {offset: 0, limit: 10}
});

export const loadSpecialDetail = () => (dispatch, getState, {request}) => {
    const {data, itemData, itemQuery, itemRouterFilter} = getState().insect.special.detail;
    dispatch(update({itemLoading: true}));
    const params = {...data.filter, ...itemRouterFilter, ...itemQuery}
    Object.keys(params).forEach(key => {
        if (!params[key]) {
            delete params[key];
        }
    })
    request.get(`/insect/special/${data.id}/item/detail`, {params})
        .then(({data}) => {
            data.rows.forEach(item => {
                item.images = item.images ? item.images.split(',') : [];
            });
            dispatch(update({itemData: {rows: itemData.rows.concat(data.rows), more: data.more}}));
        })
        .finally(() => {
            dispatch(update({itemLoading: false}));
        })
};

export const loadAnalyseData = ({id}) => (dispatch, getState, {request}) => {
    const {data} = getState().insect.special.detail;
    const params = {}
    Object.keys(data.filter).forEach(key => {
        if (data.filter[key]) {
            params[key] = data.filter[key];
        }
    })
    request.get(`/insect/special/${id}/analyse`, {params})
        .then(({data}) => {
            const phylum = {};
            const classObj = {}
            const order = {};
            const family = {};
            let collect = 0;
            data.forEach(({phylumChinese, classChinese, orderChinese, familyChinese, sum}) => {
                phylum[phylumChinese] = 1;
                classObj[`${phylumChinese}.${classChinese}`] = 1;
                order[`${phylumChinese}.${classChinese}.${orderChinese}`] = 1;
                family[`${phylumChinese}.${classChinese}.${orderChinese}.${familyChinese}`] = 1;
                collect += sum;
            });
            const sumData = {
                phylum: Object.keys(phylum).length,
                class: Object.keys(classObj).length,
                order: Object.keys(order).length,
                family: Object.keys(family).length,
                specificName: data.length,
                collect
            };
            dispatch(update({insectAnalyse: {rows: data, sumData}}));
        })
};

export const toInsectDetail = (id, specialId) => push(`insect/detail/${id}`);

export const loadOptionsData = (type, selectedOptions) => (dispatch, getState, {request}) => {
    const optionField = optionFields[type];
    const depth = selectedOptions.length;
    const isLeaf = depth >= optionField.length - 2;
    const params = {type, data: {data: 1}};
    let targetOption;
    if (depth) {
        targetOption = selectedOptions[depth - 1];
        targetOption.loading = true;
    }
    selectedOptions.forEach((item, index) => {
        params[optionField[index]] = item.valueData;
    });
    request('/insect/analyse/filter', {params})
        .then(({data}) => {
            const options = data.map((item, index) => {
                const valueData = item[optionField[depth]];
                return ({
                    valueData,
                    value: [type, depth, valueData || index].join('-'),
                    label: valueData || '-',
                    isLeaf
                });
            });
            if (targetOption) {
                targetOption.loading = false;
                targetOption.children = options;
                dispatch(updateFilterOptions({}));
            } else {
                dispatch(updateFilterOptions({[type]: options}));
            }
        });
};

export const cascaderChange = (field, status) => (dispatch, getState) => {
    const state = getState().insect.special.detail.filterOptions;
    if (status && state[field].length === 0) {
        dispatch(loadOptionsData(field, []));
    }
};

export const filterChange = (type, value, selectedOptions) => (dispatch, getState) => {
    const optionField = optionFields[type];
    let params;
    if (selectedOptions.length) {
        params = {};
        selectedOptions.forEach((item, index) => {
            params[optionField[index]] = item.valueData;
        });
    }
    dispatch(updateFilter({[type]: params}));
    const {data: {id}, filter} = getState().insect.special.detail;
    dispatch(loadSpecialDetail({id, ...filter}));
};

export const onFilterChange = (changedValues, allValues) => (dispatch, getState) => {
    const {data} = getState().insect.special.detail;
    const filter = {};
    Object.keys(allValues).forEach(type => {
        const optionField = optionFields[type];
        let params;
        if (allValues[type] && allValues[type].length) {
            const startDepth = data.filter[type] ? Object.keys(data.filter[type]).length : 0;
            params = data.filter[type] ? {...data.filter[type]} : {};
            allValues[type].forEach((item, index) => {
                params[optionField[index + startDepth]] = item;
            });
            filter[type] = params;
        }
    })
    dispatch(resetItem());
    dispatch(updateQuery(filter));
    dispatch(loadSpecialDetail());
}

export const setRouterFilter = data => (dispatch, getState) => {
    const {itemRouterFilter} = getState().insect.special.detail;
    if (!equals(data, itemRouterFilter)) {
        dispatch(resetItem());
        dispatch(update({itemRouterFilter: data}));
    }
};
