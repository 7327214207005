import React from 'react';
import IndexLayout from '../../../IndexLayout';
import {connect} from 'react-redux';
import {Checkbox, Typography} from 'antd';

import * as actions from './actions';
import UserReportInfo from '../../../../component/UserReportInfo';

class UserDetail extends React.Component {
    componentDidMount() {
        const {match: {params}, info: {id}} = this.props;
        +params.id !== id && this.props.loadUserData(params);
        this.props.loadAnalyseData(params);
        this.props.loadPermissionData();
        this.props.loadUserPermissionData(params);
    }

    render() {
        const {props} = this;
        const {match: {params}, info, insectAnalyse} = props;
        return <IndexLayout>
            {+params.id === info.id && <>
                <Typography.Title level={3}>用户管理-{info.name}</Typography.Title>
                <Typography.Title level={4}>
                    已入库数据{insectAnalyse.auditedRows && insectAnalyse.auditedRows.length !== 0 && insectAnalyse.sumData && `:${insectAnalyse.sumData.order}目,${insectAnalyse.sumData.family}科,${insectAnalyse.sumData.specificName}种,${insectAnalyse.sumData.collectSum}条记录`}
                </Typography.Title>
                <UserReportInfo data={insectAnalyse.auditedRows} taxonomyData={insectAnalyse.taxonomyData}/>
                {!!insectAnalyse.waitAuditSum &&
                <Typography.Title level={4}>待鉴定数据{insectAnalyse.waitAuditSum}条</Typography.Title>
                }
                {props.grant && <>
                    <Typography.Title level={4}>用户权限</Typography.Title>
                    {props.grant.map((item, index) => {
                        return <Checkbox
                            key={index} name={item.code} checked={props.permission[item.code]}
                            onChange={props.changePermission}
                        >
                            {item.name}
                        </Checkbox>;
                    })}
                </>}
            </>}
        </IndexLayout>
    }
}

const ManageUserDetail = connect(state => state.manage.user.detail, actions)(UserDetail);

export default ManageUserDetail;
