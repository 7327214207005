export const showMessage = ({message, timeout = 3000, type = 'primary'}) => (dispatch, getState) => {
    let {messageList} = getState().frameworks;
    const newBar = {key: Date.now(), message, type, show: false};
    if (timeout) {
        setTimeout(() => dispatch(hideMessage(newBar.key)), timeout);
    }
    messageList = messageList.concat(newBar);
    dispatch({
        type: 'state.frameworks',
        payload: {
            messageList
        }
    });
    process.nextTick(() => {
        newBar.show = true;
        dispatch({
            type: 'state.frameworks',
            payload: {
                messageList: [...messageList]
            }
        });
    });
};

export const hideMessage = (key, reason) => (dispatch, getState) => {
    if (reason === 'clickaway') {
        return;
    }
    const {messageList} = getState().frameworks;
    let index = -1;
    for (let i = 0; i < messageList.length; i++) {
        if (messageList[i].key === key) {
            index = i;
            messageList[i].show = false;
            break;
        }
    }
    if (index !== -1) {
        dispatch({
            type: 'state.frameworks',
            payload: {
                messageList: [...messageList]
            }
        });
        setTimeout(() => dispatch(deleteMessage(key)), 300);
    }
};

export const deleteMessage = key => (dispatch, getState) => {
    const {messageList} = getState().frameworks;
    let index = -1;
    for (let i = 0; i < messageList.length; i++) {
        if (messageList[i].key === key) {
            index = i;
            break;
        }
    }
    if (index !== -1) {
        messageList.splice(index, 1);
        dispatch({
            type: 'state.frameworks',
            payload: {messageList}
        });
    }
};
