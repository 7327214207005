const edit = {
    name: '',
    city: {},
    showMode: 0,
    images: []
};

const initialData = {
    showEdit: false,
    edit,
    listData: [],
    fileList: [],
    detail: {
        data: {id: 0, name: ''},
        listData: [],
        showAppend: false,
        appendData: {}
    },
    addressValue: [],
    filter: {},
    filterOptions: {
        address: [],
    }
};

const special = (state = initialData, {type, payload}) => {
    if (type === 'state.manage.insect.special') {
        return {...state, ...payload};
    }
    if (type === 'state.manage.insect.special.edit') {
        return {...state, edit: {...state.edit, ...payload}};
    }
    if (type === 'state.manage.insect.special.detail') {
        return {...state, detail: {...state.detail, ...payload}};
    }
    if (type === 'state.manage.insect.special.new') {
        return {...state, showEdit: true, edit};
    }
    if (type === 'state.manage.insect.special.filter') {
        return {...state, filter: {...state.filter, ...payload}};
    }
    if (type === 'state.manage.insect.special.filterOptions') {
        return {...state, filterOptions: {...state.filterOptions, ...payload}};
    }
    return state;
};

export default special;
