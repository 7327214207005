import React from 'react';
import {Typography} from 'antd';

const TaxonomyTitle = props => {
    if (props.subType && props.type && props.parentData.chineseName) {
        const taxonomyPath = props.parentPath.slice(0, props.depth).filter(item => item);
        console.log(taxonomyPath);
        return <Typography.Title level={3}>
            分类系统{taxonomyPath.map((item, index) =>
            <span key={item.id}
                  onClick={() => (taxonomyPath.length - 1 - index) && props.history.go(index - taxonomyPath.length + 1)}>
                    -{item.chineseName}
                </span>)}
        </Typography.Title>
    }
    return <Typography.Title level={3}>分类系统</Typography.Title>
};

export default TaxonomyTitle;
