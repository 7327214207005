import {push} from "connected-react-router";

const update = payload => ({type: 'state.insect.taxonomy', payload});
const updateQuery = payload => ({type: 'state.insect.taxonomy.query', payload});

export const getFamilyInfo = ({familyId}) => (dispatch, getState, {request}) => {
    return request.get(`/insect/taxonomy/family/${familyId}`)
        .then(({data}) => {
            if (data.message === 'success') {
                dispatch(update({parentData: data.data}))
            }
        })
};

export const getList = () => (dispatch, getState, {request}) => {
    dispatch(update({loading: true}));
    let {currentOrder: {chineseName: orderName}, parentData: {chineseName: familyName}, query, insectData} = getState().insect.taxonomy;
    request.get('/insect', {params: {orderChinese: orderName, familyChinese: familyName, ...query}})
        .then(({data}) => {
            data.rows.forEach(item => {
                item.images = item.images ? item.images.split(',') : [];
            });
            data.rows = insectData.rows.concat(data.rows);
            dispatch(update({insectData: data, loading: false}));
        })
};

export const loadMore = () => (dispatch, getState) => {
    const state = getState().insect.taxonomy;
    if (state.loading) return;
    dispatch(updateQuery({offset: state.query.offset + state.query.limit}));
    return dispatch(getList());
};

export const toInsectDetail = ({id}) => push(`/insect/${id}`);
export const resetPage = () => update({
    query: {offset: 0, limit: 10, order: 'asc', sort: 'latinName'},
    insectData: {rows: [], total: 0}
});
