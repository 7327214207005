import React, {useEffect, useState} from 'react';
import {Select} from 'antd';
import {useRequest} from 'ahooks-v2';
import request from '../util/request';

const HostSelector = props => {
    const {run, data, mutate, loading} = useRequest(search => {
        if (search) {
            return {url: '/insect/host', params: {search}}
        }
        return Promise.resolve({});
    }, {
        requestMethod: (param) => request(param),
        formatResult: ({data}) => {
            return data && data.rows
        },
        debounceInterval: 500
    });
    const [host, setHost] = useState({});
    useEffect(() => {
        setHost(props.data || {});
        if (props.data && props.data.id) {
            mutate([props.data]);
        } else {
            setHost({});
        }
    }, [props.data]);
    const selectHost = (value, options) => {
        setHost(options.item);
        props.onChange && props.onChange(options.item);
    }
    return <Select
        {...props}
        onSearch={run}
        showSearch
        filterOption={false}
        placeholder="请输入寄主植物名称搜索"
        onChange={selectHost}
        value={host.id}
        loading={loading}
    >
        {data && data.map(item =>
            <Select.Option value={item.id} key={item.id} item={item}>{item.nameChinese}</Select.Option>
        )}
    </Select>

}

export default HostSelector;
