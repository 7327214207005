import React from 'react'

class AmapLayerSwitcher extends React.Component {
    constructor(props) {
        super(props);
        this.AMapUI = window.AMapUI;
    }

    componentDidMount() {
        const {AMapUI, props} = this;
        AMapUI.loadUI(['control/BasicControl'], function (BasicControl) {
            props.__map__.addControl(new BasicControl.LayerSwitcher());
        });
    }

    render() {
        return null;
    }
}

export default AmapLayerSwitcher;
