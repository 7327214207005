import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import frameworks from './reducers/frameworks';
import main from './reducers/main';
import passport from './reducers/passport';
import insect from './reducers/insect';
import manage from './reducers/manage';
import user from './reducers/user';

export default (history) => combineReducers({
    frameworks,
    main,
    passport,
    insect,
    manage,
    user,
    router: connectRouter(history),
})
