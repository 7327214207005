import React from 'react';
import {Card, Form, Pagination} from 'antd';
import {Col, Row} from 'react-bootstrap';
import Carousel from 'react-slick';
import FilterCascader, {optionFields} from '../FilterCascader';

class UserAcceptedList extends React.Component {
    state = {
        filters: {}
    }

    pageChange = (page) => {
        const {props, state} = this;
        this.props.pageChange({offset: (page - 1) * props.param.limit, limit: props.param.limit, ...state.filters});
    };

    formChange = (changedFields, allFields) => {
        const taxonomy = allFields[0].value;
        const optionField = optionFields.taxonomy;
        let params;
        if (taxonomy && taxonomy.length) {
            params = {};
            taxonomy.forEach((item, index) => {
                params[optionField[index]] = item;
            });
            this.setState({filters: params});
        } else {
            this.setState({filters: {}});
        }
        this.pageChange(1);
    };

    render() {
        const {props} = this;
        return <>
            <Form onFieldsChange={this.formChange}>
                <Form.Item label={'分类学过滤'} name={'taxonomy'}>
                    <FilterCascader type={'taxonomy'}/>
                </Form.Item>
            </Form>
            <Row className={'row-cols-1 row-cols-md-3 row-cols-xl-5'}>
                {props.data.rows && props.data.rows.map(item => {
                    const {images, collectTime, specificNameChinese, id} = item
                    return <Col key={id} className={'py-3'}><Card
                        cover={
                            <Carousel arrows className={'inner-arrow'}>
                                {images && images.map((name, index) => (
                                    <div className={'img-4-3-wrapper'} key={index}>
                                        <img
                                            alt={name}
                                            src={`/uploadImg/tiny/${name}`}
                                        />
                                    </div>
                                ))}
                            </Carousel>
                        }
                    >
                        <p className={'h5'}>{specificNameChinese || '-'}</p>
                        <p className={'text-right'}>{collectTime}</p>
                    </Card></Col>;
                })}
            </Row>
            <Pagination
                showTotal={(total, range) => `展示第${range[0]}-${range[1]}条, 共${total}条`}
                total={props.data.total}
                pageSize={props.param.limit}
                current={(props.param.offset / props.param.limit) + 1}
                onChange={this.pageChange}
            />
        </>
    }
}

export default UserAcceptedList;
