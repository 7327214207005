import React from 'react';
import Layout from '../../IndexLayout';
import {Col, Container, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {Map, Markers} from 'react-amap';
import {loadInsect} from './actions';
import dateUtil from '@/util/date';
import {Descriptions, Modal, Typography} from 'antd';
import Carousel from 'react-slick';
import Literature from '../../../component/Literature';
import AmapLayerSwitcher from '../../../component/AmapLayerSwitcher';
import ZoomInOutlined from '@ant-design/icons/lib/icons/ZoomInOutlined';

class CollectCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            carouselData: [],
            nav1: null,
            nav2: null
        };
    }

    updateCarouselData() {
        const carouselData = [];
        let {collect} = this.props;
        // if (this.props.selectedCollect !== undefined) {
        //     collect = [collect[this.props.selectedCollect] || {}];
        // }
        collect.forEach(({id, images}) => {
            if (images && images !== '') {
                carouselData.push(...images.split(',').map((src, index) => ({
                    url: `/uploadImg/tiny/${src}`,
                    src,
                    key: `${id}-${index}`,
                    id,
                    index
                })));
            }
        });
        this.setState({carouselData});
    }

    slideChange = (current, next) => {
        this.props.slideChange && this.props.slideChange(this.state.carouselData[current], this.state.carouselData[next]);
    }

    slideTo = index => {
        this.slider1.slickGoTo(index);
    }

    componentDidMount() {
        this.updateCarouselData();
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.collect !== this.props.collect) {
            this.updateCarouselData();
        }
    }

    render() {
        const {state} = this;
        return <>
            <Carousel
                ref={(e) => this.slider1 = e}
                asNavFor={this.state.nav2}
                infinite={false}
                beforeChange={this.slideChange}
                arrows
                adaptiveHeight
                lazyLoad
            >
                {state.carouselData.map(({url, src, key}) => <div key={key} className={'preview-wrapper'}>
                        <img src={url} className="w-100 d-block px-2" alt=""/>
                        <ZoomInOutlined onClick={() => this.props.onPreview(src)}/>
                    </div>
                )}
            </Carousel>
            <div className="my-3">
                <Carousel
                    ref={(e) => this.slider2 = e}
                    asNavFor={this.state.nav1}
                    infinite={false}
                    slidesToShow={5}
                    arrows
                    lazyLoad
                >
                    {state.carouselData.map(({url, key}, index) =>
                        <div className={'img-4-3-wrapper'} key={key} onClick={() => this.slideTo(index)}>
                            <img src={url} className="px-1 cut" alt=""/>
                        </div>
                    )}
                </Carousel>
            </div>
        </>;
    }
}

class Detail extends React.Component {
    constructor(props) {
        super(props);
        this.mapEvents = {
            created: (map) => {
                this.mapInstance = map;
            }
        };
        this.markersEvents = {
            created: (markers) => {
                markers.length && this.mapInstance && this.mapInstance.setFitView();
                this.setState({markers});
                this.updateCurrentMarker(undefined, markers[this.state.currentIndex]);
            },
            // click: (mapOptions, marker) => props.clickMarker(marker)
        };
        this.state = {currentIndex: 0, markers: [], previewUrl: ''};
    }

    componentDidMount() {
        const {match: {params}, filter} = this.props;
        this.props.loadInsect({...params, filter});
    }

    slideChange = (currentData, nextData) => {
        if (!nextData) return;
        const currentId = currentData && currentData.id
        const nextId = nextData && nextData.id
        if (currentId !== nextId) {
            const current = this.props.data.markers.findIndex(({id}) => currentId === id);
            const next = this.props.data.markers.findIndex(({id}) => nextId === id);
            this.setState({currentIndex: next});
            this.updateCurrentMarker(this.state.markers[current], this.state.markers[next]);
        }
    }

    updateCurrentMarker(currentMarker, nextMarker) {
        currentMarker && (currentMarker.getContent().firstElementChild.src = '//webapi.amap.com/theme/v1.3/markers/n/mark_bs.png');
        nextMarker && (nextMarker.getContent().firstElementChild.src = '//webapi.amap.com/theme/v1.3/markers/n/mark_rs.png');
    }

    handlePreview = (src) => {
        this.setState({previewUrl: src && `/uploadImg/origin/${src}`});
    }

    render() {
        const props = this.props;
        let activeMarkerData = null;
        if (props.data.collect) {
            activeMarkerData = props.data.collect[this.state.currentIndex];
        }
        return (
            <Layout>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <Typography.Title
                                level={3}>{props.data.orderChinese} {props.data.order} {props.data.familyChinese} {props.data.family}</Typography.Title>
                            <Typography.Title
                                level={4}>{props.data.specificNameChinese} <span
                                className={'font-italic'}>{props.data.latinName}</span> {props.data.authorYear}
                            </Typography.Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} xl={8}>
                            <div className={'px-3'}>
                                <CollectCarousel
                                    collect={props.collect}
                                    selectedCollect={props.activeIndex}
                                    slideChange={this.slideChange}
                                    onPreview={this.handlePreview}
                                />
                            </div>
                        </Col>
                        <Col xs={12} md={6} xl={4}>
                            <div style={{height: '300px'}}>
                                <Map amapkey={'88369698bd3d994eb5f636508e005f8d'} version={'1.4.15'} events={this.mapEvents} useAMapUI={true}>
                                    <Markers markers={props.data.markers}
                                             events={this.markersEvents}/>
                                    <AmapLayerSwitcher/>
                                </Map>
                            </div>
                            <Typography.Title level={4}>&nbsp;</Typography.Title>
                            {activeMarkerData &&
                            <Descriptions column={2}>
                                <Descriptions.Item
                                    label={'采集人'}>{activeMarkerData.collectUserName}</Descriptions.Item>
                                <Descriptions.Item
                                    label={'采集日期'}>{dateUtil(activeMarkerData.collectTime).format('YYYY/MM/DD')}</Descriptions.Item>
                                <Descriptions.Item
                                    label={'鉴定人'}>{activeMarkerData.authenticateUser}</Descriptions.Item>
                                {props.data.classChinese === '昆虫纲' &&
                                <Descriptions.Item label={'寄主'}>{activeMarkerData.host}</Descriptions.Item>
                                }
                                <Descriptions.Item label={'备注'}>{activeMarkerData.remark}</Descriptions.Item>
                            </Descriptions>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {props.data.classChinese === '昆虫纲' && <Typography.Title level={4}>寄主</Typography.Title>}
                            {props.data.classChinese === '昆虫纲' && props.data.hostList && <Descriptions column={1}>
                                {props.data.hostList.map((item, index) => (
                                    <Descriptions.Item
                                        key={index}
                                        label={<Typography.Text strong>{item.family}</Typography.Text>}
                                    >
                                        {item.nameList.join(', ')}
                                    </Descriptions.Item>
                                ))}
                            </Descriptions>}
                            {props.data.taxonomyRemark &&
                                <Typography.Title level={4}>分类学评述</Typography.Title>}
                            {props.data.taxonomyRemark}
                            {props.data.literature && props.data.literature.rows && props.data.literature.rows.length > 0 &&
                                <Typography.Title level={4}>参考文献</Typography.Title>}
                            {props.data.literature && props.data.literature.rows && props.data.literature.rows.map(item => (
                                <Typography.Paragraph key={item.id}>
                                    <Literature {...item}/>
                                </Typography.Paragraph>)
                            )}
                        </Col>
                    </Row>
                </Container>
                <Modal
                    open={!!this.state.previewUrl}
                    footer={null}
                    title={'预览图片'}
                    style={{minWidth: '85%'}}
                    onCancel={() => this.handlePreview('')}
                >
                    <div className={'img-4-3-wrapper'}>
                        <img alt={''} src={this.state.previewUrl}/>
                    </div>
                </Modal>
            </Layout>
        );
    };
}

const InsectDetail = connect(
    state => {
        const data = state.insect.detail;
        const activeMarkerData = data.activeIndex !== undefined && data.collect[data.activeIndex];
        return ({
            ...data,
            data,
            activeMarkerData
        });
    },
    {loadInsect}
)(Detail);

export default InsectDetail;
