const initialData = {
    listData: [],
    detail: {
        data: {},
        listData: [],
        itemQuery: {
            offset: 0,
            limit: 10
        },
        itemData: {rows: [], more: false},
        itemLoading: true,
        insectAnalyse: {},
        itemRouterFilter: {}
    }
};

const special = (state = initialData, {type, payload}) => {
    if (type === 'state.insect.special') {
        return {...state, ...payload};
    }
    if (type === 'state.insect.special.detail') {
        return {...state, detail: {...state.detail, ...payload}};
    }
    if (type === 'reset.insect.special.detail') {
        return {...state, detail: initialData.detail};
    }
    if (type === 'state.insect.special.detail.itemQuery') {
        return {...state, detail: {...state.detail, itemQuery: {...state.detail.itemQuery, ...payload}}};
    }
    return state;
};

export default special;
