import insect from './insect';
import {combineReducers} from 'redux';
import literature from "./literature";
import user from './user';

const manage = combineReducers({
    insect,
    literature,
    user
});

export default manage;
