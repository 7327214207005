import {message} from 'antd';
import {push} from "connected-react-router";
import {deleteRecord, moveRecord, saveSortResult} from './common-actions';
import {taxonomyField} from '../../../util';

export * from './common-actions';

const update = payload => ({type: 'state.manage.insect.taxonomy', payload});
const updateOrderEdit = payload => ({type: 'state.manage.insect.taxonomy.edit', payload});

export const getOrderList = () => (dispatch, getState, {request}) => {
    request.get('/insect/taxonomy/order')
        .then(({data}) => {
            data.forEach(item => {
                item.images = item.images ? item.images.split(',') : [];
            });
            dispatch(update({orderList: data}));
        })
};

export const getList = (params) => (dispatch, getState, {request}) => {
    const {type, subType, id} = params;
    const url = ['/insect/taxonomy', type || taxonomyField[0].name];
    if (id) {
        url.push(id);
        if (subType) url.push(subType);
    }
    dispatch(update({loading: true}));
    request.get(url.join('/'))
        .then(({data}) => {
            data.forEach(item => {
                item.images = item.images ? item.images.split(',') : [];
            });
            dispatch(update({list: data}));
        })
        .finally(() => dispatch(update({loading: false})));
};

export const onOrderEditImagesChange = images => updateOrderEdit({images});

export const onOrderEditChange = ({target}) => updateOrderEdit({[target.name]: target.value});

export const hiddenOrderEdit = () => update({showOrderEdit: false});

export const newOrder = () => ({type: 'state.manage.insect.taxonomy.newOrder'});

export const saveOrder = () => (dispatch, getState, {request}) => {
    const {id, images, ...data} = getState().manage.insect.taxonomy.edit;
    if (!data.name || !data.chineseName) {
        message.info('请输入名称');
        return;
    }
    if (images.length === 0) {
        message.info('至少上传一张图片');
        return;
    }
    data.images = images.join(',');
    (id ? request.patch(`/users/insect/taxonomy/order/${id}`, data) : request.post('/users/insect/taxonomy/order', data))
        .then(({data}) => {
            if (data.message === 'success') {
                dispatch(getOrderList());
                dispatch(update({showEdit: false}));
                return;
            }
            message.error(JSON.stringify(data));
        });
};

export const saveRecord = (type, params) => (dispatch, getState, {request}) => {
    const {id, images, ...data} = getState().manage.insect.taxonomy.edit;
    if (!data.name || !data.chineseName) {
        message.info('请输入名称');
        return;
    }
    if (images.length === 0) {
        message.info('至少上传一张图片');
        return;
    }
    data.images = images.join(',');
    if (params.id) {
        data.parentId = +params.id;
    }
    (id ? request.patch(`/users/insect/taxonomy/${type}/${id}`, data) : request.post(`/users/insect/taxonomy/${type}`, data))
        .then(({data}) => {
            if (data.message === 'success') {
                dispatch(getList(params));
                dispatch(update({showEdit: false}));
                return;
            }
            message.error(JSON.stringify(data));
        });
};

export const editOrder = record => update({showEdit: true, edit: record});

export const deleteTaxonomy = (record, type, params) => dispatch => {
    dispatch(deleteRecord(type, record, () => {
        dispatch(getList(params));
    }));
};

export const toFamily = record => dispatch => {
    dispatch(update({currentOrder: record}));
    dispatch(push(`${window.location.pathname}/${record.id}`));
};

export const toDetail = (record, depth) => dispatch => {
    dispatch(push(`/manage/insect/taxonomy/${taxonomyField[depth].name}/${record.id}/${taxonomyField[depth + 1].name}`));
};

export const moveRow = (dragIndex, hoverIndex) => moveRecord('order', dragIndex, hoverIndex);

export const doSort = (sortType) => dispatch => {
    message.info('请拖动列表排序,结束后按保存按钮');
    dispatch(update({sortType}));
};

export const saveSort = (sortType, id) => saveSortResult(sortType, id);
