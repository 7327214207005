const initialData = {
    listData: [],
    query: {
        offset: 0,
        limit: 10,
        search: ''
    },
    total: 0
};
const search = (state = initialData, {type, payload}) => {
    if (type === 'state.insect.search') {
        return {...state, ...payload};
    }
    if (type === 'state.insect.search.query') {
        return {...state, query: {...state.query, ...payload}};
    }
    return state;
};

export default search;
