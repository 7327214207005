import {message, Modal} from 'antd';
import {beforeAvatarUpload, loadUserData} from '../../../util';

const update = payload => ({type: 'state.user.index', payload});

export const loadAnalyseData = () => (dispatch, getState, {request}) => {
    request.get('/users/insect/analyse')
        .then(({data}) => {
            if (data.auditedRows) {
                const phylum = {};
                const classObj = {};
                const order = {};
                const family = {};
                const taxonomyData = {};
                let collectSum = 0;
                data.taxonomyRows.forEach(({phylumChinese, classChinese, orderChinese, familyChinese, sum}) => {
                    phylum[phylumChinese] = 1;
                    classObj[`${phylumChinese}-${classChinese}`] = 1;
                    order[`${phylumChinese}-${classChinese}-${orderChinese}`] = 1;
                    family[`${phylumChinese}-${classChinese}-${orderChinese}-${familyChinese}`] = 1;
                    collectSum += sum;
                    if (!taxonomyData[phylumChinese]) {
                        taxonomyData[phylumChinese] = {};
                    }
                    if (!taxonomyData[phylumChinese][classChinese]) {
                        taxonomyData[phylumChinese][classChinese] = {};
                    }
                    if (!taxonomyData[phylumChinese][classChinese][orderChinese]) {
                        taxonomyData[phylumChinese][classChinese][orderChinese] = {};
                    }
                    taxonomyData[phylumChinese][classChinese][orderChinese][familyChinese] = 1;
                });
                data.taxonomyData = taxonomyData;
                data.sumData = {
                    phylum: Object.keys(phylum).length,
                    class: Object.keys(classObj).length,
                    order: Object.keys(order).length,
                    family: Object.keys(family).length,
                    specificName: data.auditedRows.length,
                    collectSum
                };
            }
            dispatch(update({insectAnalyse: data}));
            console.log(data);
        })
};

export const loadCollectData = (page) => (dispatch, getState, {request}) => {
    let {collectParam} = getState().user.index;
    if (page) {
        collectParam = {offset: (page - 1) * collectParam.limit, limit: collectParam.limit};
        dispatch(update({collectParam}));
    }
    request.get('/users/insect/collect', {params: collectParam})
        .then(({data}) => {
            const {rows, insectData, total} = data;
            if (total !== undefined && rows) {
                // if (rows.length === 0 && offset) {
                //     const beforeOffset = total - 10;
                //     dispatch(loadData({offset: beforeOffset < 0 ? 0 : beforeOffset}));
                // }
                const insectObj = {};
                insectData.forEach(({id, ...data}) => {
                    insectObj[id] = data;
                });
                const list = rows.map(({data, auxiliaryData, auditedData, ...other}) => {
                    // 以下代码导致用户编辑时总是看到错误数据, 先做注释处理
                    // if (auditedData) {
                    //     auditedData = JSON.parse(auditedData);
                    //     if (typeof auditedData.images === 'string') {
                    //         auditedData.images = auditedData.images.split(',');
                    //     }
                    //     data = {...data, ...auditedData};
                    // }
                    let insectData;
                    if (data.insectId) {
                        insectData = insectObj[data.insectId];
                    } else {
                        insectData = auxiliaryData;
                    }
                    return {data, auxiliaryData, insectData, ...other};
                });
                dispatch(update({
                    collectData: {
                        // offset,
                        // limit,
                        list,
                        total
                    }
                }));
            }
            // dispatch(update({collectData: data}));
            console.log(data);
        })
};

export const editCollect = record => (dispatch, getState, {request}) => {
    if (record.status === 1) {
        Modal.confirm({
            content: '该条记录待审核，撤销提审后方可编辑',
            onOk: () => {
                return request.delete(`/users/insect/collect/${record.id}/audit`)
                    .then(({data}) => {
                        if (data.message === 'success') {
                            record.status = 0;
                            dispatch(update({editCollectData: record}))
                        } else {
                            message.error('撤回失败,记录可能不是待审核状态')
                        }
                    })
            }
        });
        return;
    }
    dispatch(update({editCollectData: record}))
};

export const saveCollect = (collectData) => (dispatch, getState, {request}) => {
    console.log(collectData);
    const user = getState().user.index;
    const {props: {id}, state: {data, auxiliaryData, insect}} = collectData;
    if (auxiliaryData.matchMode === 1 && !data.insectId) {
        return message.info('请设置昆虫名称项');
    }
    if (auxiliaryData.matchMode !== 1 && !auxiliaryData.orderChinese) {
        return message.info('请填写目名');
    }
    if (data.images.length === 0) {
        return message.info('请上传物种图片');
    }
    request.patch(`/users/insect/collect/${id}`, {data, auxiliaryData})
        .then(({data: res}) => {
            if (res.message === 'success') {
                message.info('提交成功');
                const record = user.collectData.list.find((record) => record.id === id);
                if (record) {
                    record.status = 1;
                    record.data = data;
                    record.auxiliaryData = auxiliaryData;
                    if (data.insectId) {
                        record.insectData = insect;
                    } else {
                        record.insectData = auxiliaryData;
                    }
                }
                dispatch(update({collectData: {...user.collectData}, editCollectData: null}));
                return;
            }
            message.error(JSON.stringify(res));
        });
};

export const cancelEditCollect = () => (update({editCollectData: null}));

export const loadAcceptedData = (param) => (dispatch, getState, {request}) => {
    let {acceptedParam} = getState().user.index;
    if (param) {
        acceptedParam = param;
        dispatch(update({acceptedParam}));
    } else {
        acceptedParam = {offset: 0, limit: acceptedParam.limit};
        dispatch(update({acceptedParam}));
    }
    request.get('/users/insect/accepted', {params: acceptedParam})
        .then(({data}) => {
            data.rows.forEach(item => {
                item.images = item.images ? item.images.split(',') : [];
            });
            dispatch(update({acceptedData: data}));
        });
}

export const changeAvatar = e => (dispatch, getState, {request}) => {
    const {files: [file]} = e.target;
    if (!file) return;
    if (!beforeAvatarUpload(file)) return;
    request.get('/passport/avatar/sign', {params: {name: file.name, type: file.type}})
        .then(({data}) => {
            file.finalName = data.key;
            const form = new FormData();
            Object.keys(data).forEach(key => {
                form.append(key, data[key]);
            });
            form.append('file', file);
            request.post(getState().frameworks.config.imgEndpoint, form)
                .then(() => {
                    request.patch('/users/info', {name: 'avatar', value: file.finalName})
                        .then(({data}) => {
                            if (data.message === 'success') {
                                message.success('修改成功');
                                dispatch(loadUserData(true, false));
                            } else {
                                message.info(JSON.stringify(data));
                            }
                        });
                })
        })
}
