import {combineReducers} from 'redux';
import detail from './detail';
import search from './search';
import edit from './edit';

const initialEdit = {
    name: ''
};

const initialData = {
    showEdit: false,
    edit: initialEdit,
    listData: []
};

const index = (state = initialData, {type, payload}) => {
    if (type === 'state.manage.insect.morphology') {
        return {...state, ...payload};
    }
    if (type === 'state.manage.insect.morphology.edit') {
        return {...state, edit: {...state.edit, ...payload}};
    }
    if (type === 'state.manage.insect.morphology.new') {
        return {...state, showEdit: true, edit: initialEdit};
    }
    return state;
};

const morphology = combineReducers({
    index,
    detail,
    search,
    edit
})

export default morphology;
