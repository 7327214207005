const initialData = {
    list: [],
    activeIndex: 0,
    insectData: {},
    selected: 'empty',
    groupData: [],
    filterData: {},
    imageMargin: 0,
    remark: '',
    referInsect: {},
    query: {}
};

const audit = (state = initialData, {type, payload}) => {
    if (type === 'state.insect.audit') {
        return {...state, ...payload};
    }
    return state;
};

export default audit;
