import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import * as actions from './actions';
import logo from '../../images/logo.png';
import {Button, Checkbox, Form, Input, Modal, Space} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import ImgListEditor from '../../component/ImgListEditor';
import {beforeAvatarUpload} from '../../util';

const formLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 18},
};
const checkBoxLayout = {
    wrapperCol: {offset: 1, span: 23},
};

const Passport = (props) => {
    const isRegister = props.location.pathname.endsWith('register');
    const isResetPassword = props.location.pathname.endsWith('reset-password');
    const [form] = Form.useForm();
    const handleFormChange = (changedValues) => {
        if (changedValues.avatar && changedValues.avatar.length > 1) {
            form.setFieldsValue({avatar: [changedValues.avatar[changedValues.avatar.length - 1]]});
        }
    }
    return (
        <Container>
            <Row>
                <Col>
                    <div style={{
                        border: '.2rem solid #ececec',
                        borderRadius: '10px',
                        margin: '100px auto',
                        maxWidth: '500px',
                        padding: '50px 30px'
                    }}>
                        <h3 style={{textAlign: 'center'}}><img src={logo} alt={''} height={30}/> 山东物种多样性信息平台</h3>
                        <Form {...formLayout} form={form} onValuesChange={handleFormChange} onFinish={values => {
                            if (isRegister) {
                                props.register(values);
                            } else if (isResetPassword) {
                                props.resetPassword(values);
                            } else {
                                props.login(values);
                            }
                        }}>
                            <Form.Item label={'手机号'} name={'telephone'} rules={[{required: true}]}>
                                <Input placeholder="请输入手机号"/>
                            </Form.Item>
                            {(isRegister || isResetPassword) && <Form.Item label={'验证码'} name={'code'}>
                                <Input.Search
                                    placeholder="请输入验证码"
                                    enterButton={props.phoneCodeWait || '发送验证码'}
                                    loading={props.phoneCodeWait !== 0}
                                    onSearch={() => {
                                        form.validateFields(['telephone'])
                                            .then(data => {
                                                props.sendPhoneCode(data.telephone, isRegister ? 'register' : 'reset-password');
                                            });
                                    }}
                                />
                            </Form.Item>}
                            <Form.Item label={'密码'}>
                                <Form.Item name="password" noStyle>
                                    <Input.Password placeholder="请输入密码"/>
                                </Form.Item>
                                {!(isRegister && isResetPassword) && <div className={'text-right'}>
                                    <Button
                                        type={'link'}
                                        onClick={() => props.history.push('/passport/reset-password')}
                                    >
                                        忘记密码
                                    </Button>
                                </div>}
                            </Form.Item>
                            {(isRegister || isResetPassword) &&
                            <Form.Item label={'确认密码'} name={'passwordConfirm'}>
                                <Input.Password placeholder="请再次输入密码"/>
                            </Form.Item>}
                            {isRegister && <>
                                <Form.Item label={'邮箱'} name="email">
                                    <Input placeholder="请输入邮箱"/>
                                </Form.Item>
                                <Form.Item label={'真实姓名'} name="name">
                                    <Input placeholder="请输入真实姓名"/>
                                </Form.Item>
                                <Form.Item label={'头像'} name={'avatar'} valuePropName={'images'}>
                                    <ImgListEditor
                                        signUrl={'/passport/avatar/sign'}
                                        mode={'oss'}
                                        beforeUpload={beforeAvatarUpload}
                                    >
                                        <div>
                                            <PlusOutlined/>
                                            <div className="ant-upload-text">上传头像</div>
                                        </div>
                                    </ImgListEditor>
                                </Form.Item>
                                <Form.Item label={'通讯地址'} name="address">
                                    <Input placeholder="请输入通讯地址"/>
                                </Form.Item>
                                <Form.Item label={'工作单位'} name="workplace">
                                    <Input placeholder="请输入工作单位"/>
                                </Form.Item>
                                <Form.Item {...checkBoxLayout}>
                                    <Checkbox onChange={props.acceptProtocolChange}
                                              checked={props.acceptProtocol}>我已经看过并同意《用户注册协议》中的所有条款</Checkbox>
                                </Form.Item>
                            </>}
                            <div className={'text-center'}>
                                <Space>
                                    {!(isRegister || isResetPassword) &&
                                    <Button type="primary" htmlType={'submit'}>
                                        登录
                                    </Button>
                                    }
                                    {!isResetPassword && <Button
                                        type={isRegister ? 'primary' : 'default'}
                                        htmlType={isRegister ? 'submit' : 'button'}
                                        onClick={isRegister ? undefined : props.toRegister}
                                    >
                                        注册
                                    </Button>}
                                    {isResetPassword &&
                                    <Button type="primary" htmlType={'submit'}>
                                        重置密码
                                    </Button>
                                    }
                                </Space>
                            </div>
                        </Form>
                    </div>
                    <Modal open={props.showProtocol} onOk={props.closeProtocol} onCancel={props.rejectProtocol}
                           okText={'同意'} title={'用户注册协议'}>
                        <p>欢迎您注册网站，本站只接受实名认证人员参与数据库建设和讨论，请用真实身份注册。本站默认您上传到本站的图片，同意本站代理出版等销售该图片，并获得稿酬。</p>
                        <p>为维护网上公共秩序和社会稳定，请您自觉遵守以下条款：</p>
                        <p>一、不得利用本站危害国家安全、泄露国家秘密，不得侵犯国家社会集体的和公民的合法权益，不得利用本站制作、复制和传播下列信息：</p>
                        <p>（一）煽动抗拒、破坏宪法和法律、行政法规实施的；</p>
                        <p>（二）煽动颠覆国家政权，推翻社会主义制度的；</p>
                        <p>（三）煽动分裂国家、破坏国家统一的；</p>
                        <p>（四）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
                        <p>（五）捏造或者歪曲事实，散布谣言，扰乱社会秩序的；</p>
                        <p>（六）宣扬封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪的；</p>
                        <p>（七）公然侮辱他人或者捏造事实诽谤他人的，或者进行其他恶意攻击的；</p>
                        <p>（八）损害国家机关信誉的；</p>
                        <p>（九）其他违反宪法和法律行政法规的；</p>
                        <p>（十）进行商业广告行为的。</p>
                        <p>二、互相尊重，对自己的言论和行为负责。</p>
                        <p>三、上传到本站的图片等任何数据信息归本站和版权人所有，对本站图片和信息任何形式的使用都需要得到版权人或本站的授权。</p>
                    </Modal>
                </Col>
            </Row>
        </Container>
    );
};

export default connect(state => state.passport, actions)(Passport);
