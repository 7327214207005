import {showMessage} from '../../../component/MessageAlert';

const update = payload => ({type: 'state.insect.batch', payload});

export const uploadInsect = e => (dispatch, getState, {request}) => {
    const {files: [file], value} = e.target;
    if (!file) return;
    const postData = new FormData();
    postData.append('xls', file);
    console.log(file, value);
    dispatch(update({insectMessage: `开始上传${file.name}`}));
    request.put('/users/insect/batch', postData)
        .then(({data}) => {
            const {message, receiveLength = 0, affectedRows = 0, errorList = []} = data;
            console.log(data);
            if (message && message !== 'success') {
                return dispatch(showMessage({message}));
            }
            const errObj = {
                innerRepeat: 0,
                databaseRepeat: 0,
                noLatinName: 0,
                dataInvalid: 0
            };
            let otherError = 0;
            errorList.forEach(({errorType}) => {
                if (errObj[errorType] === undefined) {
                    otherError++;
                    return;
                }
                errObj[errorType]++;
            });
            const errorText = [];
            if (errObj.dataInvalid) {
                errorText.push(`数据错误:${errObj.dataInvalid}条,`);
            }
            if (errObj.noLatinName) {
                errorText.push(`无法组合拉丁名:${errObj.noLatinName}条,`);
            }
            if (errObj.databaseRepeat) {
                errorText.push(`拉丁名重复:${errObj.databaseRepeat}条,`);
            }
            if (errObj.innerRepeat) {
                errorText.push(`导入文件内重复:${errObj.innerRepeat}条,`);
            }
            if (otherError) {
                errorText.push(`其他错误:${otherError}条,`);
            }
            dispatch(update({insectMessage: `${file.name}共计接受数据${receiveLength}条,成功导入${affectedRows}条,${errorText.join('')}`}));
        });
};

export const uploadCollect = e => (dispatch, getState, {request}) => {
    const {files: [file], value} = e.target;
    if (!file) return;
    const postData = new FormData();
    postData.append('xls', file);
    console.log(file, value);
    dispatch(update({collectMessage: `开始上传${file.name}`}));
    request.put('/users/insect/batch/collect', postData)
        .then(({data}) => {
            const {message, receiveLength = 0, affectedRows = 0, errorList = []} = data;
            console.log(data);
            if (message && message !== 'success') {
                return dispatch(showMessage({message}));
            }
            const errObj = {
                numberRepeat: 0,
                LatinNameNotFound: 0,
                dataInvalid: 0
            };
            let otherError = 0;
            errorList.forEach(({errorType}) => {
                if (errObj[errorType] === undefined) {
                    otherError++;
                    return;
                }
                errObj[errorType]++;
            });
            const errorText = [];
            if (errObj.dataInvalid) {
                errorText.push(`数据错误:${errObj.dataInvalid}条,`);
            }
            if (errObj.LatinNameNotFound) {
                errorText.push(`找不到拉丁名:${errObj.LatinNameNotFound}条,`);
            }
            if (errObj.numberRepeat) {
                errorText.push(`馆藏号重复:${errObj.numberRepeat}条,`);
            }
            if (otherError) {
                errorText.push(`其他错误:${otherError}条,`);
            }
            dispatch(update({collectMessage: `${file.name}共计接受数据${receiveLength}条,成功导入${affectedRows}条,${errorText.join('')}`}));
        });
};

export const uploadSpecialData = e => (dispatch, getState, {request}) => {
    const {files: [file], value} = e.target;
    if (!file) return;
    const postData = new FormData();
    postData.append('xls', file);
    console.log(file, value);
    dispatch(update({specialMessage: `开始上传${file.name}`}));
    request.put('/users/insect/special/batch', postData)
        .then(({data}) => {
            const {message, receiveLength = 0, affectedRows = 0, errorList = []} = data;
            console.log(data);
            if (message && message !== 'success') {
                return dispatch(showMessage({message}));
            }
            const errObj = {
                noSpecial: 0,
                noLatinName: 0,
                databaseRepeat: 0,
                dataInvalid: 0
            };
            let otherError = 0;
            errorList.forEach(({errorType}) => {
                if (errObj[errorType] === undefined) {
                    otherError++;
                    return;
                }
                errObj[errorType]++;
            });
            const errorText = [];
            if (errObj.dataInvalid) {
                errorText.push(`数据错误:${errObj.dataInvalid}条,`);
            }
            if (errObj.noLatinName) {
                errorText.push(`找不到拉丁名:${errObj.noLatinName}条,`);
            }
            if (errObj.noSpecial) {
                errorText.push(`找不到专题名:${errObj.noSpecial}条,`);
            }
            if (errObj.databaseRepeat) {
                errorText.push(`重复数据:${errObj.databaseRepeat}条,`);
            }
            if (otherError) {
                errorText.push(`其他错误:${otherError}条,`);
            }
            dispatch(update({specialMessage: `${file.name}共计接受数据${receiveLength}条,成功导入${affectedRows}条,${errorText.join('')}`}));
        });
};

export const uploadMorphologyData = e => (dispatch, getState, {request}) => {
    const {files: [file]} = e.target;
    if (!file) return;
    const postData = new FormData();
    postData.append('xls', file);
    dispatch(update({morphologyMessage: `开始上传${file.name}`}));
    request.put('/users/insect/morphology/batch', postData)
        .then(({data}) => {
            const {message, receiveLength = 0, affectedRows = 0, relevanceRows = 0, errorList = [], morphologyUnit = 0} = data;
            if (message && message !== 'success') {
                return dispatch(showMessage({message}));
            }
            const errObj = {
                noMorphology: 0,
                noLatinName: 0,
                databaseRepeat: 0,
                dataInvalid: 0
            };
            let otherError = 0;
            errorList.forEach(({errorType}) => {
                if (errObj[errorType] === undefined) {
                    otherError++;
                    return;
                }
                errObj[errorType]++;
            });
            const errorText = [];
            if (errObj.dataInvalid) {
                errorText.push(`数据错误:${errObj.dataInvalid}条,`);
            }
            if (errObj.noLatinName) {
                errorText.push(`找不到拉丁名:${errObj.noLatinName}条,`);
            }
            if (errObj.noMorphology) {
                errorText.push(`找不到相关形态:${errObj.noMorphology}条,`);
            }
            if (errObj.databaseRepeat) {
                errorText.push(`重复关联:${errObj.databaseRepeat}条,`);
            }
            if (otherError) {
                errorText.push(`其他错误:${otherError}条,`);
            }
            dispatch(update({morphologyMessage: `${file.name}共计接受数据${receiveLength}行,形态关联数据${morphologyUnit}个,新增形态${affectedRows}种,成功关联${relevanceRows}条,${errorText.join('')}`}));
        });
};

export const uploadLiteratureData = e => (dispatch, getState, {request}) => {
    const {files: [file], name, value} = e.target;
    if (!file) return;
    const postData = new FormData();
    postData.append('xls', file);
    console.log(file, value);
    dispatch(update({literatureMessage: `开始上传${file.name}`}));
    request.put(`/users/literature/batch/${name}`, postData)
        .then(({data}) => {
            const {message, receiveLength = 0, affectedRows = 0, bindRows = 0, errorList = [], bindErrorList = []} = data;
            console.log(data);
            if (message && message !== 'success') {
                return dispatch(showMessage({message}));
            }
            const errObj = {
                duplicateCheckingEmpty: 0,
                databaseRepeat: 0,
                innerRepeat: 0,
                dataInvalid: 0,
                noLatinName: 0,
                existLiterature: 0
            };
            let otherError = 0;
            errorList.forEach(({errorType}) => {
                if (errObj[errorType] === undefined) {
                    otherError++;
                    return;
                }
                errObj[errorType]++;
            });
            bindErrorList.forEach(({errorType}) => {
                if (errObj[errorType] === undefined) {
                    otherError++;
                    return;
                }
                errObj[errorType]++;
            });
            const errorText = [];
            if (errObj.dataInvalid) {
                errorText.push(`数据错误:${errObj.dataInvalid}条,`);
            }
            if (errObj.duplicateCheckingEmpty) {
                errorText.push(`查重项为空:${errObj.noSpecial}条,`);
            }
            if (errObj.existLiterature) {
                errorText.push(`已有文献:${errObj.existLiterature}条,`);
            }
            if (errObj.noLatinName) {
                errorText.push(`找不到拉丁名:${errObj.noLatinName}条,`);
            }
            if (errObj.databaseRepeat) {
                errorText.push(`重复关联:${errObj.databaseRepeat}条,`);
            }
            if (errObj.innerRepeat) {
                errorText.push(`导入文件内重复:${errObj.innerRepeat}条,`);
            }
            if (otherError) {
                errorText.push(`其他错误:${otherError}条,`);
            }
            dispatch(update({literatureMessage: `${file.name}共计接受数据${receiveLength}条,新增文献${affectedRows}条,新增绑定关系${bindRows}条,${errorText.join('')}`}));
        });
};

export const uploadImgPackage = e => (dispatch, getState, {request}) => {
    const {files: [file]} = e.target;
    if (!file) return;
    const postData = new FormData();
    postData.append('zip', file);
    dispatch(update({imgMessage: `开始上传${file.name}`, imgProgress: 0}));
    request.put('/users/insect/img/package', postData, {
        onUploadProgress: progressEvent => {
            dispatch(update({imgProgress: Math.floor(progressEvent.loaded / progressEvent.total * 100)}));
        }
    })
        .then(({data}) => {
            const {message, fileResult, errorList = []} = data;
            console.log(data);
            if (message && message !== 'success') {
                return dispatch(showMessage({message}));
            }
            const dir = Object.keys(fileResult);
            let normalFile = 0;
            const noNumberFile = [];
            const errorFile = [];
            dir.forEach(dir => {
                const data = fileResult[dir];
                normalFile += data.normalFile.length;
                noNumberFile.push(...data.noNumberFile);
                errorFile.push(...data.errorFile);
            });
            const strs = [`成功解包${dir.length}个,失败${errorList.length}个,导入图片${normalFile}张`];
            if (noNumberFile.length) {
                strs.push(`找不到对应编号图片${noNumberFile.length}张(${noNumberFile.join(',')})`);
            }
            if (errorFile.length) {
                strs.push(`错误图片${errorFile.length}张(${errorFile.join(',')})`);
            }
            dispatch(update({imgMessage: strs.join(',')}));
        });
};
