import {combineReducers} from 'redux';

const initialData = {
    insectAnalyse: {},
    collectParam: {
        offset: 0,
        limit: 10
    },
    collectData: {},
    editCollectData: null,
    acceptedParam: {
        offset: 0,
        limit: 10
    },
    acceptedData: {}
};

const index = (state = initialData, {type, payload}) => {
    if (type === 'state.user.index') {
        return {...state, ...payload};
    }
    return state;
};

const user = combineReducers({
    index
});

export default user;
