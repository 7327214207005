import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {Statistic} from 'antd';

const StatisticView = props => {
    if (props.sumData) {
        return <Row className={'index-statistic-wrapper no-gutters'}>
            <Col className={'py-2'}>
                <Statistic
                    suffix="门"
                    value={props.sumData.phylum}
                    valueStyle={{color: '#fff'}}
                />
            </Col>
            <Col className={'py-2'}>
                <Statistic
                    suffix="纲"
                    value={props.sumData.class}
                    valueStyle={{color: '#fff'}}
                />
            </Col>
            <Col className={'py-2'}>
                <Statistic
                    suffix="目"
                    value={props.sumData.order}
                    valueStyle={{color: '#fff'}}
                />
            </Col>
            <Col className={'py-2'}>
                <Statistic
                    suffix="科"
                    value={props.sumData.family}
                    valueStyle={{color: '#fff'}}
                />
            </Col>
            {props.sumData.genus !== undefined && <Col className={'py-2'}>
                <Statistic
                    suffix="属"
                    value={props.sumData.genus}
                    valueStyle={{color: '#fff'}}
                />
            </Col>}
            <Col className={'py-2'}>
                <Statistic
                    suffix="种"
                    value={props.sumData.specificName}
                    valueStyle={{color: '#fff'}}
                />
            </Col>
            <Col className={'py-2'}>
                <Statistic
                    suffix="记录"
                    value={props.sumData.collect}
                    valueStyle={{color: '#fff'}}
                />
            </Col>
        </Row>
    }
    return null;
}

export default StatisticView;
