const initialData = {
    collect: []
};
const reducer = (state = initialData, {type, payload}) => {
    if (type === 'action.insect.detail') {
        return {...state, ...payload};
    }
    return state;
};

export default reducer;
