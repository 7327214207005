import {push} from 'connected-react-router';
import {message, Modal} from 'antd';

const update = payload => ({type: 'state.manage.insect.morphology', payload});
const updateEdit = payload => ({type: 'state.manage.insect.morphology.edit', payload});

export const newData = () => ({type: 'state.manage.insect.morphology.new'});

export const hiddenEdit = () => update({showEdit: false});

export const inputChange = ({target}) => {
    return updateEdit({[target.name]: target.value});
};

export const getList = () => (dispatch, getState, {request}) => {
    request.get('/insect/morphology')
        .then(({data}) => {
            dispatch(update({listData: data}));
        });
};

export const save = () => (dispatch, getState, {request}) => {
    const {id, ...data} = getState().manage.insect.morphology.index.edit;
    if (!data.name) {
        message.info('请输入形态名称');
        return;
    }
    if (!data.type) {
        message.info('请输入形态类别');
        return;
    }
    (id ? request.patch(`/users/insect/morphology/${id}`, data) : request.post('/users/insect/morphology', data))
        .then(({data}) => {
            console.log(data);
            dispatch(getList());
            dispatch(update({showEdit: false}));
        });
};

export const toDetail = (data) => dispatch => {
    dispatch({type: 'state.manage.insect.morphology.detail', payload: {data}});
    dispatch(push(`${window.location.pathname}/detail/${data.id}`));
};

export const editRow = (data) => dispatch => {
    dispatch(update({showEdit: true, edit: data}));
};

export const deleteRow = data => (dispatch, getState, {request}) => {
    Modal.confirm({
        title: `确认删除${data.name}专题吗`,
        onOk: () => {
            return request.delete(`/users/insect/morphology/${data.id}`)
                .then(({data}) => {
                    if (data) {
                        if (data.message === 'success') {
                            message.success('删除成功');
                            dispatch(getList());
                            return;
                        }
                    }
                    console.log(data);
                    message.error(JSON.stringify(data));
                });
        }
    });
};
