const initialData = {
    loading: false,
    showProtocol: false,
    acceptProtocol: false,
    phoneCodeWait: 0
};
const passport = (state = initialData, {type, payload}) => {
    if (type === 'action.passport') {
        return {...state, ...payload};
    }
    return state;
};

export default passport;
