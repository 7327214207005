import {push, replace} from "connected-react-router";

const update = payload => ({type: 'state.insect.search', payload});
const updateQuery = payload => ({type: 'state.insect.search.query', payload});

export const loadData = () => (dispatch, getState, {request}) => {
    const state = getState().insect.search;
    dispatch(update({loading: true}));
    return request.get('/insect', {params: state.query})
        .then(({data: {rows, total}}) => {
            rows.forEach(item => {
                item.images = item.images ? item.images.split(',') : [];
            });
            const listData = state.listData.concat(rows);
            dispatch(update({listData, total}));
        })
        .finally(() => {
            dispatch(update({loading: false}));
        })
};

export const toInsectDetail = ({id}) => push(`/insect/${id}`);

export const loadMore = page => (dispatch, getState) => {
    const state = getState().insect.search;
    console.log(page, 'loadMore');
    if (state.loading) return;
    dispatch(updateQuery({offset: state.query.offset + state.query.limit}));
    return dispatch(loadData());
};

export const doSearch = (search, type) => dispatch => {
    dispatch(replace(`${window.location.pathname}?${type}=${search}`));
    dispatch(update({query: {[type]: search, offset: 0, limit: 10}, listData: [], total: 0}));
    if (search) {
        dispatch(loadData());
    }
};
