import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import * as actions from './actions';

const Provider = props => {
    const {match: {params}, data, setSpecialData} = props;
    useEffect(() => {
        if (+params.id === data.id) {
            setSpecialData && setSpecialData(data);
        } else {
            props.resetItem();
            if (setSpecialData) {
                props.loadSpecialData(params)
                    .then(setSpecialData);
            } else {
                props.loadSpecialData(params);
            }
        }
    }, [params.id]);
    return null;
}

const DetailProvider = connect(state => state.insect.special.detail, actions)(Provider);

export const SpecialFilterProvider = props => {
    const [data, setData] = useState(null);
    const {component: Component, valuePropName = 'filter', ...otherProps} = props;
    const {match: {params}} = props;
    console.log('SpecialFilterProvider', data);
    if (data && +params.id === data.id) {
        return <Component {...{...otherProps, [valuePropName]: data.filter}}/>;
    }
    return <DetailProvider {...props} setSpecialData={setData}/>;
}

export const withSpecialFilter = (component, valuePropName) => props => {
    return <SpecialFilterProvider component={component} valuePropName={valuePropName} {...props}/>
    // return <div>1</div>
}

export default DetailProvider;
