import {combineReducers} from 'redux';
import special from './special';
import morphology from './morphology';
import collect from './collect';
import taxonomy from './taxonomy';

const initialData = {
    rows: [],
    total: 0,
    query: {
        order: 'desc',
        offset: 0,
        limit: 10
    },
    batchOption: false,
    checkedRows: []
};

const index = (state = initialData, {type, payload}) => {
    if (type === 'manage.insect.index') {
        return {...state, ...payload};
    }
    if (type === 'manage.insect.index.query') {
        return {...state, query: {...state.query, ...payload}};
    }
    return state;
};

const insect = combineReducers({
    index,
    collect,
    special,
    morphology,
    taxonomy
});

export default insect;
