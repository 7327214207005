import {showMessage} from '../../../component/MessageAlert';
import {message, Modal} from "antd";
import {push} from 'connected-react-router';

const update = (payload) => ({type: 'state.insect.audit', payload});

export const switchPage = (op) => (dispatch, getState) => {
    let {activeIndex, list, offset, limit, total} = getState().insect.audit;
    activeIndex += op;
    if (activeIndex < 0) {
        offset -= limit;
        if (offset >= 0) {
            dispatch(loadData({offset}))
                .then(() => dispatch(update({activeIndex: limit - 1})));
        }
    } else if (activeIndex < list.length) {
        dispatch(update({activeIndex}));
    } else {
        console.log(offset, 'a');
        offset += limit;
        console.log(offset, total);
        if (offset < total) {
            dispatch(loadData({offset}))
                .then(() => dispatch(update({activeIndex: 0})));
        }
    }
};

export const loadData = ({offset = 0, limit = 10, ...otherParams}) => (dispatch, getState, {request}) => {
    const {query} = getState().insect.audit;
    const params = {...query, ...otherParams, offset, limit};
    dispatch(update({query: params}));
    return request.get('/users/insect/waitAudit', {params})
        .then(({data}) => {
            const {rows, total} = data;
            if (total !== undefined && rows) {
                if (rows.length === 0 && offset > 0) {
                    const beforeOffset = total - 10;
                    dispatch(loadData({offset: beforeOffset < 0 ? 0 : beforeOffset}));
                    return;
                }
                const list = rows.map(({data, auxiliaryData, ...other}) => {
                    auxiliaryData = auxiliaryData || {};
                    return {data, auxiliaryData, ...other};
                });
                dispatch(update({
                    offset,
                    limit,
                    list,
                    total
                }));
                dispatch(onIndexChange());
            }
        });
};

export const onIndexChange = () => (dispatch, getState, {request}) => {
    const {list, activeIndex} = getState().insect.audit;
    if (activeIndex > -1 && list[activeIndex]) {
        const {data, auxiliaryData} = list[activeIndex];
        if (auxiliaryData.matchMode === 1) {
            request.get(`/insect/detail/${data.insectId}`)
                .then(({data}) => {
                    dispatch(update({insectData: data}));
                });
            return;
        }
    }
    dispatch(update({insectData: {}}));
};

export const changeInsectId = ({id}) => (dispatch, getState, {request}) => {
    const {list, activeIndex} = getState().insect.audit;
    const {data} = list[activeIndex];
    data.insectId = id;
    dispatch(update({list}));
    request.get(`/insect/detail/${id}`)
        .then(({data}) => {
            dispatch(update({insectData: data}));
        });
};

export const changeImages = images => (dispatch, getState, {request}) => {
    const {list, activeIndex} = getState().insect.audit;
    const {data} = list[activeIndex];
    data.images = images;
    dispatch(update({list}));
};

export const rejectCollect = () => dispatch => {
    Modal.confirm({
        title: '确认驳回',
        content: '驳回后需要采集人重新上传正确数据',
        okType: 'danger',
        onOk: () => dispatch(doAudit(0)),
    });
};

export const doAudit = isPass => (dispatch, getState, {request}) => {
    const state = getState()
    const {list, activeIndex, remark} = state.insect.audit;
    const {id, data, auxiliaryData, offset, createUser} = list[activeIndex];
    const {insectId, images} = data;
    let body;
    if (isPass) {
        if (!insectId) {
            return dispatch(showMessage({message: '请鉴定昆虫类型', type: 'warning'}));
        }
        let authenticateUser;
        if (auxiliaryData.matchMode === 1 && auxiliaryData.insectId === insectId) {
            authenticateUser = data.collectUserName;
        } else {
            authenticateUser = state.frameworks.user.name;
        }
        body = {id, data: {...data, images: images.join(','), createUser, authenticateUser}, isPass, remark}
    } else {
        body = {id, isPass, remark};
    }
    request.put('/users/insect/audit', body)
        .then(({data}) => {
            if (data.message === 'success') {
                dispatch(loadData({offset}));
                return dispatch(showMessage({message: '审核成功'}));
            }
            dispatch(showMessage({message: JSON.stringify(data)}));
        });
};

export const showAddInsect = () => (dispatch, getState) => {
    const {insect: {audit: {list, activeIndex}}, frameworks: {user: {permission}}} = getState();
    if (!permission.insectAdd) {
        return message.error('没有权限操作');
    }
    const {auxiliaryData: {matchMode, ...data}} = list[activeIndex];
    dispatch(push('/manage/insect/add', {initialValues: data}));
};

export const fetchGroupData = () => (dispatch, getState, {request}) => {
    request.get('/users/insect/waitAudit/group')
        .then(({data}) => {
            const obj = {};
            data.forEach(item => {
                let {order, family, sum} = item;
                item.order = order = order || 'empty';
                item.family = family = family || 'empty';
                let classObj = obj[order];
                if (!classObj) {
                    classObj = {field: order, label: order, children: []};
                    obj[order] = classObj;
                }
                const label = `${order}-${family}`;
                classObj.children.push({field: label, label: label, sum});
            });
            const groupData = Object.keys(obj).map(key => obj[key]);
            dispatch(update({groupData}));
        });
};

export const selectKey = (key) => (dispatch, getState, {request}) => {
    dispatch(update({selected: key}));
    if (key === 'taxonomy') {
        dispatch(fetchGroupData());
    }
};

export const selectTaxonomy = (key) => update({filterData: {taxonomy: key}});

export const inputUsername = (e) => update({filterData: {user: e.target.value}});

export const inputAddress = (e) => update({filterData: {address: e.target.value}});

export const startFilter = () => (dispatch, getState, {request}) => {
    const {selected, filterData} = getState().insect.audit;
    switch (selected) {
        case 'taxonomy':
            if (filterData.taxonomy) {
                const [order, family] = filterData.taxonomy.split('-');
                return dispatch(loadData({where: {order, family}}));
            }
            break;
        case 'user':
            return dispatch(loadData({where: {collectUserName: filterData.user}}));
        case 'address':
            return dispatch(loadData({where: {address: filterData.address}}));
        default:
            return dispatch(loadData({where: {}}));
    }
};

export const slide = (e) => (dispatch, getState) => {
    const {imageMargin} = getState().insect.audit;
    if (e.target.className.includes('-prev')) {
        dispatch(update({imageMargin: imageMargin + 150}));
    } else {
        dispatch(update({imageMargin: imageMargin - 150}));
    }
};

export const clickImage = (e) => {
    return update({activeIndex: +e.target.getAttribute('data-index') || 0});
};

export const changeRemark = e => update({remark: e.target.value});

export const selectReferInsect = id => (dispatch, getState, {request}) => {
    dispatch(update({referInsect: {id}}));
    request.get(`/insect/detail/${id}`)
        .then(({data}) => {
            let imageList = [];
            const {collect} = data;
            collect.forEach(({id, images}) => {
                if (images && images !== '') {
                    imageList.push(...images.split(',').map((src, index) => ({
                        url: `/uploadImg/tiny/${src}`,
                        key: `${id}-${index}`
                    })));
                }
            });
            dispatch(update({referInsect: {...data, images: imageList}}));
        });
};

export const changeField = e => (dispatch, getState) => {
    const {list, activeIndex} = getState().insect.audit;
    const {data} = list[activeIndex];
    data[e.target.name] = e.target.value;
    dispatch(update({list}));
}

export const changeFields = fields => (dispatch, getState) => {
    const {list, activeIndex} = getState().insect.audit;
    const {data} = list[activeIndex];
    console.log(data, fields);
    Object.assign(data, fields);
    dispatch(update({list}));
}
