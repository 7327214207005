import React, {useState} from 'react';
import {connect} from 'react-redux';
import IndexLayout from '../../../IndexLayout';
import {CloseOutlined, PlusOutlined, SaveOutlined} from '@ant-design/icons';
import {Button, Divider, Form, Modal, Select, Table, Typography, Upload} from 'antd';
import * as actions from './actions';
import dateUtil from '@/util/date';
import request from '../../../../util/request';
import debounce from 'lodash/debounce';

const EditableCell = connect(() => ({}), {editField: actions.editField})(props =>
    <Typography.Paragraph
        editable={{
            onChange: value => props.editField(props.record, {
                field: props.field,
                value
            })
        }}
    >
        {props.text}
    </Typography.Paragraph>
);

const EditableImgView = connect(() => ({}), {imagesSave: actions.imagesSave})(props => {
    const [record, recordChange] = useState(props.record);
    const [fileList, fileChange] = useState(props.imgList);
    if (record !== props.record) {
        recordChange(props.record);
        fileChange(props.imgList);
    }
    return <>&nbsp;
        {props.imgList !== fileList && <Button.Group>
            <Button type={'primary'} icon={<SaveOutlined/>}
                    onClick={() => props.imagesSave(record, fileList)}/>
            <Button type={'default'} icon={<CloseOutlined/>} onClick={() => fileChange(props.imgList)}/>
        </Button.Group>}
        <Upload
            action={'/api/users/insect/img'}
            listType={'picture-card'}
            fileList={fileList}
            onChange={({fileList}) => fileChange([...fileList])}
            showUploadList={{showPreviewIcon: true, showDownloadIcon: false}}
        >
            <div>
                <PlusOutlined/>
                <div className="ant-upload-text">图片上传</div>
            </div>
        </Upload>
    </>;
});

class InsectSelector extends React.Component {
    constructor(props) {
        super(props);
        this.handleSearch = debounce(this.doSearch, 500);
    }

    state = {
        insect: {},
        searchList: [],
        searchText: '',
        searching: false
    }

    doSearch = query => {
        this.setState({searchText: query, searching: !!query});
        if (query) {
            request.get('/insect/query', {params: {query}})
                .then(({data}) => {
                    this.setState({searchList: data, searching: false});
                })
        }
    };

    selectInsect = id => {
        this.setState({
            insect: {id}
        });
        request.get(`/insect/detail/${id}`)
            .then(({data}) => {
                this.setState({insect: data});
            });
    };

    render() {
        const {insect, searchList} = this.state;
        const {value = insect.id, onChange = this.handleSearch} = this.props;
        return <Select onSearch={this.handleSearch} showSearch filterOption={false} value={value} onChange={onChange}
                       placeholder="请输入科名/物种名称搜索">
            {searchList.map(item =>
                <Select.Option value={item.id} key={item.id}>{item.specificNameChinese}</Select.Option>
            )}
        </Select>
    }
}

const collectField = [
    {key: 'id', label: '', show: false},
    {key: 'number', label: '馆藏号'},
    {key: 'collectUserName', label: '采集人'},
    {key: 'collectTime', label: '采集时间', render: (text, record) => dateUtil(text).format('YYYY-MM-DD HH:mm')},
    {key: 'authenticateUser', label: '鉴定人'},
    {key: 'authenticateTime', label: '鉴定时间', render: (text, record) => dateUtil(text).format('YYYY-MM-DD HH:mm')},
    {key: 'province', label: '省'},
    {key: 'city', label: '市'},
    {key: 'district', label: '区'},
    {key: 'township', label: '街道'},
    {key: 'address', label: '详细地址'},
    {key: 'elevation', label: '海拔'},
    {key: 'lat', label: '纬度', detailOnly: true},
    {key: 'lng', label: '经度', detailOnly: true},
    {key: 'storageAddress', label: '保藏地点'},
    {key: 'storageType', label: '保藏方式'},
    {
        key: 'specimenProperty',
        label: '标本属性',
        render: (text, record) => <EditableCell text={text} record={record} field={'specimenProperty'}/>
    },
    {key: 'hostFamilyChinese', label: '寄主科名', detailOnly: true,
        render: (text, record) => <EditableCell text={text} record={record} field={'hostFamilyChinese'}/>
    },
    {key: 'hostFamily', label: 'hostFamily', detailOnly: true,
        render: (text, record) => <EditableCell text={text} record={record} field={'hostFamily'}/>
    },
    {
        key: 'host', label: '寄主中文名', detailOnly: true,
        render: (text, record) => <EditableCell text={text} record={record} field={'host'}/>
    },
    {
        key: 'hostLatinName',
        label: '寄主拉丁名',
        detailOnly: true,
        render: (text, record) => <EditableCell text={text} record={record} field={'hostLatinName'}/>
    },
    {
        key: 'harmWay',
        label: '生物学',
        detailOnly: true,
        render: (text, record) => <EditableCell text={text} record={record} field={'harmWay'}/>
    },
    {
        key: 'insectState',
        label: '发育阶段',
        render: (text, record) => <EditableCell text={text} record={record} field={'insectState'}/>
    },
    {
        key: 'sex',
        label: '性别',
        render: (text, record) => <EditableCell text={text} record={record} field={'sex'}/>
    },
    {
        key: 'habitatType',
        label: '生境类型',
        detailOnly: true,
        render: (text, record) => <EditableCell text={text} record={record} field={'habitatType'}/>
    },
    {
        key: 'remark',
        label: '备注',
        detailOnly: true,
        render: (text, record) => <EditableCell text={text} record={record} field={'remark'}/>
    },
    {
        key: 'images', label: '图片', detailOnly: true, render: (text, record) => {
            return <EditableImgView text={text} record={record} imgList={record.imgList} key={'images'}/>
        }
    }
];

class Collect extends React.Component {
    componentDidMount() {
        const {match: {params}, insectData: {id}} = this.props;
        const insectId = +params.id;
        insectId !== id && this.props.loadInsectData(params.id);
        this.props.loadCollectData(insectId);
    }

    moveRow = record => {
        let form = null;
        Modal.confirm({
            title: '移动采集信息',
            icon: null,
            content: <Form ref={e => form = e}>
                <Form.Item name={'insectId'} label={'物种名称'} rules={[
                    {
                        required: true,
                        message: '请选择物种名称',
                    },
                ]}>
                    <InsectSelector/>
                </Form.Item>
            </Form>,
            onOk: () => {
                return form.validateFields()
                    .then(({insectId}) => {
                        return this.props.editField(record, {field: 'insectId', value: insectId});
                    })
            }
        })
    }

    render() {
        const {props} = this;
        const {query, insectData = {}} = props;
        const rowSelection = {
            selectedRowKeys: props.checkedRows,
            onChange: props.onSelectChange,
        };
        return <IndexLayout>
            <Typography.Title level={4}>采集信息管理</Typography.Title>
            {insectData.id && <Typography.Title level={4}>{insectData.specificNameChinese}</Typography.Title>}
            <div className={'py-2'}>
                <Button type={'danger'} onClick={props.batchDelete}>批量删除</Button>
            </div>
            <Table
                dataSource={props.rows}
                rowKey={'id'}
                onChange={props.pageChange}
                pagination={{
                    showTotal: (total, range) => `展示第${range[0]}-${range[1]}条, 共${total}条`,
                    current: query.offset / query.limit + 1,
                    total: props.total,
                    pageSize: query.limit
                }}
                expandedRowRender={record => collectField.map(({key, label, detailOnly, render}) =>
                    detailOnly && <div key={key}>{label}:{render ? render(record[key], record) : record[key]}</div>
                )}
                rowSelection={props.batchOption ? rowSelection : null}
            >
                {collectField.map(({key, label, show, detailOnly, render}) =>
                    show !== false && !detailOnly &&
                    <Table.Column title={label} dataIndex={key} key={key}
                                  render={render}/>
                )}
                <Table.Column
                    title="Action"
                    key="action"
                    render={(text, record) => (
                        <span>
                        {/*<Button type="link" onClick={() => props.editRow(record)}>编辑</Button>*/}
                            {/*<Divider type="vertical"/>*/}
                            <Button type="link" onClick={() => this.moveRow(record)}>移动</Button>
                            <Divider type="vertical"/>
                            <Button type="link" onClick={() => props.deleteRow(record)}>删除</Button>
                        </span>
                    )}
                />
            </Table>
        </IndexLayout>;
    }
}

const ManageInsectCollect = connect(state => state.manage.insect.collect, actions)(Collect);

export default ManageInsectCollect;
