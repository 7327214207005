import {push} from 'connected-react-router';

const update = payload => ({type: 'state.manage.insect.morphology.search', payload});
const updateQuery = payload => ({type: 'state.manage.insect.morphology.search.query', payload});

export const loadData = () => (dispatch, getState, {request}) => {
    const state = getState().manage.insect.morphology.search
    dispatch(update({loading: true}));
    request.get('/users/insect/morphology/search', {params: state.query})
        .then(({data: {rows, total}}) => {
            rows.forEach(item => {
                item.images = item.images ? item.images.split(',') : [];
            });
            dispatch(update({rows: state.rows.concat(rows), total}));
        })
        .finally(() => {
            dispatch(update({loading: false}));
        })
}

export const loadMore = () => (dispatch, getState) => {
    const state = getState().manage.insect.morphology.search;
    if (state.loading) return;
    dispatch(updateQuery({offset: state.query.offset + state.query.limit}));
    return dispatch(loadData());
};

export const editSearch = ({area, ...data}) => dispatch => {
    dispatch({type: 'state.manage.insect.morphology.edit', payload: {...data, area: JSON.parse(area)}});
    dispatch(push(`./search/edit`));
}

export const initLoadData = () => dispatch => {
    dispatch(update({query: {offset: 0, limit: 10}, rows: [], total: 0}));
    dispatch(loadData());
}

export const newData = () => push('./search/edit')
