import React from 'react';
import IndexLayout from '../../IndexLayout';
import {Button, Col, Divider, Input, Row, Table, Typography} from 'antd';
import {connect} from 'react-redux';
import dateUtil from '@/util/date';

import * as actions from './actions';

class User extends React.Component {
    componentDidMount() {
        this.props.loadData();
    }

    render() {
        const {props} = this;
        const {query, rows} = props;
        return (
            <IndexLayout>
                <Row className={'p-3'}>
                    <Col flex={'auto'}>
                        <Typography.Title level={4}>用户管理</Typography.Title>
                    </Col>
                    <Col>
                        <Input.Search placeholder="输入手机号或姓名搜索" defaultValue={query.search} onSearch={props.handleSearch}
                                      enterButton/>
                    </Col>
                </Row>
                <Table
                    rowKey={'id'} dataSource={rows}
                    pagination={{
                        showTotal: (total, range) => `展示第${range[0]}-${range[1]}条, 共${total}条`,
                        current: query.offset / query.limit + 1,
                        total: props.total,
                        pageSize: query.limit
                    }}
                    onChange={props.tableChange}
                >
                    <Table.Column title={'手机号'} dataIndex={'telephone'}/>
                    <Table.Column title={'姓名'} dataIndex={'name'}/>
                    <Table.Column title={'工作单位'} dataIndex={'workplace'}/>
                    <Table.Column title={'邮箱'} dataIndex={'email'}/>
                    <Table.Column title={'注册时间'} dataIndex={'createTime'}
                                  render={text => dateUtil(text).format('YYYY-MM-DD HH:mm')}/>
                    <Table.Column
                        title={'操作'} dataIndex={'action'}
                        render={(value, record, index) => {
                            return <Button.Group>
                                <Button type="link" onClick={() => props.toDetail(record)}>查看详情</Button>
                                <Divider type="vertical"/>
                                <Button type="link" onClick={() => props.deleteRow(record)}>删除</Button>
                            </Button.Group>
                        }}
                    />
                </Table>
            </IndexLayout>
        );
    }
}

const ManageUser = connect(state => state.manage.user.index, actions)(User);

export default ManageUser;
