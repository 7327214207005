import React from 'react';
import {push} from 'connected-react-router';
import {Input, message, Modal} from 'antd';
import request from './request';
import {useRequest} from 'ahooks';

export const loadUserData = (flush, autoRedirect = true) => (dispatch, getState, {request}) => {
    const {user} = getState().frameworks;
    if (flush || !user.id) {
        request.get('/users/info', autoRedirect ? {
            validateStatus: function (status) {
                return (status >= 200 && status < 300) || status === 401 || status === 403;
            },
        } : null)
            .then(({data}) => {
                if (data.message) {
                    if (autoRedirect) {
                        dispatch(push('/passport'));
                    }
                    return;
                }
                dispatch({type: 'state.frameworks', payload: {user: data}});
            })
            .catch(e => {
                console.log(e);
            })
    }
};

export const logout = () => (dispatch, getState, {request}) => {
    request.get('/passport/logout')
        .then(() => {
            dispatch({type: 'state.frameworks', payload: {user: {}}});
            dispatch(push('/passport'));
        })
}

export const changePassword = () => {
    let password = ''
    const valueChange = e => {
        const {value} = e.target;
        modal.update({
            okButtonProps: {
                disabled: !value,
            }
        });
        password = value;
    }
    const modal = Modal.confirm({
        title: '请输入新密码',
        content: <Input.Password placeholder="请输入新密码" onChange={valueChange}/>,
        icon: null,
        onOk: () => {
            return request.patch('/users/info', {name: 'password', value: password})
                .then(({data}) => {
                    if (data.message === 'success') {
                        message.success('修改成功');
                    } else {
                        message.info(JSON.stringify(data));
                    }
                })
        },
        okButtonProps: {
            disabled: true,
        }
    })
}

export const handleRedirect = url => dispatch => {
    if (url) {
        if (url.startsWith('http')) {
            window.open(url);
        } else {
            dispatch(push(url))
        }
    }
}

export const beforeAvatarUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('只能上传png/jpg格式图片!');
    }
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
        message.error('头像尺寸最大20M!');
    }
    return isJpgOrPng && isLt2M;
}

export const useCustomRequest = (pathname, options) => {
    return useRequest(() => request(pathname), options);
}

