import {Modal, message} from 'antd';

const update = payload => ({type: 'state.manage.insect.morphology.detail', payload});

export const loadData = ({id}) => (dispatch, getState, {request}) => {
    request.get('/insect/morphology', {params: {id}})
        .then(({data}) => {
            dispatch(update({data: data[0] || {}}));
        });
};

export const loadDetail = ({id}) => (dispatch, getState, {request}) => {
    request.get(`/insect/morphology/${id}/item`)
        .then(({data}) => {
            dispatch(update({listData: data}));
        });
};

export const appendItem = () => (dispatch, getState, {request}) => {
    const {showAppend, appendData, data} = getState().manage.insect.morphology.detail;
    if (showAppend) {
        if (appendData.id) {
            Modal.confirm({
                title: `确认给${appendData.specificNameChinese}(${appendData.latinName})添加${data.name}形态吗?`,
                onOk: () => {
                    return request.post(`/users/insect/morphology/${data.id}/insect/${appendData.id}`)
                        .then(() => {
                            message.success('添加成功');
                            dispatch(update({showAppend: false}));
                            dispatch(loadDetail({id: data.id}));
                        });
                }
            });
        }
        return;
    }
    dispatch(update({showAppend: true, appendData: {}}));
};

export const selectedData = (appendData) => {
    return update({appendData});
};

export const removeInsect = (e, index) => (dispatch, getState, {request}) => {
    e.preventDefault();
    const {data: baseData, listData} = getState().manage.insect.morphology.detail;
    const insectData = listData[index];
    Modal.confirm({
        title: `确认删除${insectData.specificNameChinese}(${insectData.latinName})吗?`,
        onOk: () => request.delete(`/users/insect/morphology/${baseData.id}/insect/${insectData.insectId}`)
            .then(({data}) => {
                if (data) {
                    if (data.message === 'success') {
                        message.success('删除成功');
                        dispatch(loadDetail({id: baseData.id}));
                        return;
                    }
                }
                console.log(data);
                message.error(JSON.stringify(data));
            })
    });
};
