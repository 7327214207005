const update = payload => ({type: 'state.frameworks.insectSelector', payload});

export const toggle = (event) => (dispatch, getState) => {
    if (typeof event === 'boolean') {
        return dispatch(update({show: event}));
    }
    const {show: shown} = getState().frameworks.insectSelector;
    if (!shown && typeof event === 'object') {
        return dispatch(update({show: !shown, target: event.target}));
    }
    return dispatch(update({show: !shown}));
};

export const queryList = query => (dispatch, getState, {request}) => {
    return request.get('/insect/query', {params: {query}});
};

export const queryChange = (e) => (dispatch, getState, {request}) => {
    let {queryTimer} = getState().frameworks.insectSelector;
    const {value} = e.target;
    if (queryTimer) {
        return dispatch(update({query: value}));
    }
    if (value) {
        queryTimer = setTimeout(() => {
            let {query} = getState().frameworks.insectSelector;
            dispatch(update({queryTimer: null}));
            if (!query) {
                return dispatch(update({list: []}));
            }
            dispatch(queryList(query))
                .then(({data: list}) => {
                    dispatch(update({list, showNoData: !list.length, queryMessage: list.length ? '' : '未找到任何数据'}));
                });
        }, 500);
    }
    dispatch(update({query: value, queryTimer}));
};

export const updateSelected = (data) => (dispatch, getState, {request}) => {
    if (typeof data === 'object') {
        return dispatch(update({selected: data}));
    }
    if (typeof data === 'number') {
        const {list} = getState().frameworks.insectSelector;
        const selected = list.find(item => item.id === data);
        if (selected) {
            return dispatch(update({selected}));
        }
        dispatch(queryList(data))
            .then(({data: list}) => {
                if (list && list.length) {
                    return dispatch(update({selected: list[0], list}));
                }
            });
        return;
    }
    dispatch(update({selected: {}}));
};

export const handleSelect = ({target}) => (dispatch, getState) => {
    const index = target.getAttribute('data-index');
    dispatch(update({show: false}));
    if (index) {
        const {list} = getState().frameworks.insectSelector;
        dispatch(updateSelected(list[index]));
        return list[index];
    }
};

export const reset = () => ({type: 'reset.frameworks.insectSelector'});
