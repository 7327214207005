import React from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {Col, DatePicker, Descriptions, Form, Input, Radio, Row, Select} from 'antd';
import {Map} from 'react-amap';
import {taxonomyField} from '../../page/util';
import request from '../../util/request';
import dateUtil from '@/util/date';
import debounce from 'lodash/debounce'
import AmapLayerSwitcher from '../AmapLayerSwitcher';
import ImgListEditor from '../ImgListEditor';
import TaxonomyFieldSelector from '../TaxonomySelector/TaxonomyFieldSelector';
import HostSelector from '../HostSelector';

class Geolocation extends React.Component {
    constructor(props) {
        super(props);
        this.AMap = window.AMap;
    }

    componentDidMount() {
        const {AMap} = this;
        AMap.plugin('AMap.Geolocation', () => {
            const geolocation = new AMap.Geolocation({
                // 是否使用高精度定位，默认：true
                enableHighAccuracy: false,
                // 设置定位超时时间，默认：无穷大
                timeout: 10000,
                // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
                // buttonOffset: new AMap.Pixel(10, 20),
                //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                zoomToAccuracy: true,
                //  定位按钮的排放位置,  RB表示右下
                buttonPosition: 'RB',
                //  优先使用地址位置信息定位
                GeoLocationFirst: true
            });
            this.props.__map__.addControl(geolocation);
            geolocation.getCurrentPosition((status, result) => {
                if (status === 'complete') {
                    this.watchId = geolocation.watchPosition();
                }
            });
            this.geolocation = geolocation;
        });
    }

    componentWillUnmount() {
        this.watchId && this.geolocation.clearWatch(this.watchId);
    }

    render() {
        return null;
    }
}

class PositionPicker extends React.Component {
    constructor(props) {
        super(props);
        this.AMapUI = window.AMapUI;
    }

    componentDidMount() {
        const {AMapUI, props} = this;
        const onPickerChange = props.onPickerChange || (() => {
        });
        AMapUI.loadUI(['misc/PositionPicker'], function (PositionPicker) {
            const positionPicker = new PositionPicker({
                mode: props.mode,//设定为拖拽地图模式，可选'dragMap'、'dragMarker'，默认为'dragMap'
                map: props.__map__//依赖地图对象
            });
            positionPicker.on('success', onPickerChange);
            positionPicker.on('fail', function(positionResult) {
                // 海上或海外无法获得地址信息
                console.log(positionResult, 'fail');
            });
            positionPicker.start();
            props.__map__.setFitView();
        });
    }

    render() {
        return null;
    }
}

class CollectEditor extends React.Component {
    constructor(props) {
        super(props);
        this.formItemLayout = {
            labelCol: {
                xs: {span: 4},
                lg: {span: 6}
            },
            wrapperCol: {
                xs: {span: 20},
                lg: {span: 18}
            },
        };
        this.handleSearch = debounce(this.doSearch, 500);
        this.state = this.computeState(props);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.setState(this.computeState(this.props));
        }
    }

    computeState = props => {
        const {data, auxiliaryData} = props;
        const fileList = data.images.map((fileName, index) => ({
            uid: index,
            fileName,
            url: `/uploadImg/tiny/${fileName}`
        }));
        let searchList = [];
        let insectData = props.insectData || {};
        let selectedTaxonomy = {};
        if (auxiliaryData.matchMode !== 1) {
            if (auxiliaryData.phylumChinese) {
                selectedTaxonomy.phylumChinese = {key: -1, label: auxiliaryData.phylumChinese}
            }
            if (auxiliaryData.classChinese) {
                selectedTaxonomy.classChinese = {key: -1, label: auxiliaryData.classChinese}
            }
            if (auxiliaryData.orderChinese) {
                selectedTaxonomy.orderChinese = {key: -1, label: auxiliaryData.orderChinese}
            }
            if (auxiliaryData.familyChinese) {
                selectedTaxonomy.familyChinese = {key: -1, label: auxiliaryData.familyChinese}
            }
        }
        switch (auxiliaryData.matchMode) {
            case 1:
                if (data.insectId) {
                    insectData.id = data.insectId;
                    searchList = [insectData]
                }
                break;
            case 3:
                insectData = {id: -1};
                searchList = [{specificNameChinese: auxiliaryData.specificNameChinese, id: -1}];
                break;
            default:
                break;
        }
        let host = {};
        if (data.hostId) {
            host.id = data.hostId;
            host.name = data.hostLatinName;
            host.nameChinese = data.host;
            host.family = data.hostFamily;
            host.familyChinese = data.hostFamilyChinese;
        }
        const state = {
            data,
            auxiliaryData,
            insect: insectData,
            collectTimeObj: data.collectTime ? dateUtil(data.collectTime) : null,
            fileList,
            searchList,
            selectedTaxonomy,
            host
        };
        if (data.lng && data.lat) {
            state.center = [data.lng, data.lat];
        }
        return state;
    };

    onDataChange = ({target}) => this.setState({data: {...this.state.data, [target.name]: target.value}});

    onAuxiliaryDataChange = ({target}) => this.setState({
        auxiliaryData: {
            ...this.state.auxiliaryData,
            [target.name]: target.value
        }
    });

    onMatchModeChange = ({target}) => {
        const data = {...this.state.data};
        delete data.insectId;
        this.setState({
            data,
            auxiliaryData: {[target.name]: target.value},
            selectedTaxonomy: {},
            insect: {}
        });
    }

    onCollectTimeChange = (date, str) => {
        this.setState({data: {...this.state.data, collectTime: str}, collectTimeObj: date});
    };

    onPositionChange = (data) => {
        console.log(data);
        const {info, position, regeocode, address} = data;
        if (info === 'OK') {
            const {province, city, district, township} = regeocode.addressComponent;
            this.setState({
                data: {
                    ...this.state.data,
                    address, lat: position.lat, lng: position.lng,
                    province, city, district, township
                }
            });
        }
    };

    selectInsect = id => {
        const data = {...this.state.data}
        if (id === -1) {
            delete data.insectId
            this.setState({
                data,
                auxiliaryData: {matchMode: 3, specificNameChinese: this.state.searchText},
                insect: {id}
            });
            return;
        }
        this.setState({
            data: {...this.state.data, insectId: id},
            auxiliaryData: {matchMode: 1, insectId: id},
            insect: {id}
        });
        request.get(`/insect/detail/${id}`)
            .then(({data}) => {
                this.setState({insect: data});
            });
    };

    selectHost = (data) => {
        if (data) {
            const item = data;
            const newData = {
                ...this.state.data,
                hostId: item.id,
                host: item.nameChinese,
                hostLatinName: item.name,
                hostFamilyChinese: item.familyChinese,
                hostFamily: item.family
            }
            this.setState({data: newData, host: data});
        } else {
            const newData = {...this.state.data, host: '', hostLatinName: ''};
            delete newData.hostId
            delete newData.hostFamilyChinese
            delete newData.hostFamily
            this.setState({data: newData, host: {}});
        }
    }

    unknownName = () => {
        this.setState({
            auxiliaryData: {
                ...this.state.auxiliaryData,
                matchMode: 2
            }
        })
    };

    fileChange = data => {
        const {fileList} = data;
        console.log(data, data.file.response);
        const images = [];
        for (let i = 0; i < fileList.length; i++) {
            if (fileList[i].fileName) {
                images.push(fileList[i].fileName);
                continue;
            }
            if (fileList[i].response && fileList[i].response.length) {
                images.push(fileList[i].response[0]);
            }
        }
        this.setState({data: {...this.state.data, images}, fileList: data.fileList});
    };

    notFoundName = () => {
        this.setState({
            auxiliaryData: {
                ...this.state.auxiliaryData,
                matchMode: 3
            }
        })
    };

    doSearch = query => {
        this.setState({searchText: query, searching: !!query});
        if (query) {
            request.get('/insect/query', {params: {query}})
                .then(({data}) => {
                    this.setState({searchList: data, searching: false});
                })
        }
    };

    onTaxonomyChange = (type, data) => {
        this.setState({
            auxiliaryData: {
                ...this.state.auxiliaryData,
                [type]: data.label
            },
            selectedTaxonomy: {
                ...this.state.selectedTaxonomy,
                [type]: data
            },
        })
    };

    onImgListEditorChange = images => {
        this.setState({data: {...this.state.data, images}});
    }

    render() {
        const {data, auxiliaryData, insect, searchList, searchText, searching, host} = this.state;
        const {matchMode} = auxiliaryData;
        const isExact = matchMode === 1;
        const matchModeValue = matchMode !== 2 ? 1 : 2;
        const insectData = isExact ? insect : auxiliaryData;
        const onDataChange = this.onDataChange;
        return <Row>
            <Col xs={24} lg={12}>
                <div style={{height: '350px'}}>
                    <Map amapkey={'88369698bd3d994eb5f636508e005f8d'} version={'1.4.15'} center={this.state.center} useAMapUI={true}>
                        {this.props.geolocation && <Geolocation/>}
                        {this.props.positionPicker &&
                        <PositionPicker mode={this.props.geolocation ? 'dragMap' : 'dragMarker'}
                                        onPickerChange={this.onPositionChange}/>}
                        <AmapLayerSwitcher/>
                    </Map>
                </div>
                <Descriptions>
                    <Descriptions.Item label={'采集位置'}>{data.address}</Descriptions.Item>
                </Descriptions>
                <Descriptions>
                    <Descriptions.Item label={'省'}>{data.province}</Descriptions.Item>
                    <Descriptions.Item label={'市'}>{data.city}</Descriptions.Item>
                    <Descriptions.Item label={'区'}>{data.district}</Descriptions.Item>
                    <Descriptions.Item label={'镇'}>{data.township}</Descriptions.Item>
                    <Descriptions.Item label={'纬度'}>{data.lat}</Descriptions.Item>
                    <Descriptions.Item label={'经度'}>{data.lng}</Descriptions.Item>
                </Descriptions>
            </Col>
            <Col xs={24} lg={12}>
                <Form {...this.formItemLayout}>
                    <Form.Item label={'物种名称'}>
                        <Radio.Group value={matchModeValue} onChange={this.onMatchModeChange} name={'matchMode'}>
                            <Radio value={1}>已知名称</Radio>
                            <Radio value={2}>未知名称</Radio>
                        </Radio.Group>
                        {matchModeValue === 1 &&
                        <Select onSearch={this.handleSearch} showSearch filterOption={false}
                                placeholder="请输入科名/物种名称搜索" onChange={this.selectInsect} value={insect.id}>
                            {searchList.map(item =>
                                <Select.Option value={item.id} key={item.id}>{item.specificNameChinese}</Select.Option>
                            )}
                            {searchText && searchList.length === 0 && !searching &&
                            <Select.Option value={-1}>{searchText}</Select.Option>}
                        </Select>}
                    </Form.Item>
                    {(!isExact || insect.id) && <>
                        {taxonomyField.map(({key, name, label}, index) => (
                            <Form.Item key={key} label={label}>
                                {isExact ?
                                    <Input
                                        readOnly
                                        value={insectData[key] || ''}
                                        name={key}
                                        onChange={this.onAuxiliaryDataChange}
                                    /> :
                                    <TaxonomyFieldSelector
                                        type={key}
                                        depth={index}
                                        selected={this.state.selectedTaxonomy}
                                        onChange={this.onTaxonomyChange}
                                    />
                                }
                            </Form.Item>
                        ))}
                    </>}
                    <Form.Item label={'采集人'}>
                        <Input type="text" placeholder="" name={'collectUserName'}
                               value={data.collectUserName || ''} onChange={onDataChange}/>
                    </Form.Item>
                    <Form.Item label={'采集时间'}>
                        <DatePicker
                            showTime value={this.state.collectTimeObj} allowClear={false}
                            onChange={this.onCollectTimeChange}/>
                    </Form.Item>
                    {insectData.classChinese === '昆虫纲' && <>
                        <Form.Item label={'寄主植物'}>
                            <HostSelector data={host} onChange={this.selectHost} allowClear/>
                        </Form.Item>
                        {host.id && <>
                            <Form.Item label={'寄主拉丁名'}>
                                <Input type="text" placeholder="" name="hostLatinName" value={data.hostLatinName}
                                       onChange={onDataChange} readOnly/>
                            </Form.Item>
                            <Form.Item label={'中文科名'}>
                                <Input type="text" placeholder="" name="hostFamilyChinese"
                                       value={data.hostFamilyChinese}
                                       onChange={onDataChange} readOnly/>
                            </Form.Item>
                            <Form.Item label={'拉丁科名'}>
                                <Input type="text" placeholder="" name="hostFamily" value={data.hostFamily}
                                       onChange={onDataChange} readOnly/>
                            </Form.Item>
                        </>}
                    </>}
                    <Form.Item label={'发育阶段'}>
                        <Input type="text" placeholder="" name="insectState" value={data.insectState}
                               onChange={onDataChange}/>
                    </Form.Item>
                    <Form.Item label={'生物学'}>
                        <Input type="text" placeholder="" name="harmWay" value={data.harmWay}
                               onChange={onDataChange}/>
                    </Form.Item>
                    <Form.Item label={'生境类型'}>
                        <Input type="text" placeholder="" name="habitatType" value={data.habitatType}
                               onChange={onDataChange}/>
                    </Form.Item>
                    <Form.Item label={'海拔(m)'}>
                        <Input type="text" placeholder="" name="elevation" value={data.elevation}
                               onChange={onDataChange}/>
                    </Form.Item>
                    <Form.Item label={'物种图像'}>
                        <ImgListEditor images={data.images} onChange={this.onImgListEditorChange} mode={'oss'}>
                            <div>
                                <PlusOutlined/>
                                <div className="ant-upload-text">物种图像上传</div>
                            </div>
                        </ImgListEditor>
                        请使用手机横屏拍摄
                    </Form.Item>
                </Form>
            </Col>
        </Row>;
    }
}

export default CollectEditor;
