import m1 from './images/taxonomy-1/0.png';
import m2 from './images/taxonomy-2/0.png';
import m3 from './images/taxonomy-3/0.png';
import m4 from './images/taxonomy-4/0.png';
import m5 from './images/taxonomy-5/0.png';
import m6 from './images/taxonomy-6/0.png';
import m7 from './images/taxonomy-7/0.png';
import m8 from './images/taxonomy-8/0.png';
import m9 from './images/taxonomy-9/0.png';
import m10 from './images/taxonomy-10/0.png';
import m11 from './images/taxonomy-11/0.png';
import m12 from './images/taxonomy-12/0.png';
import m1Taxonomy from './images/taxonomy-1/1.png';
import m1Morphology from './images/taxonomy-1/3.png';
import m2Taxonomy from './images/taxonomy-2/1.png';
import m2Morphology from './images/taxonomy-2/3.png';
import m3Taxonomy from './images/taxonomy-3/1.png';
import m3Morphology from './images/taxonomy-3/3.png';
import m4Taxonomy from './images/taxonomy-4/1.png';
import m4Morphology from './images/taxonomy-4/3.png';
import m5Taxonomy from './images/taxonomy-5/1.png';
import m5Morphology from './images/taxonomy-5/3.png';
import m6Taxonomy from './images/taxonomy-6/1.png';
import m6Host from './images/taxonomy-6/2.png';
import m6Morphology from './images/taxonomy-6/3.png';
import m7Taxonomy from './images/taxonomy-7/1.png';
import m7Morphology from './images/taxonomy-7/3.png';
import m8Taxonomy from './images/taxonomy-8/1.png';
import m8Morphology from './images/taxonomy-8/3.png';
import m9Taxonomy from './images/taxonomy-9/1.png';
import m9Morphology from './images/taxonomy-9/3.png';
import m10Taxonomy from './images/taxonomy-10/1.png';
import m10Morphology from './images/taxonomy-10/3.png';
import m11Taxonomy from './images/taxonomy-11/1.png';
import m11Morphology from './images/taxonomy-11/3.png';
import m12Taxonomy from './images/taxonomy-12/1.png';
import m12Morphology from './images/taxonomy-12/3.png';

const config = [null, {
    img: m1,
    name: '鸟纲',
    search: [{img: m1Taxonomy, type: 'taxonomy'}, {img: m1Morphology, type: 'morphology'}],
    route: {
        pathname: '/insect/taxonomy/class/449/order',
    }
}, {
    img: m2,
    name: '哺乳纲',
    search: [{img: m2Taxonomy, type: 'taxonomy'}, {img: m2Morphology, type: 'morphology'}],
    route: {
        pathname: '/insect/taxonomy/class/446/order',
    }
}, {
    img: m3,
    name: '爬行纲',
    search: [{img: m3Taxonomy, type: 'taxonomy'}, {img: m3Morphology, type: 'morphology'}],
    route: {
        pathname: '/insect/taxonomy/class/447/order',
    }
}, {
    img: m4,
    name: '两栖纲',
    search: [{img: m4Taxonomy, type: 'taxonomy'}, {img: m4Morphology, type: 'morphology'}],
    route: {
        pathname: '/insect/taxonomy/class/445/order',
    }
}, {
    img: m5,
    name: '鱼纲',
    search: [{img: m5Taxonomy, type: 'taxonomy'}, {img: m5Morphology, type: 'morphology'}],
    route: {
        pathname: '/insect/taxonomy/class/448/order',
    }
}, {
    img: m6,
    name: '昆虫纲',
    search: [{img: m6Taxonomy, type: 'taxonomy'}, {img: m6Host, type: 'host'}, {img: m6Morphology, type: 'morphology'}],
    route: {
        pathname: '/insect/taxonomy/class/443/order',
    }
}, {
    img: m8,
    name: '蛛形纲',
    search: [{img: m8Taxonomy, type: 'taxonomy'}, {img: m8Morphology, type: 'morphology'}],
    route: {
        pathname: '/insect/taxonomy/class/455/order',
    }
}, {
    img: m9,
    name: '内口纲',
    search: [{img: m9Taxonomy, type: 'taxonomy'}, {img: m9Morphology, type: 'morphology'}],
    route: {
        pathname: '/insect/taxonomy/class/458/order',
    }
}, {
    img: m10,
    name: '软甲纲',
    search: [{img: m10Taxonomy, type: 'taxonomy'}, {img: m10Morphology, type: 'morphology'}],
    route: {
        pathname: '/insect/taxonomy/class/450/order',
    }
}, {
    img: m11,
    name: '倍足纲',
    search: [{img: m11Taxonomy, type: 'taxonomy'}, {img: m11Morphology, type: 'morphology'}],
    route: {
        pathname: '/insect/taxonomy/class/456/order',
    }
}, {
    img: m12,
    name: '唇足纲',
    search: [{img: m12Taxonomy, type: 'taxonomy'}, {img: m12Morphology, type: 'morphology'}],
    route: {
        pathname: '/insect/taxonomy/class/457/order',
    }
}, {
    img: m7,
    name: '其他无脊椎动物',
    search: [{img: m7Taxonomy, type: 'taxonomy'}, {img: m7Morphology, type: 'morphology'}],
    route: {
        pathname: '/insect/taxonomy/phylum',
        search: '?exclude=442,444'
    }
}]

export default config;
