import {push} from 'connected-react-router';
import {message, Modal} from 'antd';
import {props} from 'ramda';
import {optionFields} from '../../../component/FilterCascader';

const updateFilter = payload => ({type: 'state.manage.insect.special.filter', payload});
const updateFilterOptions = payload => ({type: 'state.manage.insect.special.filterOptions', payload});

const update = payload => ({type: 'state.manage.insect.special', payload});
const updateEdit = payload => ({type: 'state.manage.insect.special.edit', payload});

export const newData = () => ({type: 'state.manage.insect.special.new'});

export const hiddenEdit = () => update({showEdit: false});

export const inputChange = ({target}) => {
    return updateEdit({[target.name]: target.value});
};

export const fileChange = (data) => dispatch => {
    const {fileList} = data;
    console.log(data, data.file.response);
    const images = [];
    for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].fileName) {
            images.push(fileList[i].fileName);
            continue;
        }
        if (fileList[i].response && fileList[i].response.length) {
            images.push(fileList[i].response[0]);
        }
    }
    dispatch(updateEdit({images}));
    dispatch(update({fileList: data.fileList}));
};

export const getList = () => (dispatch, getState, {request}) => {
    request.get('/insect/special')
        .then(({data}) => {
            data.forEach(item => {
                item.images = item.images ? item.images.split(',') : [];
                try {
                    item.city = JSON.parse(item.city);
                } catch (e) {
                    item.city = {};
                }
            });
            dispatch(update({listData: data}));
        });
};

export const save = (formData) => (dispatch, getState, {request}) => {
    const {id} = getState().manage.insect.special.edit;
    const {images: fileList, filter, ...data} = formData;
    const images = [];
    for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].fileName) {
            images.push(fileList[i].fileName);
            continue;
        }
        if (fileList[i].response && fileList[i].response.length) {
            images.push(fileList[i].response[0]);
        }
    }
    const {taxonomyMulti} = filter;
    const convertFilterData = {};
    (taxonomyMulti ? ['region', 'host'] : ['taxonomy', 'region', 'host']).forEach(type => {
        const optionField = optionFields[type];
        let params;
        if (filter[type] && filter[type].length) {
            params = {};
            filter[type].forEach((item, index) => {
                params[optionField[index]] = item;
            });
            convertFilterData[type] = params;
        } else if (typeof filter[type] === 'boolean') {
            convertFilterData[type] = filter[type];
        }
    })
    if (filter.address) {
        convertFilterData.address = filter.address;
    }
    if (filter.tag) {
        convertFilterData.tag = filter.tag;
    }
    if (filter.insectId) {
        convertFilterData.insectId = filter.insectId;
    }
    if (filter.taxonomyMulti) {
        convertFilterData.taxonomy = {[optionFields.taxonomy[0]]: filter.taxonomy.map(item => item[0])}
        convertFilterData.taxonomyMulti = filter.taxonomyMulti;
    }
    data.images = images.join(',');
    data.filter = JSON.stringify(convertFilterData);
    (id ? request.patch(`/users/insect/special/${id}`, data) : request.post('/users/insect/special', data))
        .then(({data}) => {
            dispatch(getList());
            dispatch(update({showEdit: false}));
        });
};

export const toDetail = (data) => dispatch => {
    dispatch({type: 'state.manage.insect.special.detail', payload: {data}});
    dispatch(push(`${window.location.pathname}/${data.id}`));
};

export const editRow = (data) => dispatch => {
    const fileList = data.images.map((fileName, index) => ({
        uid: index,
        fileName,
        url: `/uploadImg/${fileName}`
    }));
    const addressValue = props(optionFields.address, data.city).filter(item => item);
    const filter = {};
    Object.keys(data.filter).forEach(type => {
        if (data.filter[type] === false) {
            filter[type] = false;
            return;
        }
        if (type === 'taxonomy' && data.filter.taxonomyMulti) {
            filter[type] = data.filter[type][optionFields[type][0]].map(item => [item]);
        } else if (type !== 'address' && optionFields[type]) {
            filter[type] = props(optionFields[type], data.filter[type]).filter(item => item);
        } else {
            filter[type] = data.filter[type];
        }
    })
    dispatch(update({showEdit: true, edit: {...data, images: fileList, filter}, addressValue}));
};

export const deleteRow = data => (dispatch, getState, {request}) => {
    Modal.confirm({
        title: `确认删除${data.name}专题吗`,
        onOk: () => {
            return request.delete(`/users/insect/special/${data.id}`)
                .then(({data}) => {
                    if (data) {
                        if (data.message === 'success') {
                            message.success('删除成功');
                            dispatch(getList());
                            return;
                        }
                    }
                    console.log(data);
                    message.error(JSON.stringify(data));
                });
        }
    });
};

export const loadOptionsData = (type, selectedOptions) => (dispatch, getState, {request}) => {
    const optionField = optionFields[type];
    const depth = selectedOptions.length;
    const isLeaf = depth >= optionField.length - 2;
    const params = {type, data: {data: 1}};
    let targetOption;
    if (depth) {
        targetOption = selectedOptions[depth - 1];
        targetOption.loading = true;
    }
    selectedOptions.forEach((item, index) => {
        params[optionField[index]] = item.valueData;
    });
    request('/insect/analyse/filter', {params})
        .then(({data}) => {
            const options = data.map((item, index) => {
                const valueData = item[optionField[depth]];
                return ({
                    valueData,
                    value: [type, depth, valueData || index].join('-'),
                    label: valueData || '-',
                    isLeaf
                });
            });
            if (targetOption) {
                targetOption.loading = false;
                targetOption.children = options;
                dispatch(updateFilterOptions({}));
            } else {
                dispatch(updateFilterOptions({[type]: options}));
            }
        });
};

export const cascaderChange = (field, status) => (dispatch, getState) => {
    const state = getState().manage.insect.special.filterOptions;
    if (status && state[field].length === 0) {
        dispatch(loadOptionsData(field, []));
    }
};

export const filterChange = (type, value, selectedOptions) => (dispatch, getState) => {
    const optionField = optionFields[type];
    let params;
    if (selectedOptions.length) {
        params = {};
        selectedOptions.forEach((item, index) => {
            params[optionField[index]] = item.valueData;
        });
    }
    dispatch(updateFilter({[type]: params}));
    dispatch(updateEdit({city: params}));
};
