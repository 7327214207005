import React from 'react';
import {connect} from 'react-redux';
import IndexLayout from '../../../IndexLayout';
import {Card, Carousel, Form, Spin, Typography} from 'antd';

import * as actions from './actions';
import {Col, Container, Row} from 'react-bootstrap';
import StatisticView from '../../../../component/StatisticView';
import FilterCascader from '../../../../component/FilterCascader';
import InfiniteScroll from 'react-infinite-scroller';
import DetailProvider from './provider';

const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 14}
};

class Detail extends React.Component {
    componentDidMount() {
        this.props.setRouterFilter(this.props.history.location.state);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {match: {params}, data, itemData, itemRouterFilter} = this.props;
        if ((data.id !== prevProps.data.id || itemRouterFilter !== prevProps.itemRouterFilter) && +params.id === data.id) {
            this.props.loadAnalyseData(params);
            if (itemData.rows.length === 0) {
                this.props.loadSpecialDetail(params);
            }
        }
    }

    render() {
        const {props} = this;
        const {insectAnalyse} = props;
        const taxonomyInitialFilter = props.itemTaxonomy || (props.data.filter && props.data.filter.taxonomy);
        return <IndexLayout>
            <DetailProvider match={props.match}/>
            <Container>
                <Row>
                    <Col>
                        <Typography.Title level={4}>
                            {props.data.name}
                        </Typography.Title>
                    </Col>
                </Row>
                <StatisticView sumData={insectAnalyse.sumData}/>
                {props.data.filter && <Row className={'mt-3'}>
                    <Col>
                        <Form {...formItemLayout} onValuesChange={props.onFilterChange}>
                            <Form.Item label={'分类学过滤'} name={'taxonomy'}>
                                <FilterCascader
                                    type={'taxonomy'}
                                    initialFilter={taxonomyInitialFilter}
                                />
                            </Form.Item>
                            {!props.data.filter.address && <Form.Item label={'地理位置过滤'} name={'address'}>
                                <FilterCascader type={'address'}/>
                            </Form.Item>}
                            {(props.data.filter.host !== false && !props.data.filter.host) &&
                            <Form.Item label={'寄主过滤'} name={'host'}>
                                <FilterCascader type={'host'}/>
                            </Form.Item>}
                        </Form>
                    </Col>
                </Row>}
                <InfiniteScroll
                    className={'row-cols-3 row'}
                    loadMore={props.loadMore}
                    initialLoad={false}
                    hasMore={props.itemData.more}
                    loader={<div className="col-12 text-center py-5" key={0}><Spin/></div>}
                >
                    {props.itemData.rows.map((data) => {
                        const {insectId, latinName, specificNameChinese, images} = data;
                        return <Col key={insectId}>
                            <Card
                                onClick={() => props.toInsectDetail(insectId, props.data.id)}
                                cover={
                                    <Carousel dots={false} autoplay={true}>
                                        {images.map((name, index) => (
                                            <img
                                                key={index}
                                                alt={name}
                                                src={`/uploadImg/tiny/${name}`}
                                            />
                                        ))}
                                    </Carousel>
                                }
                            >
                                {specificNameChinese} <span className={'font-italic'}>{latinName}</span>
                            </Card>
                        </Col>;
                    })}
                </InfiniteScroll>
            </Container>
        </IndexLayout>;
    }
}

const InsectSpecialDetail = connect(state => state.insect.special.detail, actions)(Detail);
export default InsectSpecialDetail;
