import {push} from 'connected-react-router';

const update = payload => ({type: 'state.insect.special', payload});

export const getList = () => (dispatch, getState, {request}) => {
    request.get('/insect/special')
        .then(({data}) => {
            data.forEach(item => {
                item.images = item.images ? item.images.split(',') : [];
                try {
                    item.city = JSON.parse(item.city);
                } catch (e) {
                    item.city = {};
                }
            });
            dispatch(update({listData: data}));
        });
};

export const toDetail = (data) => dispatch => {
    dispatch(push(`/insect/special/${data.id}/${data.showMode === 1 ? 'taxonomy/' : ''}`));
};
