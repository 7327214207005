import {message} from 'antd';

const update = payload => ({type: 'state.manage.insect.morphology.edit', payload})
const updateCurrent = payload => ({type: 'state.manage.insect.morphology.edit.currentArea', payload})
const updateArea = payload => ({type: 'state.manage.insect.morphology.edit.area', payload})
export const fileChange = data => dispatch => {
    const {fileList} = data;
    dispatch(update({fileList}));
    if (fileList[0].response && fileList[0].response.length) {
        dispatch(update({images: [fileList[0].response[0]], fileList: []}))
    }
};

export const areaFileChange = data => dispatch => {
    const {fileList} = data;
    dispatch(update({areaFileList: fileList}));
    if (fileList[0].response && fileList[0].response.length) {
        dispatch(update({areaFileList: []}))
        dispatch(updateCurrent({image: fileList[0].response[0]}))
    }
};

export const editChange = ({target}) => dispatch => {
    dispatch(updateCurrent({[target.name]: target.value}))
};
export const currentAreaChange = area => updateCurrent({area});
export const areaChange = staticArea => updateArea({static: staticArea});

export const changeType = ({target}) => (dispatch, getState) => {
    if (target.value === 'box') {
        dispatch(updateCurrent({borderRadius: undefined}));
    } else {
        dispatch(updateCurrent({borderRadius: '50'}));
    }
}

export const addArea = () => ({type: 'state.manage.insect.morphology.edit.new'});

export const saveArea = () => (dispatch, getState) => {
    const state = getState().manage.insect.morphology.edit;
    const {width, height, left, top, rotate, borderRadius, ...other} = state.currentArea;
    const style = {
        width: `${width}%`,
        height: `${height}%`,
        left: `${left}%`,
        top: `${top}%`
    }
    if (rotate) {
        style.transform = `rotate(${rotate}deg)`;
    }
    if (borderRadius) {
        style.borderRadius = `${borderRadius}%`;
    }
    dispatch(update({currentArea: null}));
    dispatch(updateArea({list: [{...other, style}].concat(state.area.list)}))
}

export const loadAreaList = () => (dispatch, getState, {request}) => {
    request.get('/users/insect/morphology/area')
        .then(({data}) => {
            dispatch(update({areaList: data}))
        })
}

export const saveData = () => (dispatch, getState, {request}) => {
    const data = getState().manage.insect.morphology.edit;
    const {currentArea, area, images} = data;
    if (currentArea) {
        message.info('请先完成区域编辑');
        return;
    }
    if (images.length === 0) {
        message.info('请上传图片');
        return;
    }
    const body = {
        images: images.join(','),
        area: JSON.stringify(area)
    }
    const req = data.id ?
        request.put(`/users/insect/morphology/search/${data.id}`, body) :
        request.post('/users/insect/morphology/search', body)
    req.then(({data}) => {
        if (data) {
            if (data.message === 'success') {
                message.success('保存成功');
                window.history.back()
                return;
            }
        }
        message.error(JSON.stringify(data));
    })
}

export const reset = () => ({type: 'reset.manage.insect.morphology.edit'});

export const removeArea = index => (dispatch, getState) => {
    const state = getState().manage.insect.morphology.edit;
    const list = [...state.area.list];
    list.splice(index, 1);
    dispatch(updateArea({list}));
}

export const showArea = index => update({activeIndex: index});
