import {goBack, push} from 'connected-react-router';
import {showMessage} from '../../component/MessageAlert';
import {message, Modal} from 'antd';
import {loadAccessCount} from '../../framework/actions';

const update = payload => ({type: 'action.passport', payload});

const handleLoginResult = ({data: {message, user}}) => dispatch => {
    dispatch(update({loading: false}));
    switch (message) {
        case 'loginName error':
            return dispatch(showMessage({message: '用户名错误'}));
        case 'password error':
            return dispatch(showMessage({message: '密码错误'}));
        case 'block':
            return dispatch(showMessage({message: '请求来源被禁，请稍后再试'}));
        case 'wait':
            return dispatch(showMessage({message: '该账号尚未通过审核，请耐心等待'}));
        default:
            return dispatch(showMessage({message}));
        case 'success':
        case undefined:
            dispatch({type: 'state.frameworks', payload: {user}});
            dispatch(push('/'));
            dispatch(loadAccessCount());
            break;
    }
};

export const login = data => (dispatch, getState, {request}) => {
    const {telephone, password} = data;
    if (!telephone) {
        return dispatch(showMessage({message: '请输入手机号'}));
    }
    if (!password) {
        return dispatch(showMessage({message: '请输入密码'}));
    }
    request.post('/passport/basic', {telephone, password})
        .then(result => dispatch(handleLoginResult(result)));
};

export const register = data => (dispatch, getState, {request}) => {
    const {acceptProtocol} = getState().passport;
    const {passwordConfirm, ...registerData} = data;
    const {telephone, code, password, name, avatar, address, workplace, email} = registerData;

    if (!acceptProtocol) {
        return message.info('请阅读并同意协议');
    }
    if (!telephone) {
        return message.info('请输入电话号码');
    }
    if (!code) {
        return message.info('请输入验证码');
    }
    if (!password) {
        return message.info('请输入密码');
    }
    if (password !== passwordConfirm) {
        return message.info('密码不一致');
    }
    if (!name) {
        return message.info('请输入姓名');
    }
    if (!address) {
        return message.info('请输入通讯地址');
    }
    if (!workplace) {
        return message.info('请输入工作单位');
    }
    if (!email) {
        return message.info('请输入邮箱');
    }
    if (!avatar || avatar.length === 0) {
        return message.info('请上传头像');
    }
    request.put('/passport/basic', registerData)
        .then(({data}) => {
            if (data.message === 'success') {
                dispatch({type: 'state.frameworks', payload: {user: {name, id: data.id, permission: {}}}});
                message.success('注册成功');
                dispatch(push('/'));
                return;
            }
            if (data.message === 'exist') {
                return message.error('手机号已注册');
            }
            if (data.message === 'wait') {
                return Modal.info({
                    title: '注册申请已提交', content: '请耐心等待管理员审核', onOk: () => {
                        window.history.back()
                    }
                })
            }
            message.info(JSON.stringify(data));
        });
};

export const toRegister = () => push('/passport/register');

export const acceptProtocolChange = ({target}) => update({
    showProtocol: target.checked,
    acceptProtocol: target.checked
});

export const closeProtocol = () => update({showProtocol: false});

export const rejectProtocol = () => update({showProtocol: false, acceptProtocol: false});

export const sendPhoneCode = (telephone, type) => (dispatch, getState, {request}) => {
    request.post(`/passport/${type}/sms`, {telephone})
        .then(({data}) => {
            if (data.message === 'success') {
                let phoneCodeWait = 60;
                dispatch(update({phoneCodeWait}));
                const interval = setInterval(() => {
                    phoneCodeWait -= 1;
                    dispatch(update({phoneCodeWait}));
                    if (!phoneCodeWait) clearInterval(interval);
                }, 1000);
                return;
            }
            return message.error(data.message || data);
        })
}

export const resetPassword = data => (dispatch, getState, {request}) => {
    const {passwordConfirm, ...otherData} = data;
    const {telephone, code, password} = otherData;
    if (!telephone) {
        return message.info('请输入电话号码');
    }
    if (!code) {
        return message.info('请输入验证码');
    }
    if (!password) {
        return message.info('请输入密码');
    }
    if (password !== passwordConfirm) {
        return message.info('密码不一致');
    }
    request.post('/passport/reset-password', otherData)
        .then(({data}) => {
            if (data.message === 'success') {
                message.success('密码已重置');
                dispatch(goBack());
                return;
            }
            message.info(JSON.stringify(data));
        })
}
