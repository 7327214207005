import React from 'react';

const Literature = item => {
    if (item.type === 'monograph') {
        return (<>
            {item.author}, {item.year}, {item.title}, {item.volume}, {item.periodical}, {item.pageSum}pp,
            {item.page && '本种页码'} {item.page}.
        </>)
    }
    return (<>
        {item.author}, {item.year}, {item.title}, {item.periodical}, {item.volume}, {item.pageSum}.
    </>)
};

export default Literature;
