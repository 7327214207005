import React from 'react';
import IndexLayout from '../../../IndexLayout';
import {Col, Container, Row} from 'react-bootstrap';
import {Card, Carousel, Spin} from 'antd';
import {connect} from 'react-redux';
import * as actions from './actions';
import InfiniteScroll from 'react-infinite-scroller';
import TaxonomyTitle from '../TaxonomyTitle';

class TaxonomyFamily extends React.Component {
    componentDidMount() {
        const {match: {params}, getFamilyInfo, getList, parentData: currentFamily} = this.props;
        const task = [];
        if (currentFamily.id !== +params.familyId) {
            task.push(getFamilyInfo(params));
        }
        Promise.all(task)
            .then(() => {
                getList();
            });
    }

    componentWillUnmount() {
        this.props.resetPage();
    }

    render() {
        const {props} = this;
        const {insectData} = props;
        return (
            <IndexLayout>
                <Container>
                    <Row>
                        <Col>
                            <TaxonomyTitle
                                history={props.history}
                                type={'order'}
                                subType={'family'}
                                parentData={props.parentData}
                                parentPath={props.parentPath}
                                depth={4}
                            />
                        </Col>
                    </Row>
                    <InfiniteScroll
                        className={'row-cols-3 row'}
                        loadMore={props.loadMore}
                        initialLoad={false}
                        hasMore={insectData.rows.length < insectData.total}
                        loader={<div className="col-12 text-center py-5" key={0}><Spin/></div>}
                    >
                        {insectData.rows.map((data) => {
                            const {id, specificNameChinese, latinName, images} = data;
                            return <Col key={id}>
                                <Card
                                    onClick={() => props.toInsectDetail(data)}
                                    cover={
                                        <Carousel>
                                            {images.map((name, index) => (
                                                <div className={'img-4-3-wrapper'} key={index}>
                                                    <img
                                                        alt={name}
                                                        src={`/uploadImg/tiny/${name}`}
                                                    />
                                                </div>
                                            ))}
                                        </Carousel>
                                    }
                                >
                                    <p className={'h5'}>{specificNameChinese} <span
                                        className={'font-italic'}>{latinName}</span></p>
                                </Card>
                            </Col>;
                        })}
                    </InfiniteScroll>
                </Container>
            </IndexLayout>
        );
    }
}

const InsectTaxonomyFamily = connect(state => state.insect.taxonomy, actions)(TaxonomyFamily);

export default InsectTaxonomyFamily;
