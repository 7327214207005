const initialData = {
    rows: [],
    total: 0,
    query: {
        offset: 0,
        limit: 10
    },
    loading: false
}

const search = (state = initialData, {type, payload}) => {
    if (type === 'state.manage.insect.morphology.search') {
        return {...state, ...payload};
    }
    if (type === 'state.manage.insect.morphology.search.query') {
        return {...state, query: {...state.query, ...payload}};
    }
    return state
}

export default search;
