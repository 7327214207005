const initialData = {
    info: {},
    insectAnalyse: {},
    grant: null,
    permission: {}
};

const detail = (state = initialData, {type, payload}) => {
    if (type === 'state.manage.user.detail') {
        return {...state, ...payload};
    }
    return state;
};

export default detail;
