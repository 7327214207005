const update = payload => ({type: 'action.insect.detail', payload});

export const loadInsect = ({id, filter}) => (dispatch, getState, {request}) => {
    const params = {}
    if (filter) {
        if (filter.region) {
            Object.assign(params, filter.region);
        }
        if (filter.host) {
            Object.assign(params, filter.host);
        }
    }
    request.get(`/insect/detail/${id}`, {params})
        .then(({data}) => {
            if (data.message) return;
            const markers = [];
            const hostObj = {};
            data.collect.forEach(({lat, lng, host, hostLatinName, hostFamily, hostFamilyChinese, id}, index) => {
                markers.push({
                    position: {latitude: lat, longitude: lng},
                    index,
                    id
                });
                const family = `${hostFamilyChinese} ${hostFamily}`;
                const hostName = `${host} ${hostLatinName}`;
                if (family.length > 3 && hostName.length > 3) {
                    if (!hostObj[family]) {
                        hostObj[family] = {};
                    }
                    hostObj[family][hostName] = 1;
                }
            });
            data.markers = markers;
            data.hostList = Object.keys(hostObj).map(family => ({family, nameList: Object.keys(hostObj[family])}));
            dispatch({type: 'action.insect.detail', payload: data || {}});
        });
    request.get(`/literature/query`, {params: {insectId: id, sort: 'year', order: 'asc'}})
        .then(({data}) => dispatch({type: 'action.insect.detail', payload: {literature: data}}));
};

export const clickMarker = (marker) => (dispatch, getState) => {
    const {activeMarker} = getState().insect.detail;
    activeMarker && (activeMarker.getContent().firstElementChild.src = '//webapi.amap.com/theme/v1.3/markers/n/mark_bs.png');
    const {index: activeIndex} = marker.getExtData();
    marker.getContent().firstChild.src = '//webapi.amap.com/theme/v1.3/markers/n/mark_rs.png';
    dispatch(update({activeMarker: marker, activeIndex}));
};

export const clearSelect = () => (dispatch, getState) => {
    const {activeMarker} = getState().insect.detail;
    activeMarker && (activeMarker.getContent().firstElementChild.src = '//webapi.amap.com/theme/v1.3/markers/n/mark_bs.png');
    dispatch(update({activeMarker: undefined, activeIndex: undefined}))
};
