import React from 'react';
import {Alert} from 'react-bootstrap';

import './message.css';
import {connect} from 'react-redux';
import {hideMessage} from './actions';

const messageContainer = props => {
    return <div className="messageContainer">
        {props.messageList.map(({key, type, message, show}) =>
            <Alert dismissible variant={type} key={key} show={show} onClose={() => props.hideMessage(key)}>
                {message}
            </Alert>
        )}
    </div>;
};

const MessageAlert = connect(state => state.frameworks, {hideMessage})(messageContainer);

export * from './actions';

export default MessageAlert;
