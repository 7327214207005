import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './util/date';
import zhCN from 'antd/locale/zh_CN';
import {Provider} from 'react-redux';
import {ConnectedRouter} from "connected-react-router";
import store, {history} from './store';
import Router from './router';
import MessageAlert from './component/MessageAlert';
import {ConfigProvider, message} from 'antd';
import Framework from './framework';
import {setMessage} from './util/request';

const App = () => {
    const [messageApi, contextHolder] = message.useMessage();
    setMessage(messageApi);
    return <Provider store={store}>
        <ConfigProvider locale={zhCN}>
            <Framework>
                {contextHolder}
                <MessageAlert/>
                <ConnectedRouter history={history}>
                    <Router history={history}/>
                </ConnectedRouter>
            </Framework>
        </ConfigProvider>
    </Provider>;
};

export default App;
