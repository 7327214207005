import React from 'react';
import {connect} from 'react-redux';
import {Card, Carousel} from 'antd';
import IndexLayout from '../../IndexLayout';
import * as actions from './actions';
import {Col, Container, Row} from 'react-bootstrap';

class Special extends React.Component {
    componentDidMount() {
        this.props.getList();
    }

    render() {
        const {props} = this;
        const content = <Row className={'row-cols-3'}>
            {props.listData.map((data) => {
                const {id, name, images} = data;
                return <Col key={id}>
                    <Card
                        onClick={() => props.toDetail(data)}
                        cover={
                            <Carousel>
                                {images.map((name, index) => (
                                    <img
                                        key={index}
                                        alt={name}
                                        src={`/uploadImg/${name}`}
                                    />
                                ))}
                            </Carousel>
                        }
                    >
                        <p className={'h5'}>{name}</p>
                    </Card>
                </Col>;
            })}
        </Row>
        if (props.inner) {
            return content;
        }
        return <IndexLayout>
            <Container>
                {content}
            </Container>
        </IndexLayout>;
    }
}

const InsectSpecial = connect(state => state.insect.special, actions)(Special);

export default InsectSpecial;
