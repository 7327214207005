import loadable from '@loadable/component';

const SpeciesLayout = loadable(() => import(/* webpackChunkName: "SpeciesLayout" */'./species'));
const ProvinceLayout = loadable(() => import(/* webpackChunkName: "ProvinceLayout" */'./province'));
const CsldiasLayout = loadable(() => import(/* webpackChunkName: "CsldiasLayout" */'./csldias'));

let IndexLayout = SpeciesLayout;

switch (process.env.REACT_APP_ENV) {
    case 'province':
        IndexLayout = ProvinceLayout;
        break;
    case 'csldias':
        IndexLayout = CsldiasLayout;
        break;
    default:
}

export default IndexLayout;
