import React, {forwardRef} from 'react';
import {Button, Form, Input, message, Modal} from 'antd';
import {useRequest} from 'ahooks-v2';
import request from '../../../util/request';
import {useSelector} from 'react-redux';

const hostField = [
    {name: 'nameChinese', label: '中文名', rules: [{required: true}]},
    {name: 'name', label: '拉丁名', rules: [{required: true}]},
    {name: 'familyChinese', label: '中文科名', rules: [{required: true}]},
    {name: 'family', label: '拉丁科名', rules: [{required: true}]},
];

const HostEdit = forwardRef((props, ref) => {
    return <Form {...props} ref={ref}>
        {hostField.map(item => (
            <Form.Item {...item} key={item.name}><Input/></Form.Item>
        ))}
    </Form>
});

export const hostEdit = (params) => {
    const submitRow = useRequest((data) => ({url: '/users/insect/host', data, method: 'PUT'}), {
        requestMethod: (param) => request(param),
        formatResult: ({data}) => data,
        manual: true
    });
    const user = useSelector(state => state.frameworks.user);
    const edit = (data) => {
        let form;
        Modal.confirm({
            title: '寄主',
            icon: null,
            content: <HostEdit
                ref={e => form = e}
            />,
            onOk: () => {
                if (form) {
                    return form.validateFields()
                        .then(values => {
                            return submitRow.run(values)
                                .then(data => {
                                    params && params.onSuccess && params.onSuccess({...values, id: data.id});
                                });
                        })
                }
            }
        })
    }
    const create = () => {
        if (user.permission.hostAdd) {
            edit();
        } else {
            message.error('没有权限操作');
        }
    };
    return [{edit, create}];
}

export const AddHost = props => {
    const [{create: showCreate}] = hostEdit();
    return <Button {...props} onClick={showCreate}>{props.children}</Button>
}

