import React from "react";
import IndexLayout from "../../IndexLayout";
import {Button, Input, Modal, Table} from "antd";
import {PageHeader} from '@ant-design/pro-components';
import {connect} from "react-redux";
import LiteratureView from '../../../component/Literature';
import * as actions from './actions';
import LiteratureEdit, {AddLiterature} from './edit';

class Literature extends React.Component {
    componentDidMount() {
        this.props.loadData();
    }

    editLiterature = (data) => {
        let form;
        Modal.confirm({
            title: '文献',
            icon: null,
            content: <LiteratureEdit
                ref={e => form = e}
                initialValues={data}
            />,
            onOk: () => {
                if (form) {
                    return form.validateFields()
                        .then(values => {
                            return this.props.submitRow(values, data);
                        })
                }
            }
        })
    }

    columns = [{
        title: '文献类型',
        dataIndex: 'type',
        render(text) {
            return text === 'monograph' ? '专著' : '期刊'
        }
    }, {
        title: '文献内容',
        dataIndex: 'author',
        render(text, record) {
            return <LiteratureView {...record}/>
        }
    }, {
        title: 'Action',
        dataIndex: 'action',
        render: (text, record) => {
            return (
                <span>
                    <Button type="link" onClick={() => this.editLiterature(record)}>编辑</Button>
                    <Button type="link" onClick={() => this.props.toDetail(record)}>详情</Button>
                    <Button type="link" onClick={() => this.props.deleteRow(record)}>删除</Button>
                </span>
            )
        }
    }];

    render() {
        const {props} = this;
        const {query} = props;
        return <IndexLayout>
            <PageHeader title={'文献管理'} extra={
                <>
                    <Input.Search
                        placeholder="输入标题搜索" defaultValue={query.search} onSearch={props.handleSearch}
                        enterButton style={{width: 'auto'}}
                    />
                    <AddLiterature type={'primary'}>新增文献</AddLiterature>
                </>
            }/>
            <Table
                dataSource={props.rows}
                rowKey={'id'}
                onChange={props.pageChange}
                columns={this.columns}
                pagination={{
                    showTotal: (total, range) => `展示第${range[0]}-${range[1]}条, 共${total}条`,
                    current: query.offset / query.limit + 1,
                    total: props.total,
                    pageSize: query.limit
                }}
            />
        </IndexLayout>
    }
}

const ManageLiterature = connect(state => state.manage.literature, actions)(Literature);

export default ManageLiterature;
