import axios from 'axios';
import {message as staticMessage} from 'antd';

let message = staticMessage;

export const setMessage = messageApi => message = messageApi;

const request = axios.create({baseURL: '/api'});

request.interceptors.request.use((config) => {
    config.headers = Object.assign({'X-Requested-With': 'XMLHttpRequest'}, config.headers);
    return config;
});

request.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response && error.response.data) {
        message.error(error.response.data.message || error.message);
    }
    return Promise.reject(error);
});

export default request;
