import {combineReducers} from 'redux';
import detail from './detail';

const initialData = {
    rows: [],
    total: 0,
    query: {
        order: 'desc',
        offset: 0,
        limit: 10,
        search: ''
    }
};

const index = (state = initialData, {type, payload}) => {
    if (type === 'state.manage.user') {
        return {...state, ...payload};
    }
    if (type === 'state.manage.user.query') {
        return {...state, query: {...state.query, ...payload}};
    }
    return state;
};

const user = combineReducers({index, detail});

export default user;
