import React from 'react';
import {Select} from 'antd';
import debounce from 'lodash/debounce';
import request from '../util/request';

class FormInsectSelector extends React.Component {
    constructor(props) {
        super(props);
        this.handleSearch = debounce(this.doSearch, 500);
    }

    state = {
        searchList: []
    }

    componentDidMount() {

    }

    doSearch = query => {
        this.setState({searchText: query, searching: !!query});
        if (query) {
            request.get('/insect/query', {params: {query}})
                .then(({data}) => {
                    this.setState({searchList: data, searching: false});
                })
        }
    };

    render() {
        const {props, state: {searchList, searchText, searching}} = this;
        return <Select onSearch={this.handleSearch} showSearch filterOption={false}
                       placeholder="请输入科名/物种名称搜索" {...props}>
            {searchList.map(item =>
                <Select.Option value={item.id} key={item.id}>{item.specificNameChinese}</Select.Option>
            )}
            {props.autoAppend && searchText && searchList.length === 0 && !searching &&
            <Select.Option value={-1}>{searchText}</Select.Option>}
        </Select>
    }
}

export default FormInsectSelector;
