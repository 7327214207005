import React from 'react';
import {useInfiniteScroll} from 'ahooks';
import request from '../../../util/request';
import IndexLayout from '../../IndexLayout';
import {Col, Container} from 'react-bootstrap';
import {Card, Carousel, Row, Spin} from 'antd';
import {useDispatch} from 'react-redux';
import {toInsectDetail} from '../../index/actions';
import ArticleContent from '../../article/content';

const InsectList = props => {
    const dispatch = useDispatch();
    const {data, loading, loadingMore} = useInfiniteScroll(currentData => {
        return request.get('/insect', {params: {offset: currentData && currentData.list.length, limit: 10}})
            .then(({data: {rows, total}}) => {
                rows.forEach(item => {
                    item.images = item.images ? item.images.split(',') : [];
                });
                return {list: rows, total};
            })
    }, {isNoMore: data => data && data.list.length >= data.total, target: document});
    return <IndexLayout>
        <Container>
            <Row>
                <Col>
                    <ArticleContent id={5}/>
                </Col>
            </Row>
        </Container>
        <Container>
            <div className={'row-cols-3 row'}>
                {data && data.list.map((data) => {
                    const {id, specificNameChinese, latinName, images} = data;
                    return <Col key={id}>
                        <Card
                            onClick={() => dispatch(toInsectDetail(data))}
                            cover={
                                <Carousel>
                                    {images.length === 0 && <div className={'img-4-3-wrapper'}/>}
                                    {images.map((name, index) => (
                                        <div key={index} className={'img-4-3-wrapper'}>
                                            <img
                                                alt={name}
                                                src={`/uploadImg/tiny/${name}`}
                                            />
                                        </div>
                                    ))}
                                </Carousel>
                            }
                        >
                            <p className={'h5'}>
                                {specificNameChinese} {latinName}
                            </p>
                        </Card>
                    </Col>;
                })}
                {(loading || loadingMore) && <div className="col-12 text-center py-5" key={0}><Spin/></div>}
            </div>
        </Container>
    </IndexLayout>;
}

export default InsectList;
